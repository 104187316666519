/**
 * 診断ページ用のスクリプト
 */
if (is_route('improvement.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        improvement_event_set();
        improvement_config_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.improvement_event_set = function() {

    /*---- 日別表示時 ----*/

    // 日別表示時
    $(document).on('click', '[data-daily-modal]', function () {
        _modal.create(this, 'daily-modal', ['user_id', 'month', 'property'], true);
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id', 'user_id', 'class']);
    });

    /*---- 通知表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-notice-modal]', function () {
        _modal.create(this, 'notice-modal', ['user_id', 'type', 'date', 'property'], true);
    });

    // 出勤簿：月の一括印刷時
    $(document).on('click', '[data-total-print]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('total-print'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name' : 'property', 'value' : $(this).data('property')});
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 出勤簿：月の一括印刷時
    $(document).on('click', '[data-detail-print]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('detail-print'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name' : 'user_id', 'value' : $(this).data('user_id')});
        datas.push({'name' : 'property', 'value' : $(this).data('property')});
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['company_id'], true);
    });

    /*---- 改善基準設定保存時 ----*/

    // 労務設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal');
    });

    /*---- チャート表示時 ----*/

    // チャート表示時
    $(document).on('click', '[data-chart-modal]', function () {
        // モーダルのID取得とキャンバスIDを生成
        var modalId = $(this).data('chart-modal');
        var canvasId = $(this).data('canvas-id');
        var action = $(this).data('action');
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name' : 'user_id', 'value' : $(this).data('user_id')});
        datas.push({'name' : 'property', 'value' : $(this).data('property')});
        // チャートの表示タイプを判定
        if ($(this).data('mode') == 'month') {
            // 月間チャート表示
            improvement_monthly_chart_event_set(action, modalId, canvasId, datas);
        } else {
            // 年間チャート表示
            improvement_yearly_chart_event_set(action, modalId, canvasId, datas);
        }
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });
}
/**
 * 設定ページ読み込み時のイベント設定
 */
window.improvement_config_event_set = function() {

    $(document).on('change', 'input[name=simulate]', function() {
        if ($(this).val() == 2023) {
            $('.config-2024').toggleClass('display-none', true);
            $('.config-2023').toggleClass('display-none', false);
            $('#improvement-config-modal input[name=simulate][value=2023]').trigger('click');
            $('button[type=submit][data-config-save-modal]').data('config-save-modal', '#improvement-config-modal-until-2023');
        } else {
            $('.config-2024').toggleClass('display-none', false);
            $('.config-2023').toggleClass('display-none', true);
            $('#improvement-config-modal input[name=simulate][value=2024]').trigger('click');
            $('button[type=submit][data-config-save-modal]').data('config-save-modal', '#improvement-config-modal');
        }
    });
}
/**
 * 年間チャート表示を行う
 * @param {*} action
 * @param {*} modalId
 * @param {*} canvasId
 * @param {*} datas
 */
window.improvement_yearly_chart_event_set = function(action, modalId, canvasId, datas) {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    // 送信
    _ajax.post(action, datas, function (results) {

        $(create_id(modalId) + ' .modal-title').text(results.title);
        $(create_id(modalId) + ' .period').html(results.period);
        $(create_id(modalId) + ' .user-code').html(results.userCode);
        $(create_id(modalId) + ' .user-name').html(results.userName);

        var limitLine = results.limitLine;
        var yAxesUnit = results.yAxesUnit;

        chart(canvasId, 'bar', {
            labels: results.labels,
            datasets: [
            {
                data: results.datasets.rec_times.datas,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.5)',
                yAxisID: "monthly-y-axis",
                stack: 'stack-1',
            },
            {
                type: "line",
                fill: false,
                data: results.datasets.total_rec_times.datas,
                lineTension: 0,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: "total-y-axis",
            },
            ],
        }, {
            legend: {
                display: false,
                position: "bottom",
                onClick: function () { return false },
                labels: {
                    fontSize: ticksFontSize
                }
            },
            tooltips: {
                displayColors: false,
                mode: 'nearest',
                //intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return results.tooltips[tooltipItem[0].index].title;
                    },
                    label: function (tooltipItem, data){
                        return results.tooltips[tooltipItem.index].messages;
                    }
                },
            },
            responsive: true,
            scales: {
                yAxes: [{
                    id: "monthly-y-axis",
                    type: "linear",
                    position: "left",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.rec_times.ticks[results.datasets.rec_times.ticks.length - 1],
                        max: results.datasets.rec_times.ticks[0],
                        fontSize: ticksFontSize,
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.rec_times.ticks;
                        return;
                    }
                }, {
                    id: "total-y-axis",
                    type: "linear",
                    position: "right",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.total_rec_times.ticks[results.datasets.total_rec_times.ticks.length - 1],
                        max: results.datasets.total_rec_times.ticks[0],
                        fontSize: ticksFontSize,
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.total_rec_times.ticks;
                        return;
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize
                    },
                }],
            },
            //基準線を引く
            annotation: {
                annotations: [{
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'total-y-axis',
                    value: limitLine,
                    borderWidth: 2,
                    borderColor: 'red',
                    label: {
                        position: 'right',
                        yAdjust:10,
                        content: limitLine,
                        enabled: true
                    }
                }]
            },
        });
        // モーダルを表示
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}
/**
 * 月間チャート表示を行う
 * @param {*} action
 * @param {*} modalId
 * @param {*} canvasId
 * @param {*} datas
 */
window.improvement_monthly_chart_event_set = function(action, modalId, canvasId, datas) {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    // 送信
    _ajax.post(action, datas, function (results) {

        $(create_id(modalId) + ' .modal-title').text(results.title);
        $(create_id(modalId) + ' .period').html(results.period);
        $(create_id(modalId) + ' .user-code').html(results.userCode);
        $(create_id(modalId) + ' .user-name').html(results.userName);

        var limitLine = results.limitLine;
        var yAxesUnit = results.yAxesUnit;

        chart(canvasId, 'bar', {
            labels: results.labels,
            datasets: [
            {
                data: results.datasets.run_times.datas,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: "one-day-y-axis",
                stack: 'stack-1',
            },
            {
                data: results.datasets.rec_times.datas,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.5)',
                yAxisID: "one-day-y-axis",
                stack: 'stack-1',
            },
            {
                type: "line",
                fill: false,
                data: results.datasets.total_rec_times.datas,
                lineTension: 0,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: "total-y-axis",
            },
            ],
        }, {
            legend: {
                display: false,
                position: "bottom",
                onClick: function () { return false },
                labels: {
                    fontSize: ticksFontSize
                }
            },
            tooltips: {
                displayColors: false,
                mode: 'nearest',
                //intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return results.tooltips[tooltipItem[0].index].title;
                    },
                    label: function (tooltipItem, data){
                        return results.tooltips[tooltipItem.index].messages;
                    }
                },
            },
            responsive: true,
            scales: {
                yAxes: [{
                    id: "one-day-y-axis",
                    type: "linear",
                    position: "left",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.run_times.ticks[results.datasets.run_times.ticks.length - 1],
                        max: results.datasets.run_times.ticks[0],
                        fontSize: ticksFontSize,
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.run_times.ticks;
                        return;
                    },
                }, {
                    id: "total-y-axis",
                    type: "linear",
                    position: "right",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.total_rec_times.ticks[results.datasets.total_rec_times.ticks.length - 1],
                        max: results.datasets.total_rec_times.ticks[0],
                        fontSize: ticksFontSize,
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.total_rec_times.ticks;
                        return;
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize
                    },
                }],
            },
            //基準線を引く
            annotation: {
                annotations: [{
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'total-y-axis',
                    value: limitLine,
                    borderWidth: 2,
                    borderColor: 'red',
                    label: {
                        position: 'right',
                        yAdjust:10,
                        content: limitLine,
                        enabled: true
                    }
                }]
            },
        });
        // モーダルを表示
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}
