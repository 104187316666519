/**
 * 雇用形態設定ページ用のスクリプト
 */
 if (is_route('paid_holiday_config.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        paid_holiday_config_event_set();
        paid_holiday_config_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.paid_holiday_config_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 有効時 ----*/

    // 有効確認時
    $(document).on('click', '[data-enable-modal]', function () {
        _modal.create(this, 'enable-modal', ['id']);
    });

    /*---- 無効時 ----*/

    // 無効確認時
    $(document).on('click', '[data-disable-modal]', function () {
        _modal.create(this, 'disable-modal', ['id']);
    });

    /*---- ステータス変更時 ----*/

    // 保存時
    $(document).on('click', '[data-status-modal]', function () {
        _modal.submit(this, 'status-modal');
    });

}
/**
 * 設定モーダル表示時のイベント設定
 */
window.paid_holiday_config_setting_event_set = function() {

    /*---- 年次追加時 ----*/

    $(document).on('click', '#paid_holiday_config-setting-modal-year-add', function() {
        // フォーマットの取得
        let labelFormat = $(this).data('year-label-format');
        let startLabelFormat = $(this).data('limit-start-label-format');
        let limitLabelFormat = $(this).data('limit-year-label-format');
        // 最終項目を取得し値を変更
        let item = $('#grant .grant-item:last');
        let year = $(item).data('year');
        $(item).find('.year-label').text(
            ($('#grant .grant-item').length <= 1 ? startLabelFormat.replace(':year', year) : labelFormat.replace(':year', year))
        );
        // 年を加算
        year ++;
        // 最終項目
        let clone = item.clone();
        $(clone).data('year', year);
        $(clone).attr('data-year', year);
        $(clone).find('.year-label').text(
            limitLabelFormat.replace(':year', year)
        );
        $(clone).find('.grant-item-input').attr('name', 'grantings[' + year + ']');
        $(clone).find('.grant-item-input').attr('id', 'input-grantings[' + year + ']');
        $(clone).find('.grant-item-input').parent().find('.number-input-value').attr('name', 'grantings[' + year + ']');
        $(clone).find('.errors').attr('id', 'input-grantings[' + year + ']-errors')
        $('#grant').append(clone);
        if ($('#grant .grant-item').length >= 2) {
            $('#paid_holiday_config-setting-modal-year-sub').removeAttr('disabled');
        }
        // 比例付与分の追加処理
        $('#proportional-grant .proportional-grant-item').each(function() {
            let level = $(this).data('level');
            let item = $(this).find('.proportional-grant-list .proportional-grant-list-item:last');
            let year = $(item).data('year');
            $(item).find('.year-label').text(
                ($(this).find('.proportional-grant-list .proportional-grant-list-item').length <= 1 ? startLabelFormat.replace(':year', year) : labelFormat.replace(':year', year))
            );
            // 年を加算
            year ++;
            // 最終項目
            let clone = item.clone();
            $(clone).data('year', year);
            $(clone).attr('data-year', year);
            $(clone).find('.year-label').text(
                limitLabelFormat.replace(':year', year)
            );
            $(clone).find('.proportional-grant-list-item-input').attr('name', 'proportional_grantings[' + level + '][' + year + ']');
            $(clone).find('.proportional-grant-list-item-input').attr('id', 'input-proportional_grantings[' + level + '][' + year + ']');
            $(clone).find('.proportional-grant-list-item-input').parent().find('.number-input-value').attr('name', 'proportional_grantings[' + level + '][' + year + ']');
            $(clone).find('.errors').attr('id', 'input-proportional_grantings[' + level + '][' + year + ']-errors')
            $(this).find('.proportional-grant-list').append(clone);
        })
    });

    /*---- 年次削除時 ----*/

    $(document).on('click', '#paid_holiday_config-setting-modal-year-sub', function() {
        // フォーマットの取得
        let startLabelFormat = $(this).data('limit-start-label-format');
        let limitLabelFormat = $(this).data('limit-year-label-format');
        // 最終項目を削除
        $('#grant .grant-item:last').remove();
        // 最終項目を取得し値を変更
        let item = $('#grant .grant-item:last');
        let year = $(item).data('year');
        $(item).find('.year-label').text(
            ($('#grant .grant-item').length <= 1 ? startLabelFormat.replace(':year', year): limitLabelFormat.replace(':year', year))
        );
        if ($('#grant .grant-item').length <= 1) {
            $(this).attr('disabled', 'disabled');
        }
        // 比例付与分の追加処理
        $('#proportional-grant .proportional-grant-item').each(function() {
            // 最終項目を削除
            $(this).find('.proportional-grant-list .proportional-grant-list-item:last').remove();
            let item = $(this).find('.proportional-grant-list .proportional-grant-list-item:last');
            let year = $(item).data('year');
            $(item).find('.year-label').text(
                ($(this).find('.proportional-grant-list .proportional-grant-list-item').length == 1 ? startLabelFormat.replace(':year', year) : limitLabelFormat.replace(':year', year))
            );
        })
    });

    /*---- 付与条件の追加 ----*/

    $(document).on('click', '#paid_holiday_config-setting-modal-proportional-add', function() {
        let copy = $('#proportional-grant .proportional-grant-item:last').clone();
        let level = $(copy).data('level');
        level ++;
        $(copy).data('level', level);
        $(copy).attr('data-level', level);
        $(copy).find('.min-worked-days-input').attr('name', 'min_worked_days[' + level + ']')
        $(copy).find('.min-worked-days-input').attr('id', 'input-min_worked_days[' + level + ']')
        $(copy).find('.min-worked-days-input').parent().find('.number-input-value').attr('name', 'min_worked_days[' + level + ']')
        $(copy).find('.proportional-min-worked-days .errors').attr('id', 'input-min_worked_days[' + level + ']-error')
        $(copy).find('.proportional-grant-list-item').each(function() {
            let year = $(this).data('year');
            $(this).find('.proportional-grant-list-item-input').attr('name', 'proportional_grantings[' + level + '][' + year + ']');
            $(this).find('.proportional-grant-list-item-input').attr('id', 'input-proportional_grantings[' + level + '][' + year + ']');
            $(this).find('.proportional-grant-list-item-input').parent().find('.number-input-value').attr('name', 'proportional_grantings[' + level + '][' + year + ']');
            $(this).find('.errors').attr('id', 'input-proportional_grantings[' + level + '][' + year + ']-errors');
        });
        $('#proportional-grant').append(copy);
        if ($('#proportional-grant .proportional-grant-item').length >= 2) {
            $('#paid_holiday_config-setting-modal-proportional-sub').removeAttr('disabled');
        }
    });
    
    /*---- 付与条件の削除 ----*/

    $(document).on('click', '#paid_holiday_config-setting-modal-proportional-sub', function() {
        $('#proportional-grant .proportional-grant-item:last').remove();
        if ($('#proportional-grant .proportional-grant-item').length <= 1) {
            $(this).attr('disabled', 'disabled');
        }
    });

}