/**
 * 申請ページ用のスクリプト
 */
if (is_route('workflow.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        workflow_event_set();
        workflow_detail_event_set();
    });
}

let error_set = function (selector, xhr, status, errorThrown, showToast = true) {
    try {
        // BadRequest時
        if (xhr.status == 400) {
            let json = xhr.responseJSON;
            let subject = json.subject;
            let errors = json.errors;
            // フォームにエラーメッセージを配置
            $.each(errors, function(name, message) {
                if(Array.isArray(message)) {
                    let error_str = '';
                    for(const idx in message) {
                        error_str += message[idx];
                    }
                    $(selector).html(error_str);
                    // エラーメッセージを表示
                    toastr.warning(error_str);
                } else {
                    $(selector).html(message);
                    // エラーメッセージを表示
                    toastr.warning(message);
                }
            });
            return;
        } else {
            // レスポンスされたエラーを表示する
            console.log(xhr);
            toastr.warning("xhr.status : " + xhr.status);
            $(selector).html("xhr.status : " + xhr.status);
        }
    } catch (e) {
        // レスポンスされたエラーを表示する
        console.log(e);
        toastr.warning(e);
        $(selector).html(e);
    }
}

let ajax_submit = function(elemId) {
    // 送信前にエラーをリセット
    _error.reset();
    $(elemId + "-error").html();
    // post送信
    _ajax.submit(elemId, function (results) {
        // リダイレクト指定時はリダイレクト
        if (results.redirect != null) {
            location.href = results.redirect;
            return;
        }
        // 完了メッセージをセットする
        _complete.set(results);
        // コンテンツの読込
        load_contents();
    }, function(xhr, status, errorThrown){
        // エラーをセットする
        if(xhr.status != 200) {
            error_set(elemId + "-error", xhr, status, errorThrown);
        }
    });
};

/**
 * ページ読み込み時のイベント設定
 */
window.workflow_event_set = function () {

    /*---- 点呼詳細表示時 ----*/

    // 点呼詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date'], true);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        let modalId = create_id($(this).data('setting-modal'));
        _modal.create(this, 'setting-modal', ['id', 'user_id'], false, function() { });
    });

    /*---- 承認ボタン押下時 ----*/
    $(document).on('click', '[data-type="approve"]', function() {
        ajax_submit('#' + $(this).attr('id') + '-form');
    });
    /*---- 一括承認ボタン押下時 ----*/
    $(document).on('click', '[data-type="approve-all"]', function() {
        $('.approve-all-checkbox').each(function (index, elem) {
            if($(elem).prop('checked')) {
                let dataid = $(elem).attr('data-id');
                ajax_submit('#workflow-approve-' + dataid + '-form');
            }
        });
    });
    /*---- 一括承認checkbox（ヘッダ）押下時 ----*/
    $(document).on('click', '#checkbox-head', function() {
        let set_status = $(this).prop('checked');

        $('.approve-all-checkbox').each(function (index, elem) {
            $(elem).prop('checked', set_status);
        });
    });
    /*---- 差戻ボタン押下時 ----*/
    $(document).on('click', '[data-type="remand"]', function() {
        ajax_submit('#' + $(this).attr('id') + '-form');
    });

    /*---- 設定時 ----*/

    // 保存設定時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {            
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#workflow-setting-modal');
            $('[data-detail-modal=workflow-detail-modal][data-date=' + moment(results.workflow.workflowed_at).format("YYYY-MM-DD") + ']').trigger('click');
        });
    });
}
/**
 * 詳細モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.workflow_detail_event_set = function () {

    /*---- 点呼詳細検索時 ----*/

    // 点呼詳細検索時
    $(document).on('select2:selecting', '#workflow-detail-modal-holding_id:enabled, #workflow-detail-modal-company_id:enabled, #workflow-detail-modal-section_id', function(e) {
        $(this).val(e.params.args.data.id);
        if ($(this).attr('id') == 'workflow-detail-modal-holding_id') {
            $('#workflow-detail-modal-company_id').val('');
            $('#workflow-detail-modal-section_id').val('');
        }
        if ($(this).attr('id') == 'workflow-detail-modal-company_id') {
            $('#workflow-detail-modal-section_id').val('');
        }
        let form = $(this).parents('form');
        let dates = $(form).serializeArray();
        let action = $(form).attr('action');
        let modalId = $(form).data('modal_id');
        _modal.update(modalId, action, dates);
    });

    /*---- 印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = $('#workflow-detail-modal-form').serializeArray();
        let action = extend_url_query($(this).data('print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-total-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('total-print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });



    /*---- 点呼タイプ変更時のフォーム表示 ----*/

    
    // 設定モーダル表示時に処理
    $(document).on('show.bs.modal', '#workflow-setting-modal', function (e) {
        workflow_setting_set_by_workflow_type($('.workflow-setting-workflow-type:checked'));
    });
    // トグルボタン押下時
    $(document).on('change', '.workflow-setting-workflow-type', function () {    
        workflow_setting_set_by_workflow_type(this);
    });
    
}
window.workflow_setting_set_by_workflow_type = function (selector) {
    let type = $(selector).val();
        let formId = create_id($(selector).data('form-id'));
        // 業務後点呼の場合
        if (type == '3') {
            $(formId).find('.only-type-3').each(function() {
                $(this).removeClass('display-none');
            });
            $(formId).find('.only-type').each(function() {
                $(this).addClass('display-none');
            });
        } else {
            $(formId).find('.only-type-3').each(function() {
                $(this).addClass('display-none');
            });
            $(formId).find('.only-type').each(function() {
                $(this).removeClass('display-none');
            });
        }
}