/**
 * 権限管理ページ用のスクリプト
 */
if (is_route('group.index')) {
    $(function () {
        group_event_set();
        group_setting_event_set();
    });
}
window.group_event_set = function() {

    /*---- 設定画面表示時 ----*/

    // 設定画面表示時
    $(document).on('click', '[data-setting-modal]', function() {
        _modal.create(this, 'setting-modal', ['id'], false, function() {
            group_displayed_default_permission_list();
        });
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id', 'page']);
        return false;
    });


}
window.group_setting_event_set = function() {

    /*---- モデルのチェックボックスクリック時 ----*/

    $(document).on('change', '.group-model', function() {
        let itemId = create_id($(this).data('parent-item-id'));
        // チェック項目が存在していなければindeterminateで表示
        $(this).prop('indeterminate', !($(itemId).find('.permission-tree input:checked').length > 0));
        if ($(this).prop('checked')) {
            $(itemId + ' .range-tree ').slideDown(100);
            group_set_visible_list_item_style();
        } else {
            $(itemId + ' .range-tree ').slideUp(100);
            $(itemId + ' .group-model-range-permission').prop('checked', false);
            $(itemId + ' .group-model-range').prop('checked', false);
            $(itemId + ' .group-model-range').prop('indeterminate', false);
            $(this).prop('indeterminate', false);
        }
    })

    /*---- レンジのチェックボックスクリック時 ----*/

    $(document).on('change', '.group-model-range', function() {
        let itemId = create_id($(this).data('parent-item-id'));
        let model = create_id($(this).data('parent-input-id'));
        $(this).prop('indeterminate', (!($(itemId).find('.permission-tree input:checked').length > 0)));
        if ($(this).prop('checked')) {
            $(itemId + ' .permission-tree ').slideDown(100);
            $(model).prop('checked', true);
        } else {
            $(itemId + ' .permission-tree').slideUp(100);
            $(this).prop('indeterminate', false);
            $(itemId + ' .group-model-range-permission').prop('checked', false);
        }
        $(model).trigger('change');
    });

    /*---- パーミッションのチェックボックスクリック時 ----*/

    $(document).on('change', '.group-model-range-permission', function () {
        // 同階層がチェックされていない場合
        let range = create_id($(this).data('parent-input-id'));
        if ($(this).prop('checked')) { // チェックされる時
            // 親のチェック状態を指定
            $(range).prop('checked', true);
        }
        $(range).trigger('change');
    });

    /*---- 権限タイプ変更時 ----*/

    $(document).on('change', '#group-setting-modal-type', function() {
        group_displayed_permission_list(this);
        $('#group-setting-modal-accessible_flag').prop('checked', false);
        $('#group-setting-modal-editable_flag').prop('checked', false);
        $('#group-setting-modal-deletable_flag').prop('checked', false);
    });

    /*---- 閲覧一括設定変更時 ----*/

    $(document).on('change', '#group-setting-modal-accessible_flag', function() {
        let range = $('#group-setting-modal-type').val();
        if (range != '') {
            range = ' [data-range=' + range + ']';
        }
        $('.model-tree-list-item' + range + ' [data-permission-group=accessible]').prop('checked', $(this).prop('checked'));
        $('.model-tree-list-item' + range + ' [data-permission-group=accessible]').trigger('change');
    });

    /*---- 設定一括設定変更時 ----*/

    $(document).on('change', '#group-setting-modal-editable_flag', function() {
        let range = $('#group-setting-modal-type').val();
        if (range != '') {
            range = ' [data-range=' + range + ']';
        }
        $('.model-tree-list-item' + range + ' [data-permission-group=editable]').prop('checked', $(this).prop('checked'));
        $('.model-tree-list-item' + range + ' [data-permission-group=editable]').trigger('change');
    });

    /*---- 削除一括設定変更時 ----*/

    $(document).on('change', '#group-setting-modal-deletable_flag', function() {
        let range = $('#group-setting-modal-type').val();
        if (range != '') {
            range = ' [data-range=' + range + ']';
        }
        $('.model-tree-list-item' + range + ' [data-permission-group=deletable]').prop('checked', $(this).prop('checked'));
        $('.model-tree-list-item' + range + ' [data-permission-group=deletable]').trigger('change');
    });

}

// 設定画面初期表示（表示可能な選択肢のみ表示・チェックされているtreeを開く）
window.group_displayed_default_permission_list = function () {
    let formId = '#group-setting-modal-form';
    let type = $(formId).find('[name=type]').val();
    // 権限タイプによってモデルを非表示
    hide_not_visible_model_by_target(type);
    // チェックされてるtreeを開く
    $('.group-model-range-permission:checked').each(function() {
        let permissionTreeId = create_id($(this).data('parent-tree-id'));
        $(permissionTreeId).show(0, function() {
            let rangeTreeId = create_id($(this).parents('.range-tree-list-item').data('parent-tree-id'));
            $(rangeTreeId).show(0);
        });
    });
}

// 権限タイプ変更時
window.group_displayed_permission_list = function (elm) {
    let formId = '#group-setting-modal-form';
    if ($(elm).val() == '') {
        $(formId).find('.range-tree-list-item').each(function() {
            $(this).show(0, function() {
                let parentItemId = create_id($(this).data('parent-item-id'));
                $(parentItemId).show(0, function() {
                    group_set_visible_list_item_style();
                });
            });
        });
    } else {
        let target = '[data-range=' + $(elm).val() +']';
        // 権限タイプによってモデルを非表示
        hide_not_visible_model_by_target($(elm).val());
        // 権限表示
        $(formId).find('.range-tree-list-item' + target).show(0, function() {
            let rangeTree = create_id($(this).data('parent-tree-id'));
            let modelItem = create_id($(this).data('parent-item-id'));
            if ($(rangeTree).find(target).length != 0) {
                $(modelItem).show(0, function() {
                    $(modelItem).find('.group-model').trigger('change');
                    group_set_visible_list_item_style();
                });
            }
        });
    }
}

// スタイルの調整
window.group_set_visible_list_item_style = function () {
    let modalTree = $('#group-setting-modal-form').find('.model-tree');
    let li = '.section-tree-list-item';
    $(modalTree).find(li).removeClass('last-child');
    $(modalTree).find(li + ':visible:last').addClass('last-child');
}

// 権限タイプによって権限・モデルを非表示にする
window.hide_not_visible_model_by_target = function (type) {
    if (!type) { return; }
    let target = '[data-range=' + type +']';
    $('#group-setting-modal-form').find('.range-tree-list-item:not(' + target + ')').hide(0, function() {
        $(this).find('input').prop('checked', false);
        $(this).find('input').prop('indeterminate', false);
        $(this).slideUp(0);
        $(this).find('.permission-tree').slideUp(0);
        let rangeTree = create_id($(this).data('parent-tree-id'));
        let modelItem = create_id($(this).data('parent-item-id'));
        // 表示できる権限が無い場合、モデルごと非表示
        if ($(rangeTree).find(target).length == 0) {
            $(modelItem).hide(0, function() {
                $(modelItem).find('.group-model').prop('checked', false);
                $(modelItem).find('.group-model').prop('indeterminate', false);
            });
        }
        // 下層にチェックが入った項目がない場合、モデルの表示を調整
        if ($(modelItem).find('input:checked').not('.group-model').length == 0) {
            $(modelItem).find('.group-model').prop('checked', false);
            $(modelItem).find('.group-model').prop('indeterminate', false);
        }
    });
}
