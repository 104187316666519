
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    MeetingSessionStatusCode,
} from 'amazon-chime-sdk-js';

export class AWSChime {

    sendLogCallback = null;

    prefixId = null;
    config = null;

    session = null;

    timer = null;
    warnedAt = null;
    forceEndedAt = null;
    isEnded = true;

    videoElements = {};
    indexMap = {};

    logs = [];
    errors = [];

    constructor(prefixId, config, sendLogCallback) {
        this.prefixId = prefixId;
        this.config = config;
        this.sendLogCallback = sendLogCallback;

        (async () => {

            const logger = new ConsoleLogger('MeetingLogs', LogLevel.DEBUG);
            const deviceController = new DefaultDeviceController(logger);
            const sessionConfig = new MeetingSessionConfiguration(this.config.meeting, this.config.attendee);

            this.session = new DefaultMeetingSession(sessionConfig, logger, deviceController);

            try {
                await this.session.audioVideo.audioMixController.bindAudioElement(document.getElementById(this.config.audio_id));
                this.log('set audio', this.config.audio_id);
            } catch (e) {
                this.error(e.message);
                toastr.warning(this.config.errors.bind);
            }

            this.session.audioVideo.setDeviceLabelTrigger(async () =>
                await session.navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            );

            const audioInputDevices = await this.session.audioVideo.listAudioInputDevices();
            const audioOutputDevices = await this.session.audioVideo.listAudioOutputDevices();
            const videoInputDevices = await this.session.audioVideo.listVideoInputDevices();

            if (audioInputDevices[0]) {
                this.log('start audio input', audioInputDevices[0].deviceId);
                try {
                    await this.session.audioVideo.startAudioInput(audioInputDevices[0].deviceId);
                } catch (e) {
                    this.error(e.message);
                    toastr.warning(this.config.errors.device);
                }
            }
            if (audioOutputDevices[0]) {
                this.log('choose audio output', audioOutputDevices[0].deviceId);
                try {
                    await this.session.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
                } catch (e) {
                    this.error(e.message);
                    toastr.warning(this.config.errors.device);
                }
            }
            if (videoInputDevices[0]) {
                this.log('start video input', videoInputDevices[0].deviceId);
                try {
                    await this.session.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
                } catch (e) {
                    this.error(e.message);
                    toastr.warning(this.config.errors.device);
                }
            }

            if (this.getElement('meeting-devices').length > 0) {
                this.setAudioInputSelectOption(audioInputDevices);
                this.setAudioOutputSelectOption(audioOutputDevices);
                this.setVideoInputSelectOption(videoInputDevices);
            }

            this.session.audioVideo.addDeviceChangeObserver(this.deviceChangeObserver(this));

            this.setDeviceEvent(this);

            this.session.audioVideo.chooseVideoInputQuality(this.config.size.width, this.config.size.height, 3, 1000);
            this.log(null, 'choose video input quality ...');

            for (let i = 0; i <= this.config.tiles; i++) {
                this.videoElements[i] = document.getElementById(this.config.video_id_prefix + i);
            }

            // tileの上限などにより取得が行えない場合
            if (!this.acquireVideoElement()) {
                this.error(this.config.errors.tile);
                toastr.warning(this.config.errors.tile);
            };

            this.session.audioVideo.addObserver(this.audioVideoObserver(this));
            this.session.audioVideo.start();
            this.log(null, 'audio video start ...');
            this.session.audioVideo.startLocalVideoTile();
            this.log(null, 'start local video tile ...');

        })();

        this.setMeetingEvent();
    }

    getElement(suffix = '') {
        return $(create_id(this.prefixId, suffix));
    }

    getValue(suffix = '') {
        return this.getElement(suffix).val();
    }

    acquireVideoElement(tileId = null) {
        for (let i = 0; i <= this.config.tiles; i++) {
            if (this.indexMap[i] === tileId) {
                return this.videoElements[i];
            }
        }
        for (let i = 0; i <= this.config.tiles; i++) {
            if (!this.indexMap.hasOwnProperty(i)) {
                this.indexMap[i] = tileId;
                return this.videoElements[i];
            }
        }
        return;
    }

    mainVideoElement(videoElement) {
        const videoView = document.getElementById(this.config.video_view_id);
        const videoSlide = document.getElementById(this.config.video_slide_id);
        for (let i = 0; i < videoView.childElementCount; i++) {
            videoSlide.prepend(videoView.children[i]);
        }
        videoView.prepend(videoElement);
    }

    deviceChangeObserver(obj = null) {

        obj = (obj == null) ? this : obj;

        return {
            audioInputsChanged: freshAudioInputDeviceList => {
                obj.setAudioInputSelectOption(freshAudioInputDeviceList);
            },
            audioOutputsChanged: freshAudioOutputDeviceList => {
                obj.setAudioOutputSelectOption(freshAudioOutputDeviceList);
            },
            videoInputsChanged: freshVideoInputDeviceList => {
                obj.setVideoInputSelectOption(freshVideoInputDeviceList);
            },
        };
    }

    audioVideoObserver(obj = null) {

        obj = (obj == null) ? this : obj;

        return {
            audioVideoDidStart: () => {
                obj.log(null, 'audio video did start ...');
                obj.isEnded = false;
                
                if ($(create_id(obj.prefixId, 'timer')).length) {
                    _ajax.background(obj.getElement('timer').data('action'), {
                        'meeting_id': obj.getValue('meeting_id')
                    }, function (results, obj) {
                        obj.warnedAt = moment(results.warned_at);
                        obj.forceEndedAt = moment(results.forced_ended_at);
                        // 対応時間のタイマー
                        obj.timer = setInterval(obj.displayTimeUpdate, 1000, obj);
                    }, null, 'json', obj);
                }
                
            },
            audioVideoDidStop: sessionStatus => {
                obj.log(null, 'audio video did stop ...');
                obj.isEnded = true;
                // const sessionStatusCode = sessionStatus.statusCode();
                // (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded)
            },
            videoTileDidUpdate: tileState => {
                obj.log(null, 'video tile did update ...');
                if (!tileState.boundAttendeeId) {
                    return;
                }
                const videoElement = obj.acquireVideoElement(tileState.tileId);
                obj.session.audioVideo.bindVideoElement(tileState.tileId, videoElement);
                if (!tileState.localTile) {
                    obj.mainVideoElement(videoElement);
                }
            },
            videoTileWasRemoved: tileId => {
                obj.log(null, 'video tile was removed ...');
                for (let i = 0; i <= obj.config.tiles; i++) {
                    if (obj.indexMap[i] === tileId) {
                        delete obj.indexMap[i];
                    }
                }
            }
        };
    }

    displayTimeUpdate(obj = null) {

        obj = (obj == null) ? this : obj;

        const timerElement = obj.getElement('timer');
        const recordingElement = obj.getElement('recording');
        const current = moment();
        const limit = Math.ceil(obj.forceEndedAt.diff(current) / 1000);
        const warning = Math.ceil(obj.warnedAt.diff(current) / 1000);
        if (limit > 0) {
            timerElement.text(second_to_time(limit));
            if (warning < 0) {
                timerElement.addClass('text-danger');
            }
        } else {
            timerElement.text(second_to_time(0));
            if (!obj.isEnded) {
                $(recordingElement).addClass('display-none');
                obj.end()
                obj.error(timerElement.data('message'));
                toastr.warning(timerElement.data('message'));
            }
        }
    }

    setMeetingEvent(obj = null) {

        obj = (obj == null) ? this : obj;

        // slideのビデオ表示押下時
        $(document).on('click', create_id(obj.config.video_slide_id, ' .video-preview'), function() {
            obj.mainVideoElement(document.getElementById($(this).attr('id')));
            obj.log('video preview did change', $(this).attr('id'));
        });

        // リロード・クローズ前にログを送信
        $(window).on('beforeunload', function() {
            (obj.sendLogCallback != null) && obj.sendLogCallback(obj.getValue('log'), obj.getValue('id'), obj);
        });

        // モーダル非表示時
        $(document).on('hidden.bs.modal', create_id(obj.prefixId), function() {
            (obj.sendLogCallback != null) && obj.sendLogCallback(obj.getValue('log'), obj.getValue('id'), obj);
            obj.end();
        });
    }

    setDeviceEvent(obj = null) {

        obj = (obj == null) ? this : obj;

        $(document).on('change', create_id(obj.prefixId, 'audio-inputs'), async function() {
            obj.log('start audio input', $(this).val());
            try {
                await obj.session.audioVideo.startAudioInput($(this).val());
            } catch (e) {
                obj.error(e.message);
                toastr.warning(obj.config.errors.device);
            }
        });

        $(document).on('change', create_id(obj.prefixId, 'audio-outputs'), async function() {
            obj.log('choose audio output', $(this).val());
            try {
                await obj.session.audioVideo.chooseAudioOutput($(this).val());
            } catch (e) {
                obj.error(e.message);
                toastr.warning(obj.config.errors.device);
            }
        });

        $(document).on('change', create_id(obj.prefixId, 'video-inputs'), async function() {
            obj.log('start video input', $(this).val());
            try {
                await obj.session.audioVideo.startVideoInput($(this).val());
            } catch (e) {
                obj.error(e.message);
                toastr.warning(obj.config.errors.device);
            }
        });
    }

    setAudioInputSelectOption(audioInputDevices) {
        this.log('video input devices');
        this.getElement('audio-inputs').empty();
        var defaultAudioInputGroupId = null;
        audioInputDevices.forEach(device => {
            if (device.deviceId == '') {
                return;
            }
            if (device.deviceId == 'default') {
                defaultAudioInputGroupId = device.groupId;
            } else {
                this.log(null, '-' + device.deviceId + '(' + device.label + ')');
                const option = $('<option></option>', {
                    'value': device.deviceId,
                    'data-group': device.groupId,
                }).text(device.label);
                $(option).prop('selected', (device.groupId == defaultAudioInputGroupId));
                this.getElement('audio-inputs').append(option);
            }
        });
        this.getElement('audio-inputs').parent().toggleClass('invalid', 
            (this.getElement('audio-inputs').length == 0)
        );
    }

    setAudioOutputSelectOption(audioOutputDevices) {
        this.log('audio output devices');
        this.getElement('audio-outputs').empty();
        var defaultAudioOutputGroupId = null;
        audioOutputDevices.forEach(device => {
            if (device.deviceId == '') {
                return;
            }
            if (device.deviceId == 'default') {
                defaultAudioOutputGroupId = device.groupId;
            } else {
                this.log(null, '-' + device.deviceId + '(' + device.label + ')');
                const option = $('<option></option>', {
                    'value': device.deviceId,
                    'data-group': device.groupId,
                }).text(device.label);
                $(option).prop('selected', (device.groupId == defaultAudioOutputGroupId));
                this.getElement('audio-outputs').append(option);
            }
        });
        this.getElement('audio-outputs').parent().toggleClass('invalid', 
            (this.getElement('audio-outputs').length == 0)
        );
    }

    setVideoInputSelectOption(videoInputDevices) {
        this.log('video input devices');
        this.getElement('video-inputs').empty();
        var defaultVideoInputGroupId = null;
        videoInputDevices.forEach(device => {
            if (device.deviceId == '') {
                return;
            }
            if (device.deviceId == 'default') {
                defaultVideoInputGroupId = device.groupId;
            } else {
                this.log(null, '-' + device.deviceId + '(' + device.label + ')');
                const option = $('<option></option>', {
                    'value': device.deviceId,
                    'data-group': device.groupId,
                }).text(device.label);
                $(option).prop('selected', (device.groupId == defaultVideoInputGroupId));
                this.getElement('video-inputs').append(option);
            }
        });
        this.getElement('video-inputs').parent().toggleClass('invalid', 
            (this.getElement('video-inputs').length == 0)
        );
    }

    end() {
        this.session.audioVideo.audioMixController.unbindAudioElement();
        this.session.audioVideo.stopVideoInput();
        this.session.audioVideo.stopAudioInput();
        this.session.audioVideo.stopLocalVideoTile();
        this.isEnded = true;
    }

    log(title = null, message = null) {
        if (this.getElement('log').length == 0) {
            return;
        }
        let text = '';
        if (title != null) {
            text = title + ': ';
        }
        if (message != null) {
            text = text + message;
        }
        this.logs.push(text);
    }

    error(message) {
        if (this.getElement('log').length == 0) {
            return;
        }
        this.errors.push(message);
        (this.sendLogCallback != null) && this.sendLogCallback(this.getValue('log'), this.getValue('id'), this);
    }

}