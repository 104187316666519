/**
 * 日常点検項目設定ページ用のスクリプト
 */
 if (is_route('daily_check_item.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        daily_check_item_event_set();
        daily_check_item_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.daily_check_item_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
}

/**
 * 設定画面表示時イベント
 */
window.daily_check_item_setting_event_set = function()
{
    // 点検No.次点セット
    $(document).on('change', 'select[name="section_id"]' , function() {
        if (undefined == $(this).val() || $(this).val().length === 0) return;
        let url = $(this).data('check_no');
        let data = {'section_id': $(this).val()};
        _ajax.post(url, data, function(results) {
            $('input[name="check_no"]').val(results.check_no);
            $('#daily_check_item-setting-modal-check_no').val(results.check_no);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
