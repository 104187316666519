
import firebase from "firebase/app";
import 'firebase/messaging';

import { FaceAuthRegister } from '../modules/face_authentication';
const faceAuthRegister = new FaceAuthRegister('remote_rollcall-facial-modal-video');

/**
 * ログインページ用のスクリプト
 * @uses 管理画面と設計が異なるため共通部分は呼び出すもののbss独自イベントなどは使用しない。
 */
if (is_route('user.login')) {

    var firebaseConfig = {
        apiKey: "AIzaSyC_5hd6DIO_wubtSUHjfqvDPs8FEEjiJAE",
        authDomain: "bss-anest.firebaseapp.com",
        projectId: "bss-anest",
        storageBucket: "bss-anest.appspot.com",
        messagingSenderId: "335952016242",
        appId: "1:335952016242:web:4c1582aa0ca331ea8863eb",
        measurementId: "G-CSLGZE569P"
    };

    firebase.initializeApp(firebaseConfig);

    var messaging = null;
    if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
    }

    var isSending = false;

    const login = function(results) {
        isSending = false;
        let formId = $('#login-form-submit').data('login-form');
        let action = $(formId).data('action');
        if (results.status) {
            faceAuthRegister.complete();
            _complete.set(results);
            location.href = action;
        } else {
            if (results.code == '200') { // not_agreed
                _modal.show('#login-terms-modal');
            } else if (results.code == '300') { // already_login
                _modal.show('#login-force-modal');
            } else if (results.code == '400') { // contract_status_not_use
                _modal.show('#login-status-error-modal');
            } else if (results.code == '500') { // facial image not registerd
                _modal.show('#remote_rollcall-facial-modal');
                faceAuthRegister.selectableCamera().start(function() {
                    $('[data-facial-save-modal=remote_rollcall-facial-modal]').attr('disabled', false);
                });
                // カメラプルダウン変更時イベントを追加
                $(document).off('change', '#remote_rollcall-facial-modal-video-inputs');
                $(document).on('change', '#remote_rollcall-facial-modal-video-inputs', function() {
                    faceAuthRegister.changeCamera($(this).val());
                });
                $(document).on('hidden.bs.modal', '#remote_rollcall-facial-modal', function() {
                    faceAuthRegister.cancel();
                });
            }
        }
    }

    $(function () {

        // パスワード表示アイコンクリック時
        $(document).on('click', '.show-password-btn', function () {
            let input = $(this).parents('.input-group').find('input');
            if (input.attr('type') === 'password') {
                // パスワード表示
                input.attr('type', 'text');
                $(this).find('.fas').removeClass('fa-eye-slash').addClass('fa-eye');
            } else {
                // パスワード非表示
                input.attr('type', 'password');
                $(this).find('.fas').removeClass('fa-eye').addClass('fa-eye-slash');
            }
        });

        // ログインボタンクリック時
        $(document).on('click', '#login-form-submit', function () {
            isSending = true;
            let formId = $(this).data('login-form');
            if (messaging) {
                messaging.getToken({
                    vapidKey: 'BIGjyEz9c4u4zBptKGxAlMzrBfKQmgZdcqCvE0XdlhWaO09k5KRv3Gh-xh7wiRpMVWqNt3IXtfN8ebNx14l2plk'
                }).then(function(currentToken) {
                    if (currentToken) {
                        $('#login-form-push_token').val(currentToken);
                        $('#login-form-user_agent').val(navigator.userAgent);
                    }
                }).finally(function() {
                    // 送信
                    _ajax.submit(formId, login, function(xhr, status, errorThrown){
                        isSending = false;
                        // エラーをセットする
                        _error.set(formId, xhr, status, errorThrown, false);
                    });
                    // 送信中で20秒反応がない場合も送信
                    setTimeout(function() {
                        if (isSending) {
                            _ajax.submit(formId, login, function(xhr, status, errorThrown){
                                // エラーをセットする
                                _error.set(formId, xhr, status, errorThrown, false);
                            });
                        }
                    }, 20000);
                });
            } else {
                _ajax.submit(formId, login, function(xhr, status, errorThrown){
                    // エラーをセットする
                    _error.set(formId, xhr, status, errorThrown, false);
                });
            }
            return false;
        });

        // 強制ログインボタンクリック時
        $(document).on('click', '#login-form-force-submit', function () {
            let formId = $(this).data('login-form');
            $(formId).append($('<input></input>', {
                'type' : 'hidden',
                'name' : 'force_login',
                'value' : 1
            }));
            _modal.hide('#login-force-modal');
            // 送信
            _ajax.submit(formId, login, function(xhr, status, errorThrown){
                // エラーをセットする
                _error.set(formId, xhr, status, errorThrown, false);
            });
            return false;
        });

        // 同意してログインするボタンクリック時
        $(document).on('click', '#login-form-terms-submit', function () {
            let formId = $(this).data('login-form');
            $(formId).append($('<input></input>', {
                'type' : 'hidden',
                'name' : 'terms_login',
                'value' : 1
            }));
            _modal.hide('#login-terms-modal');
            // 送信
            _ajax.submit(formId, login, function(xhr, status, errorThrown){
                // エラーをセットする
                _error.set(formId, xhr, status, errorThrown, false);
            });
            return false;
        });

        // 撮影ボタンクリック時
        $(document).on('click', '[data-facial-save-modal=remote_rollcall-facial-modal]', function() {
            const capture = faceAuthRegister.capture();
            $('#login-form').append($('<input></input>', {
                'type' : 'hidden',
                'name' : 'image',
                'value' : capture
            }));
            _modal.hide('#remote_rollcall-facial-modal');
            // 送信
            _ajax.submit('#login-form', login, function(xhr, status, errorThrown){
                $('input[name=image]').remove();
                // エラーを表示
                _error.omitted(xhr, status, errorThrown);
            });
            return false;
        });

        $("#login-terms").on('scroll', function(){
            if ($(this).scrollTop() >= $(this)[0].scrollHeight - $('#login-modal-header').height() - $(this).height()) {
                $('#login-form-terms-submit').removeAttr('disabled');
            }
         });

    });
}
