/**
 * オーバーレイを表示する
 */
exports.show = function () {
    $('#overlay').fadeIn();
};
/**
 * オーバーレイを非表示にする
 */
exports.hide = function () {
    $('#overlay').fadeOut();
};