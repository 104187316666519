const { includes, indexOf } = require("lodash");

/**
 * 社員台帳ページ用のスクリプト
 */
if (is_route('user.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        user_event_set();
        user_setting_event_set();
        accident_setting_event_set();
        violation_setting_event_set();

    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.user_event_set = function() {

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.user_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });

    /*---- 有給履歴表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-user-paid_holiday-modal]', function () {
        _modal.create(this, 'user-paid_holiday-modal', ['id']);
    });

    /*---- 印刷ボタン押下時 ----*/

    // 台帳の印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        datas.push({'name': 'id', 'value': $(this).data('id')});
        let action = $(this).data('print');
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 社員台帳一覧の印刷時
    $(document).on('click', '[data-total-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('total-print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 統括運行管理者当選任者一覧の印刷時
    $(document).on('click', '[data-operation-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('operation-print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        let modalId = $(this).data('setting-modal');

        _modal.create(this, 'setting-modal', ['id', 'type', 'detail_user_id'], false, function(){
            if (modalId.indexOf('violation') !== -1) {
                /*---- 表示初期化 ----*/
                violation.init();
            }
        });
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        var modalId = $(this).data('save-modal');
        _modal.submit(this, 'save-modal', function (results) {
            if (modalId.indexOf('accident') !== -1) {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.accident.user_id + ']').trigger('click');
            } else if (modalId.indexOf('violation') !== -1) {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.violation.user_id + ']').trigger('click');
            } else if (modalId.indexOf('guidance') !== -1) {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.detail_user_id + ']').trigger('click');
            } else {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.user.id + ']').trigger('click');
            }
        }, function (xhr) {
            // BadRequest時
            if (xhr.status === 400) {
                let json = xhr.responseJSON;
                let errors = json.errors;
                user_set_file_error(modalId, errors);
            }
            if (modalId.indexOf('accident') < 0 && modalId.indexOf('violation') < 0) {
                user_set_steper_error();
            }
        });
    });

    /*---- 一括削除チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#user-all-delete', function () {
        $(".user-delete").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.user-delete', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#user-all-delete, .user-delete', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#user-multi-delete-button').prop('disabled', ($('.user-delete:checked').length == 0));
        $('#user-multi-delete-button').toggleClass('display-none', ($('.user-delete:checked').length == 0));
    });

    /*---- 削除確認時 ----*/

    // 一括削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        var modalId = $(this).data('confirm-modal');
        if (modalId.indexOf('accident') !== -1 || modalId.indexOf('violation') !== -1 || modalId.indexOf('guidance') !== -1) {
            _modal.create(this, 'confirm-modal', ['id']);
        } else {
            var ids = ($(this).data('id') == null ? [] : [$(this).data('id')]);
            if ($(this).data('id') == null) {
                $('.user-delete[name="ids[]"]:checked').map(function() {
                    ids.push($(this).val());
                });
            }
            if (ids.length > 0) {
                _modal.create_with_data(this, 'confirm-modal', {'ids': ids});
            }
        }
    });

    // 指導記録登録モーダルでの削除確認時
    $(document).on('click', '[data-guidance-confirm-modal]', function () {
        _modal.create(this, 'user-guidance-delete-modal', ['id', 'detail_user_id']);
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        var modalId = $(this).data('delete-modal');
        _modal.submit(this, 'delete-modal', function(results) {
            if (modalId.indexOf('accident') !== -1) {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.accident.user_id + ']').trigger('click');
            } else if (modalId.indexOf('violation') !== -1) {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.violation.user_id + ']').trigger('click');
            } else {
                _modal.hide('#user-detail-modal');
            }
        });
    });

    // 指導記録登録モーダルでの削除実行時
    $(document).on('click', '[data-guidance-delete-modal]', function () {
        // 送信前にエラーをリセット
        _error.reset()
        // post送信
        _ajax.submit('#guidance-delete-modal-form', function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // コンテンツの読込
            load_contents(function() {
                $('[data-detail-modal=user-detail-modal][data-id=' + results.detail_user_id + ']').trigger('click');
            });
        }, function(xhr, status, errorThrown){});

    });

    /*---- 履歴表示時 ----*/

    // 履歴表示時
    $(document).on('click', '[data-history-modal]', function () {
        _modal.create(this, 'history-modal', ['id', 'page']);
        return false;
    });

    // 職種履歴表示時
    $(document).on('click', '[data-occupation-history-modal]', function () {
        _modal.create(this, 'occupation-history-modal', ['user_id', 'id', 'page']);
        return false;
    });

    /*---- 基本情報「表示」ボタンを押下時 ----*/

    // モーダル表示時（緊急連絡先・家族情報・職務履歴等）
    $(document).on('click', '[data-view-more-detail-modal]', function () {
        _modal.create(this, 'view-more-detail-modal', ['id', 'page', 'target_information']);
        return false;
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#user-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#user-csv-setting-modal-csv-submit-message').empty();
        $('#user-csv-setting-modal-csv-error').addClass('display-none');
        $('#user-csv-setting-modal-csv-error').empty();
        $('#user-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#user-csv-setting-modal-csv', function () {
        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.user_setting_event_set = function() {

    /*---- 営業所の選択時 ----*/

    // 営業所の選択時
    $(document).on('change', '#user-setting-modal-section_id', function() {
        let action = $('#occupation_detail_forms').data('action');
        // 送信
        _ajax.post(action, [
            {'name': 'id', 'value': $('#user-setting-modal-id').val()},
            {'name': 'section_id', 'value': $(this).val()},
        ], function (results) {
            $('#occupation_detail_forms').html(results);
            $('#operation-supervision-license').toggleClass('display-none', true);
            $('#operation-manager-license').toggleClass('display-none', true);
            initialize('datepicker');
            $('.occupation_detail').toggleClass('display-none', true);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    });

    /*---- 画像の選択時 ----*/

    $(document).on('change', '#user-setting-modal-image', function() {
        if ($('input[name=image_taken_date]').val().length != 0) {
            return;
        }
        const month = moment().format($('#user-setting-modal-image_taken_date').data('display-format'));
        $('#user-setting-modal-image_taken_date').val(month)
        $('input[name=image_taken_date]').val(moment().format('yyyy-MM'));
    });

    /*---- ステータス変更時 ----*/

    $(document).on('change', '#user-setting-modal-status', function() {
        $('.retire-forms').toggleClass('display-none', ($(this).data('retire-type') != $(this).val()));
    });

    /*---- 退職タイプ変更時 ----*/

    $(document).on('change', 'input[name=retire_type]', function() {
        $('#retire-type-other-form').toggleClass('display-none', $(this).val() != $(this).data('retire-type-other'));
    });

    /*---- 職種の選択時 ----*/

    // 職種の選択時
    $(document).on('change', '.occupation-check input[name="occupation_ids[]"]', function() {
        let occupationIds = [];
        let occupationTypes = [];
        // チェックが入っているoccupation_idをArrayに格納
        $(this).parents('.occupation-check').find('input[name="occupation_ids[]"]').each(function() {
            if ($(this).prop('checked')) {
                occupationIds.push($(this).val());
                occupationTypes.push(Number($(this).data('occupation-type')));
            }
        });
        // チェックが入っていないbladeを非表示にする
        $('.occupation_detail').each(function() {
            $(this).toggleClass('display-none', !(occupationIds.find(v => v == $(this).data('occupation_id'))));
        });
        $('#operation-supervision-license').toggleClass('display-none', !(occupationTypes.find(v => v == $('#operation-supervision-license').data('occupation_type'))));
        let types = $('#operation-manager-license').data('occupation_type').split(',');
        let isShow = false;
        for (const type of types) {
            type = Number(type);
            if (occupationTypes.indexOf(type) >= 0){
                isShow = true;
                break;
            }

        }
        $('#operation-manager-license').toggleClass('display-none', !isShow);

    });

    /*---- 雇用形態と入社日の変更時 ----*/

    $(document).on('change', '#user-setting-modal-employment_id, #user-setting-modal-employ_date', function() {
        let employmentId = $('#user-setting-modal-employment_id').val();
        if (employmentId > 0) {
            let action = $('#paid-holiday-setting').data('action');
            let data = $('#user-setting-modal-form').serialize();
            _ajax.post(action, data, function (results) {
                $('#paid-holiday-setting').html(results);
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            }, 'html');
        }
    });

    /*---- 雇入年月日と最低有給日数の変更時 ----*/

    $(document).on('change', '#user-setting-modal-employ_date, #user-setting-modal-holiday_min', function() {
        if ($('#user-setting-modal-holiday_rest').val().length > 0 || $('#user-setting-modal-employ_date').val().length == 0 || $('#user-setting-modal-holiday_min').val().length == 0) {
            return;
        }
        let holidayRest = 0;
        let employDate = moment($('#user-setting-modal-employ_date').val(), 'YYYY-MM-DD');
        let holidayMin = $('#user-setting-modal-holiday_min').val();
        let now = moment();
        if (now.isAfter(employDate)) {
            let years = Math.ceil(now.diff(employDate, 'years', true));
            holidayRest = holidayMin * years;
        }
        $('#user-setting-modal-holiday_rest').val(holidayRest + $('#user-setting-modal-holiday_rest').data('suffix'));
        $('input[name=holiday_rest]').val(holidayRest).trigger('change');
    });

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);

        // 別ステップ移動時に、bs_step_trigger関数で、元々開いていたnav-tabsコンテンツのshowクラスが消えるので追加
        let firstNav = $('#baseNavTab [data-toggle="tab"]:first');
        $('#baseNavTab [data-toggle="tab"]').removeClass('active');
        firstNav.addClass('active');
        let contentId = firstNav.attr('href');
        $(contentId).addClass('show').addClass('active');
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#user-setting-modal';
        let formId = '#user-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#user-setting-modal-confirm_step').val($(this).data('confirm-step'));
        // 送信前にエラーをリセット
        _error.reset()
        user_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            bs_step_trigger(nextPartTrigger);
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // BadRequest時
            if (xhr.status === 400) {
                let json = xhr.responseJSON;
                let errors = json.errors;
                user_set_file_error(modalId, errors);
            }
            // ステップエラーを表示
            user_set_steper_error();
        });
    });

    /*---- 基本情報ステップボタンクリック時 ----*/

    $(document).on('click', '.bs-stepper .step:first', function () {
        // 別ステップ移動時に、bs_step_trigger関数で、元々開いていたnav-tabsコンテンツのshowクラスが消えるので追加
        let activeNav = $('#baseNavTab .active[data-toggle="tab"]');
        let contentId = activeNav.attr('href');
        $(contentId).addClass('show');
    });

    /*---- 資格情報の追加ボタンを押下(資格入力項目を追加する) ----*/

    $(document).on('click', '#other-license button[name="add_license"]', function(){
        var count = $('#other-license .card').length;
        var card = $('#other-license .card:first').clone();

        // 削除項目あれば消す
        card.find('input[name="license_delete[]"]').remove();
        card.find('#label_license_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/_0/g , '_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // Name書き換え
            $(this).attr('name', $(this).attr('name').replace('[0]', '['+count+']'));

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }

            // ファイル選択ボタン属性書き換え
            if($(this).attr('type') == 'file'){
                $(this).attr('data-file-dropzone', $(this).attr('data-file-dropzone').replace(/_0/g , '_' + count));
                $(this).attr('data-name', $(this).attr('data-name').replace('[0]', '['+count+']'));
            }
            // 初期値クリア
            $(this).attr('data-old', '');
        });

        // ファイル選択モーダル：ID書き換え
        card.find('.modal').each(function() {
            var modalId = $(this).attr('id');
            $(this).attr('id', modalId.replace(/_0/g , '_' + count));
        });
        // ファイル選択モーダル：error初期化
        var fileError = card.find('.files-error:first');
        if (fileError.length > 0){
            fileError.attr('id', fileError.attr('id').replace(/_0/g , '_' + count));
            fileError.find('span').html('');
        }
        // ファイル選択モーダル：「ファイル名」リンクを削除
        card.find('span.filename-preview-latest').each(function() {
            $(this).attr('title', '');
            $(this).empty();
        });
        // ファイル選択モーダル：「最新」チェックボックス非表示
        card.find('input.files-setting-latest').each(function() {
            $(this).addClass('display-none');
            $(this).prop('checked', false);
        });
        // ファイル選択モーダル：「ファイル名」ID書き換え
        card.find('span.filename-preview').each(function() {
            $(this).attr('id', $(this).attr('id').replace(/_0/g , '_' + count));
            $(this).attr('title', '');
            $(this).empty();
        });
        // ファイル選択モーダル：「更新日時」クリア
        card.find('.files-setting-updated_at').each(function() {
            $(this).empty();
        });
        // ファイル選択モーダル：「DnD」ID書き換え
        card.find('.file-dropzone').each(function() {
            $(this).attr('id', $(this).attr('id').replace(/_0/g , '_' + count));
        });
        card.find('span.file-delete').each(function() {
            $(this).empty();
        });
        // ファイル選択モーダル：ボタンID書き換え
        card.find('[data-files-setting-modal]').each(function() {
            $(this).attr('data-files-setting-modal', $(this).attr('data-files-setting-modal').replace(/_0/g , '_' + count));
        });
        card.find('[data-files-dispose-modal]').each(function() {
            $(this).attr('data-files-dispose-modal', $(this).attr('data-files-dispose-modal').replace(/_0/g , '_' + count));
        });

        // 最後に追加
        card.insertAfter('#other-license .card:last');

        initialize();

        // 50件になったら追加ボタン殺す
        if (count + 1 >= 50) {
            $(this).prop('disabled', true);
        }
    });

    /*---- 緊急連絡先の追加ボタンを押下(緊急連絡先入力項目を追加する) ----*/

    $(document).on('click', '#emergency button[name="add_emergency"]', function() {
        var count = $('#emergency .card').length;
        var card = $('#emergency .card').first().clone();

        // 削除項目あれば消す
        card.find('input[name="emergency_delete[]"]').remove();
        card.find('#label_emergency_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/_0/g , '_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最後に追加
        var last = $('#emergency .card').last();
        card.insertAfter(last);

        initialize();

        // 2件になったら追加ボタン殺す
        if (count + 1 >= 2) {
            $(this).prop('disabled', true);
        }
    });

    /*---- 家族構成の追加ボタンを押下(家族構成入力項目を追加する) ----*/

    $(document).on('click', '#family-structure button[name="add_family_structure"]', function() {
        var count = $('#family-structure .card').length;
        var card = $('#family-structure .card').first().clone();

        // 削除項目あれば消す
        card.find('input[name="family_structure_delete[]"]').remove();
        card.find('#label_family_structure_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/_0/g , '_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最後に追加
        var last = $('#family-structure .card').last();
        card.insertAfter(last);

        initialize();

        // 20件になったら追加ボタン殺す
        if (count + 1 >= 20) {
            $(this).prop('disabled', true);
        }
    });

    /*---- 職務履歴の追加ボタンを押下(職務履歴入力項目を追加する) ----*/

    $(document).on('click', '#job-history button[name="add_job_history"]', function() {
        var count = $('#job-history .card').length;
        var card = $('#job-history .card').first().clone();

        // 削除項目あれば消す
        card.find('input[name="job_history_delete[]"]').remove();
        card.find('#label_job_history_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {

            $(this).val('');

            // Form::monthで生成されるinput hiddenは、idが取得できない為別処理
            if ($(this).attr('type') !== 'hidden' || !$(this).hasClass('month-picker-value')) {
                var id = $(this).attr('id');
                var newId = id.replace(/_0/g , '_' + count);
                var label = card.find('label[for="' + id + '"]');
                var error = card.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                label.attr('for', newId);

                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
        });

        // 最後に追加
        var last = $('#job-history .card').last();
        card.insertAfter(last);

        initialize();

        // 20件になったら追加ボタン殺す
        if (count + 1 >= 20) {
            $(this).prop('disabled', true);
        }
    });

    // 会社の選択時
    $(document).on('change', '#user-setting-modal-company_id', function() {
        // 社員ID(code)自動生成ボタンON/OFF
        if ($('#generate-user-code').length > 0) {
            if ($(this).val().length > 0) {
                $('#generate-user-code').prop('disabled', false);
            } else {
                $('#generate-user-code').prop('disabled', true);
            }
        }
    });
    // 自動生成実行
    $(document).on('click', '#generate-user-code', function () {
        let action = $(this).data('action');
        // codeセット
        _ajax.post(action, [
            {'name': 'company_id', 'value': $('#user-setting-modal-company_id').val()},
        ], function (results) {
            $('#user-setting-modal-user_code').val(results.generated);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
window.user_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#belong-part-trigger').toggleClass('error', ($('#belong-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#occupation-part-trigger').toggleClass('error', ($('#occupation-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#license-part-trigger').toggleClass('error', ($('#license-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#insurance-part-trigger').toggleClass('error', ($('#insurance-part').find('.invalid-feedback:not(.copy)').length > 0));
        // 基本情報タブ
        $('#base-part').find('.tab-pane').each(function (index, element) {
            let key = $(this).attr('id');
            $('#base-part').find("a[href='#"+key+"']").toggleClass('error', $(this).find('.invalid-feedback:not(.copy)').length > 0);
        });
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#belong-part-trigger').removeClass('error');
        $('#occupation-part-trigger').removeClass('error');
        $('#license-part-trigger').removeClass('error');
        $('#insurance-part-trigger').removeClass('error');
        // 基本情報タブ
        $('#base-part').find('.tab-pane').each(function (index, element) {
            let key = $(this).attr('id');
            $('#base-part').find("a[href='#"+key+"']").removeClass('error');
        });
    }
}

/**
 * 資格証・ファイル エラーを表示
 * @param modalId
 * @param errors
 */
window.user_set_file_error = function (modalId, errors) {
    let selector = create_id(modalId);
    $.each(errors, function(name, message) {
        // 資格証
        let fileSelector;
        if (name.indexOf('issuance_file') !== -1) {
            // エラー表示箇所のID： user-setting-modal-issuance_file_数字-errors
            // 返ってくるエラーのキー： issuance_file.数字.remarks.数字
            fileSelector = selector + '-' + name.split('.')[0] + '_' + name.split('.')[1] + '-errors';
        }
        // ファイルタブ
        if (name.indexOf('user_file') !== -1) {
            // エラー表示箇所のID： user-setting-modal-user_file-数字_remarks-errors
            // 返ってくるエラーのキー： user_file.remarks.数字
            fileSelector = selector + '-' + name.split('.')[0] + '-' + name.split('.')[2] + '_' + name.split('.')[1] + '-errors';
        }
        if ($(fileSelector).length > 0) {
            _error.message(fileSelector, message);
        }
    });
}
