/**
 * 積卸地設定ページ用のスクリプト
 */
 if (is_route('consignor.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        consignor_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.consignor_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], false, function() {
            consignor_setting_event_set();
        });
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
}

/**
 * 設定モーダルイベントセット
 */
window.consignor_setting_event_set = function() {
    /*---- 地図初期化 ----*/

    let map = $('#consignor-setting-modal').find('.zdc-map');
    // modal内の要素の幅、高さを性格に取得するため、モーダル生成後1秒おいて処理する
    window.setTimeout(function() {
        var options = {};
        if (map.length > 0) {
            map.attr('width', map.width() + 'px');
            map.attr('height', map.height() + 'px');
            if (map.data('zip_lat') !== '' || map.data('zip_lon') !== '') {
                options = {
                    lat: map.data('zip_lat'),
                    lon: map.data('zip_lon'),
                }
            }
            _zdcMap.Map.init(map, options, true);
        }
    }, 1000);

    /*---- 要素リサイズ時地図エリア・地図リサイズ ----*/

    $(window).resize(function() {
        if (map.length > 0) {
            map.attr('width', map.width() + 'px');
            map.attr('height', map.height() + 'px');
            _zdcMap.Map.refresh();
        }
    });

    /*---- 郵便番号変更 ----*/

    // 住所から緯度経度を取得し、地図中心を移動する
    $('input[name="zipcode"]').change(function() {
        if (map.length > 0) {
            _zdcMap.Map.setFromZipcode($(this).val());
        }
    });

    /*---- 都道府県変更 ----*/

    // 住所から緯度経度を取得し、地図中心を移動する
    $('select[name="prefecture"]').change(function() {
        if (map.length > 0) {
            _zdcMap.Map.setFromAddress(
                $(this).val(),
                $('input[name="municipality"]').val(),
                $('input[name="address"]').val()
            );
        }
    });

    /*---- 市区町村変更 ----*/

    // 住所から緯度経度を取得し、地図中心を移動する
    $('input[name="municipality"]').change(function() {
        if (map.length > 0) {
            _zdcMap.Map.setFromAddress(
                $('select[name="prefecture"]').val(),
                $(this).val(),
                $('input[name="address"]').val(),
            );
        }
    });

    /*---- 住所変更 ----*/

    // 住所から緯度経度を取得し、地図中心を移動する
    $('input[name="address"]').change(function() {
        if (map.length > 0) {
            _zdcMap.Map.setFromAddress(
                $('select[name="prefecture"]').val(),
                $('input[name="municipality"]').val(),
                $(this).val()
            );
        }
    });
}

