/**
 * DnDイベント処理を設定する
 */
$(function () {
    // ドラッグが許可されたエレメントのドラッグ開始時
    $(document).on('dragstart', '[draggable=true]', function() {
        let dragId = event.target.id;
        let dropzone = $(this).data('dropzone');
        event.dataTransfer.setData('id', dragId);
        $(create_id(dragId)).addClass('is_dragging');
        $(dropzone).addClass('dropzone');
        $(document).on('dragover', dropzone, function() {
            event.preventDefault();
        });
    });
    // ドラッグが許可されたエレメントのドラッグ終了時
    $(document).on('dragend', '[draggable=true]', function() {
        let dragId = event.target.id;
        let dropzone = $(this).data('dropzone');
        $(create_id(dragId)).removeClass('is_dragging');
        $(dropzone).removeClass('dropzone');
        $('.dragover').removeClass('dragover');
    });
    // ドロップエリアに入った時
    $(document).on('dragenter, dragover', '.dropzone, .dropzone *', function() {
        let dropzone = $(this);
        if (!$(this).hasClass('dropzone')) {
            dropzone = $(this).parents('.dropzone');
        }
        $(dropzone).addClass('dragover');
    });
    // ドロップエリアから外れた時
    $(document).on('dragleave', '.dropzone', function() {
        $(this).removeClass('dragover');
    });
});