/**
 * 全ページ共通のスクリプト
 */
 $(document).on('bss:onload', function() {

    /*---- 受付設定時 ----*/

    $(document).on('click', '[data-it_rollcall_config-setting-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'it_rollcall_config-setting-modal', [], false, function() {
            displayed_default_list();
        });
    });

    /*---- 設定保存時 ----*/

    $(document).on('click', '[data-it_rollcall_config-save-modal]', function() {
        _modal.submit(this, 'it_rollcall_config-save-modal');
    });

    const displayed_default_list = function() {
        $('.list-holding-input:checked').each(function() {
            const holdingItemId = create_id($(this).data('parent-item-id'));
            const companyTree = $(holdingItemId).find('.company-tree');
            $($(companyTree)).show(0, function() {
                $(companyTree).find('.list-company-input:checked').each(function() {
                    const companyItemId = create_id($(this).data('parent-item-id'));
                    const sectionTree = $(companyItemId).find('.section-tree');
                    $(sectionTree).show(0);
                });
                

            });
        });
    }

 });