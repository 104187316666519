/**
 * 操作履歴ページ用のスクリプト
 */
if (is_route('external_history.index')) {
    $(function () {
        external_history_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.external_history_event_set = function() {

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        console.info('a');
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let params = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, params);
    });

}
