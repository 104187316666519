function afterFileTypeCheck(accept, e) {

    let name = $(this).attr('data-name');
    let fn = $('input[name="_' + name + '"]');
    let file_value = $('input[name="' + name + '"]');
    let preview = $(create_id($(this).attr('data-preview')));
    let name_preview = $(".filename-" + name.replace('[]', ''));
    let del_check = $('input[name="' + name + '_del"]');

    if($(this).data('display') == 'list'){
        let sort = $(this).attr('data-sort');
        let elmId = $(this).attr('id');
        fn = $('#'+elmId+'_file_name');
        file_value = $('#'+elmId+'_value');
        preview = $('#'+elmId+'_image_preview');
        name_preview = $('#'+elmId+'_preview');
        del_check = $('#'+elmId+'_del');
        $('#'+elmId+'_latest').removeClass('display-none');
    }

    let path = $(this).val().replace(/\\/g, '/');
    let basename = path.split('/').reverse()[0];
    // ファイル名input
    if (fn.length > 0) {
        fn.val(basename);
    }
    // 表示用ファイル名
    if (name_preview.length > 0) {
        if (basename.length === 0) {
            name_preview.html(name_preview.attr('title'));
        }
    }
    // 表示用画像
    if (preview.length > 0) {
        if (basename.length === 0) {
            preview.attr('src', preview.data('old'));
        }
    }
    // 削除チェック
    if (del_check.length > 0) {
        del_check.prop('checked', false);
        if (basename.length === 0) {
            del_check.prop('disabled', false);
        } else {
            del_check.prop('disabled', true);
        }
    }
    // ファイルクリア時は元に戻す
    if (basename.length === 0) {
        file_value.val(file_value.data('old'));
    }

    if (accept != null && !accept.startsWith('text')) {
        var reader = new FileReader();
        reader.onload = function (e) {
            if (e.target.result.length > 0) {
                name_preview.html(basename);
                if (preview.length > 0) {
                    preview.attr('src', e.target.result);
                }
            } else {
                name_preview.html('File Abort!');
            }
            file_value.val(e.target.result);
        }
        if (e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }
}

/**
 * ファイルのイベントを設定する
 */
 $(function () {

    // カメラ画像フォーム
    $(document).on('click', '.camera-image-inputs .camera-image-upload', function(e) {

        const setCameraModal = function(elm) {
            const name = $(elm).data('name');
            const preview = $(elm).data('preview');
            _modal.show('#camera-modal');

            // カメラ切替オプション生成
            (async () => {   
                let devices = await navigator.mediaDevices.enumerateDevices();
                let videoId = '#camera-modal-video';
                _camera.create_options(videoId, devices);
                if(devices.length > 0){
                    let deviceId = $(create_id(videoId + '-inputs')).val();
                    _camera.change('camera-modal-video', deviceId, function(){
                        $('#camera-modal-action').attr('disabled', false);
                    });
                }else{
                    _camera.start('camera-modal-video', function(){
                        $('#camera-modal-action').attr('disabled', false);
                    });
                }
            })();

            // カメラプルダウン変更時イベントを追加
            $(document).off('change', '#camera-modal-video-inputs');
            $(document).on('change', '#camera-modal-video-inputs', function() {
                // カメラ切替
                _camera.change('camera-modal-video', $(this).val());
            });

            $('#camera-modal-action').off('click');
            $('#camera-modal-action').on('click', function() {
                const capture = _camera.capture('camera-modal-video', 'camera-modal-canvas')
                $('input[name=' + name + ']').val(capture);
                $(create_id(preview)).attr('src', capture);
                $(create_id(preview)).data('old', capture);
                $(elm).parents('.camera-image-inputs').find('.camera-image-delete').toggleClass('display-none', false);
                _modal.hide('#camera-modal');
                return false;
            });
            $('#camera-modal').off('hidden.bs.modal');
            $('#camera-modal').on('hidden.bs.modal', function() {
                _camera.end('camera-modal-video');
            });
        }

        if ($('#camera-modal').hasClass('show')) {
            _camera.end('camera-modal-video');
            _modal.hide('#camera-modal');
        }
        setCameraModal(this);
    });

    // カメラ画像削除時
    $(document).on('change', '.camera-image-inputs .camera-image-delete-input', function() {
        const inputs = $(this).parents('.camera-image-inputs');
        const upload = $(inputs).find('.camera-image-upload');
        const name = $(upload).data('name');
        const preview = $(upload).data('preview');
        if ($(this).prop('checked')) {
            console.log(create_id(preview));
            console.log($(create_id(preview)).data('default-preview'));
            $(create_id(preview)).attr('src', $(create_id(preview)).data('default-preview'));
            $('input[name=' + name + ']').val('');
        } else {
            $(create_id(preview)).attr('src', $(create_id(preview)).data('old'));
            $('input[name=' + name + ']').val($(create_id(preview)).data('old'));
        }
    });

    // 画像選択時の処理
    $(document).on('change', '.upload_file', function(e) {
        let accept = $(this).attr('accept');
        const file = e.target.files[0]
        const fileTypeKey = accept.toLowerCase().endsWith('pdf')?'pdf':(accept.toLowerCase().startsWith('image')?'image':'')
        const fileTypeWaningKey = fileTypeKey==='pdf'?'pdf':(fileTypeKey==='image'?'画像':'')
        if(e.target.files.length > 0 && (fileTypeKey==='pdf' || fileTypeKey==='image'))
        {
            const warningMsg = fileTypeWaningKey+'ファイルを選択してください。'
            if(!file.type.toLowerCase().includes(fileTypeKey))
            {
                e.preventDefault()
                alert(warningMsg)
                return
            }
            _getFileType(file)
                .then(mimeObj => {
                    if (mimeObj.mime.includes(fileTypeKey) ) {
                        afterFileTypeCheck.call(this, accept, e)
                    } else {
                        e.preventDefault()
                        alert(warningMsg)
                    }
                }).catch(console.error)
        }
        else {
            afterFileTypeCheck.call(this, accept, e)
        }
    });

    /** 複数ファイルアップロード関連 */

    // ファイル選択時の処理
    $(document).on('click', '.select_files', function(e) {
        _modal.show($(this).attr('data-files-setting-modal'));
    });

    // ファイル選択モーダルの最新フラグ選択時
    $(document).on('click', '.files-setting-latest', function(e) {
        let checked = this;
        if ($(checked).prop('checked')){
            $('.files-setting-latest').each(function(){
                if($(this).val() == $(checked).val()){
                    return true;
                }
                $(this).prop('checked', false);
            });
        }
    });

    /** ファイル変更内容破棄 */

    // 破棄確認モーダル表示
    $(document).on('click', '.files-setting-cancel, .files-setting-close', function(e) {
        var modalId = $(this).attr('data-files-dispose-modal');
        _modal.show(modalId);
    });

    // 破棄確認モーダル：戻るボタン押下時の処理
    $(document).on('click', '.files-dispose-cancel, .files-dispose-close', function(e) {
        var modalId = $(this).attr('data-files-dispose-modal');
        _modal.hide(modalId);
    });

    // 破棄確認モーダル：破棄ボタン押下時の処理
    $(document).on('click', '.files-dispose-execute', function(e) {
        var modalId = $(this).attr('data-files-dispose-modal');
        _modal.hide(modalId);
        var settingModalId = $(this).attr('data-files-setting-modal');
        undoFilesSettingDatas(settingModalId);
        _modal.hide(settingModalId);
    });

    /**
     * ファイル管理の変更内容を破棄する
     * @param {*} modalId 
     */
    function undoFilesSettingDatas(modalId) {
        var modal = $(modalId);
        $(modal).find('.files-setting-latest').each(function() {
            var old = $(this).attr('data-old');
            $(this).prop('checked', false);
            if(old.indexOf('checked') !== -1){
                $(this).prop('checked', true);
            }
        });
        $(modal).find('.image-preview').each(function() {
            $(this).find('img').each(function() {
                $(this).attr('src', $(this).attr('data-old'));
            });
        });
        $(modal).find('.filename-preview').each(function() {
            var oldUrl = $(this).attr('data-old');
            var oldTitle = $(this).attr('title');
            $(this).empty();
            if(oldUrl){
                $(this).html('<a href="'+oldUrl+'" target="_blank">'+oldTitle+"</a>");
            }
        });
        $(modal).find('.files-setting-date, .files-setting-remarks').each(function() {
            var old = $(this).attr('data-old');
            $(this).val(old);
        });
        $(modal).find('.files-setting-file-value').each(function() {
            var old = $(this).attr('data-old');
            $(this).val(old);
            if(old.length == 0){
                // 新規でファイルを選択した場合、最新チェックボックスを非表示にする
                $(this).closest('tr').find('.files-setting-latest').addClass('display-none');
            }
        });
        $(modal).find('.files-setting-file-name').each(function() {
            var old = $(this).attr('data-old');
            $(this).val(old);
        });
        $(modal).find('.delete_file').each(function() {
            $(this).prop('checked', false);
            $(this).prop('disabled', false);
        });
    }

    // ファイル選択モーダルの確定ボタン押下時の処理
    $(document).on('click', '.files-setting-save', function(e) {
        var modalId = $(this).attr('data-files-setting-modal');
        _modal.hide(modalId);
    });

});
