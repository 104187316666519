/**
 * 通常のリダイレクトが発生する場合のメッセージ処理（ajaxでの送受信エラーの場合は使用しません）。
 * 以下のファイルで非表示の要素にエラーと完了メッセージが定義された場合にtoastrで表示する。
 * 
 * resources/views/common/complete.blade.php
 * resources/views/common/error.blade.php
 */
$(function () {
    // 完了とエラーメッセージをtoastrで表示する
    $('.errors li').each(function (i, elm) {
        toastr.warning($(elm).text());
    });
    $('.complete li').each(function (i, elm) {
        toastr.success($(elm).text());
    });
});