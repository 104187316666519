/**
 * カメラを起動しVideoに出力する
 * 
 * @param {string} videoId 
 * @param {CallableFunction} loaded 
 * @param {string} deviceId
 */
exports.start = function (videoId, loaded = null, deviceId = null, arg = null) {
    const video = document.getElementById(videoId);
    let options = {
        width: 500,
        height: 500
    };
    options['deviceId'] = deviceId;
    navigator.mediaDevices.getUserMedia({
        audio: false,
        video: options
    }).then((stream) => {
        video.srcObject = stream;
        video.onloadedmetadata = (e) => {
            video.play();
            if (loaded != null) {
                loaded(arg);
            }
        };
    }).catch((err) => {
        toastr.warning($(create_id(videoId)).data('error'));
    });
}
/**
 * キャプチャをデータで取得する
 * 
 * @param {string} videoId 
 * @param {string} canvasId 
 * @param {int} width 
 * @param {int} height 
 * @returns 
 */
exports.capture = function(videoId, canvasId, width = 500, height = 500) {
    const video = document.getElementById(videoId);
    let canvas = document.getElementById(canvasId);
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
}
/**
 * カメラを終了する
 * @param {string} videoId 
 */
exports.end = function (videoId) {
    const video = document.getElementById(videoId);
    const stream = video.srcObject;
    if (stream != null) {
        const tracks = stream.getTracks();
        tracks.forEach(function(track) {
            track.stop();
        });
    }
    video.srcObject = null;
}
/**
 * 指定したカメラに切り替える
 * @param {string} videoId 
 * @param {string} deviceId 
 * @param {CallableFunction} loaded 
 */
exports.change = function (videoId, deviceId, loaded = null, arg = null) {
    // 起動中のカメラを停止する
    _camera.end(videoId);
    _camera.start(videoId, loaded, deviceId, arg);
}
/**
 * 選択可能なカメラオプションを生成する
 * @param {string} videoId 
 * @param {mixed} devices 
 * @param {string|null|bool} defaultDevice
 */
exports.create_options = function (videoId, devices, defaultDevice = false)
{
    let defaultVideoInputGroupId = null;
    $(create_id(videoId + '-inputs')).empty();
    devices.forEach(device => {
        if (device.deviceId == '' || device.kind != 'videoinput') {
            return;
        }
        if (device.deviceId == 'default') {
            defaultVideoInputGroupId = device.groupId;
        } else {
            const option = $('<option></option>', {
                'value': device.deviceId,
                'data-group': device.groupId,
            }).text(device.label);
            $(option).prop('selected', (device.groupId == defaultVideoInputGroupId));
            $(create_id(videoId + '-inputs')).append(option);
        }
    });
    if (defaultDevice !== false) {
        $(create_id(videoId + '-inputs option')).each(function(i, elm) {
            $(elm).prop('selected', ($(elm).val() == defaultDevice));
        });
    }
}