/**
 * 依頼先設定ページ用のスクリプト
 */
 if (is_route('request_destination.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        request_destination_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.request_destination_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        //自社・他社で「他社」指定以外の場合、依頼先をクリアする
        /* 自社・他社設定利用時に有効化　※「自社・他社設定利用時」で検索
        if(false == $('input#request_destination-setting-modal-own_or_other_2').prop('checked')) {
            $('input#request_destination-setting-modal-name').val('');
        } */
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    /*---- 自社・他社の選択による依頼先表示・非表示設定 ----*/
    /* 自社・他社設定利用時に有効化　※「自社・他社設定利用時」で検索
    $(document).on('change', '.request_destination-setting-own_or_other-type', function () {
        let type = $(this).val();
        if (type == '1') {
            $('#name-container').addClass('display-none');
        } else {
            $('#name-container').removeClass('display-none');
        }
    }); */
}
