/**
 * モーダル画面を表示する
 * @param {*} modalId
 */
exports.show = function (modalId) {
    // モーダルを開く
    $(modalId).modal('show');
};
/**
 * モーダル画面を閉じる
 * @param {*} modalId
 */
exports.hide = function(modalId) {
    // 以下のクラスが残りスクロールできなくなる問題があるためクラスを削除する
    $('.modal-open').removeClass('modal-open');
    // モーダルを閉じる
    $(modalId).modal('hide');
};
/**
 * モーダル画面を作成して表示する
 * @param {*} modalId
 */
exports.create = function(elm, modalIdDataSelector, keys, isExtendQuery = false, callback = null, actionDataSelector = 'action') {
    // postデータ
    var datas = [];
    var modalId = create_id($(elm).data(modalIdDataSelector));
    var action = $(elm).data(actionDataSelector);
    $.each(keys, function(i, key) {
        if ($(elm).data(key) != null) {
            datas.push({'name' : key, 'value' : $(elm).data(key)});
        }
    });
    if (isExtendQuery) {
        action = extend_url_query(action);
    }
    // モーダルのコンテンツを空に
    $(modalId + ' .modal-content').empty();
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown) {
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
};
/**
 * モーダル画面を作成して表示する
 * @param {*} modalId
 */
 exports.create_with_data = function(elm, modalIdDataSelector, datas, isExtendQuery = false, callback = null, actionDataSelector = 'action') {
    // postデータ
    var modalId = create_id($(elm).data(modalIdDataSelector));
    var action = $(elm).data(actionDataSelector);
    if (isExtendQuery) {
        action = extend_url_query(action);
    }
    // モーダルのコンテンツを空に
    $(modalId + ' .modal-content').empty();
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
};
/**
 * モーダルコンテンツを更新する
 * @param {*} modalId
 * @param {*} action
 * @param {*} datas
 * @param {*} callback
 */
exports.update = function(modalId, action, datas, isExtendQuery = false, callback = null) {
    modalId = create_id(modalId);
    if (isExtendQuery) {
        action = extend_url_query(action);
    }
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
}
/**
 * モーダルのサブミット処理
 * @param {*} elm
 * @param {*} modalIdDataSelector
 * @param {*} loadContentCallback
 * @param {*} errorCallback
 * @param {*} formSelectorSuffix
 * @param {*} noLoad   loadContentCallback時、画面リロードしない場合は、trueにする
 */
exports.submit = function(elm, modalIdDataSelector, loadContentCallback = null, errorCallback = null, formSelectorSuffix = '-form', noLoad = false, beforeLoadContentCallback = null) {
    var modalId = create_id($(elm).data(modalIdDataSelector));
    var formId = modalId + formSelectorSuffix;
    // 送信前にエラーをリセット
    _error.reset()
    // post送信
    _ajax.submit(formId, function (results) {
        // リダイレクト指定時はリダイレクト
        if (results.redirect != null) {
            location.href = results.redirect;
            return;
        }
        // 完了メッセージをセットする
        _complete.set(results);
        // モーダルを閉じる
        _modal.hide(modalId);
        // コンテンツの読込
        if (beforeLoadContentCallback != null){
            beforeLoadContentCallback();
        }
        if (loadContentCallback != null) {
            // noLoad時は画面リロードしない
            if (noLoad) {
                loadContentCallback(results);
            } else {
                load_contents(function() {
                    loadContentCallback(results);
                });
            }
        } else {
            if (!noLoad) {
                load_contents();
            }
        }
    }, function(xhr, status, errorThrown){
        // エラーをセットする
        _error.set(modalId, xhr, status, errorThrown);
        if (errorCallback != null) {
            errorCallback(xhr);
        }
    });
};
