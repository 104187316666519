/**
 * 出勤区分設定ページ用のスクリプト
 */
 if (is_route('instruction.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        instruction_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.instruction_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], true);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
    
    /*---- 印刷時 ----*/
    $(document).on('click', '[data-total-print]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('total-print'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
