/**
 * 雇用形態設定ページ用のスクリプト
 */
 if (is_route('scheduled_holiday.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        scheduled_holiday_event_set();
        scheduled_holiday_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.scheduled_holiday_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], true, function() {
            scheduled_holiday_setting_set_form();
        });
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

}
/**
 * 設定モーダル表示時のイベント設定
 */
window.scheduled_holiday_setting_event_set = function() {

    /*---- 会社・対象年の変更時 ----*/

    $(document).on('change', '#scheduled_holiday-setting-modal-year, #scheduled_holiday-setting-modal-company_id', function() {
        scheduled_holiday_setting_set_form();
    });

    /*---- 法定休日の設定時 ----*/

    $(document).on('change', '#scheduled_holiday-setting-modal-legal_holiday', function() {
        scheduled_holiday_set_legal_holiday();
    });

    /*---- 所定休日の設定時 ----*/

    $(document).on('change', 'input[name="prescribed_holidays[]"]', function() {
        scheduled_holiday_set_prescribed_holidays(this);
    });

    /*---- 祝日の所定休日の一括設定時 ----*/

    $(document).on('change', '#scheduled_holiday-setting-modal-include_national_holiday', function() {
        scheduled_holiday_set_national_holiday_to_prescribed_holidays();
    });

    /*---- 日付クリック時 ----*/

    $(document).on('click', '#scheduled_holiday-setting-modal-calendars .selectable-date:not(.legal-holiday)', function() {
        scheduled_holiday_set_change_date(this);
    });
}
window.scheduled_holiday_setting_set_form = function() {
    // エラーを削除
    $('#scheduled_holiday-setting-modal .invalid-feedback').each(function() {
        $(this).empty();
    })
    // カレンダーを削除
    $('#scheduled_holiday-setting-modal-calendars').empty();
    $('#scheduled_holiday-setting-modal-calendar-marker').toggleClass('display-none', true);
    // 会社IDと年が指定されている場合は再読み込みする
    let id = $('#scheduled_holiday-setting-modal-id').val();
    let companyId = $('#scheduled_holiday-setting-modal-company_id').val();
    let year = $('#scheduled_holiday-setting-modal-year').parents('.year-picker-inputs').find('.year-picker-value').val();
    if (companyId == null || companyId.length == 0 || year == null || year.length == 0) {
        return;
    }
    let datas = [];
    datas.push({'name' : 'id', 'value' : id});
    datas.push({'name' : 'company_id', 'value' : companyId});
    datas.push({'name' : 'year', 'value' : year});
    _ajax.post($('#scheduled_holiday-setting-modal-calendars').data('action'), datas, function(results) {
        $('#scheduled_holiday-setting-modal-calendars').html(results);
        $('#scheduled_holiday-setting-modal-calendar-marker').toggleClass('display-none', false);
        scheduled_holiday_set_national_holiday();
        scheduled_holiday_set_legal_holiday();
        scheduled_holiday_set_prescribed_holidays();
    }, function(xhr, status, errorThrown){
        // エラーをセットする
        _error.set('#scheduled_holiday-setting-modal', xhr, status, errorThrown);
    }, 'html');
}
/**
 * 祝日をカレンダーにセットする
 */
window.scheduled_holiday_set_national_holiday = function() {
    // 既存の祝日をリセット
    $('#scheduled_holiday-setting-modal-calendars .national-holiday').each(function() {
        $(this).toggleClass('national-holiday', false);
        $(this).removeData('data-toggle');
        $(this).removeData('title');
        $(this).tooltip('dispose')
    });
    // 指定年度の祝日を取得
    let year = $('#scheduled_holiday-setting-modal-form [name=year]').val();
    let nationalHolidays = holiday.between(new Date(year + '-01-01'), new Date(year + '-12-31'));
    // クラス指定
    for(const index in nationalHolidays) {
        let date = moment(nationalHolidays[index]['date']);
        let day = $('#scheduled_holiday-setting-modal-calendars [data-date=' + date.format('YYYY-MM-DD') + ']');
        $(day).toggleClass('national-holiday', true);
        $(day).data('data-toggle', 'tooltip');
        $(day).data('title', nationalHolidays[index]['name']);
        $(day).tooltip();
    }
    // 祝日が全てチェック済みの場合は一括設定をOnに
    let nationalHolidayChecked = !($('#scheduled_holiday-setting-modal-calendars .national-holiday .selectable-date-input:not(:checked)').length > 0);
    $('#scheduled_holiday-setting-modal-include_national_holiday').prop('checked', nationalHolidayChecked);
}
/**
 * 法定休日の指定値をカレンダーに休日としてセットする
 */
window.scheduled_holiday_set_legal_holiday = function() {
    // 法定休日を取得
    let legalHoliday = $('#scheduled_holiday-setting-modal-legal_holiday').val();
    // 所定休日のチェックの無効化を解除
    $('.prescribed_holidays-input').each(function() {
        $(this).removeAttr('disabled');
    });
    // 法定休日がセットされた曜日の所定休日のチェックを無効化
    $('#scheduled_holiday-setting-modal-prescribed_holidays-value-' + legalHoliday).attr('disabled', 'disabled');
    // 有効な状態の場合はチェックを外す
    if ($('#scheduled_holiday-setting-modal-prescribed_holidays-value-' + legalHoliday).prop('checked')) {
        // 法定休日がセットされた曜日の選択状態を解除
        $('#scheduled_holiday-setting-modal-prescribed_holidays-value-' + legalHoliday).prop('checked', false).trigger('change');
    }
    // 既存の法定休日をリセット
    $('#scheduled_holiday-setting-modal-calendars .legal-holiday').each(function() {
        $(this).find('.selectable-date-input').prop('checked', false);
        $(this).toggleClass('legal-holiday', false);
    });
    // 法定休日をセット
    $('#scheduled_holiday-setting-modal-calendars [data-week=' + legalHoliday + ']').each(function() {
        $(this).find('.selectable-date-input').prop('checked', true);
        $(this).toggleClass('legal-holiday', true);
    });
    // 休日日数を更新
    scheduled_holiday_set_holiday_count();
}
/**
 * 所定休日設定時にカレンダーに休日をセットする
 * @param {*} elm 
 */
window.scheduled_holiday_set_prescribed_holidays = function(elm = null) {
    // 選択対象が存在している場合
    if (elm != null && !$(elm).prop('checked')) {
        $('#scheduled_holiday-setting-modal-calendars [data-week=' + $(elm).val() + ']').each(function() {
            if ($('#scheduled_holiday-setting-modal-include_national_holiday').prop('checked') && $(this).hasClass('national-holiday')) {
                return;
            }
            $(this).find('.selectable-date-input').prop('checked', false);
            $(this).toggleClass('prescribed-holiday', false);
        });
    }
    // 未指定時か選択状態に変更する場合
    $('input[name="prescribed_holidays[]"]:checked').each(function() {
        let prescribedHoliday = $(this).val();
        // 所定休日をセット
        $('#scheduled_holiday-setting-modal-calendars [data-week=' + prescribedHoliday + ']').each(function() {
            $(this).find('.selectable-date-input').prop('checked', true);
            $(this).toggleClass('prescribed-holiday', true);
        });
    })
    // 休日日数を更新
    scheduled_holiday_set_holiday_count();
}
/**
 * 祝日を所定休日として一括設定する
 */
window.scheduled_holiday_set_national_holiday_to_prescribed_holidays = function() {
    let checked = $('#scheduled_holiday-setting-modal-include_national_holiday').prop('checked');
    $('#scheduled_holiday-setting-modal-calendars .national-holiday .selectable-date-input').prop('checked', checked);
    $('#scheduled_holiday-setting-modal-calendars .national-holiday').toggleClass('prescribed-holiday', checked);
    // 未指定時か選択状態に変更する場合
    $('input[name="prescribed_holidays[]"]:checked').each(function() {
        let prescribedHoliday = $(this).val();
        // 所定休日をセット
        $('#scheduled_holiday-setting-modal-calendars [data-week=' + prescribedHoliday + ']').each(function() {
            $(this).find('.selectable-date-input').prop('checked', true);
            $(this).toggleClass('prescribed-holiday', true);
        });
    })
    // 休日日数を更新
    scheduled_holiday_set_holiday_count();
}
/**
 * 日別で選択した際に休日をセットする
 * @param {*} elm 
 */
window.scheduled_holiday_set_change_date = function(elm) {
    // 選択状態を判定し状態を変更
    let checked = !$(elm).find('.selectable-date-input').prop('checked');
    $(elm).find('.selectable-date-input').prop('checked', checked);
    $(elm).toggleClass('prescribed-holiday', checked);
    // 祝日の選択状態を判定し一括設定の選択状態をセットする
    let nationalHolidayChecked = !($('#scheduled_holiday-setting-modal-calendars .national-holiday:not(.prescribed-holiday,.legal-holiday)').length > 0);
    $('#scheduled_holiday-setting-modal-include_national_holiday').prop('checked', nationalHolidayChecked);
    // 同週の選択状態を判定し所定休日の選択状態をセットする
    let weekNum = $(elm).data('week');
    let weekChecked = !($('#scheduled_holiday-setting-modal-calendars [data-week=' + weekNum + ']:not(.prescribed-holiday)').length > 0);
    $('#scheduled_holiday-setting-modal-prescribed_holidays-value-' + weekNum).prop('checked', weekChecked);
    // 休日日数を更新
    scheduled_holiday_set_holiday_count();
}
/**
 * 年間休日日数をセットする
 */
window.scheduled_holiday_set_holiday_count = function() {
    let count = $('#scheduled_holiday-setting-modal-calendars .legal-holiday').length + $('#scheduled_holiday-setting-modal-calendars .prescribed-holiday').length;
    $('#scheduled_holiday-setting-modal-count').val(count + $('#scheduled_holiday-setting-modal-count').data('suffix'));
}