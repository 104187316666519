/**
 * 診断ページ用のスクリプト
 */
if (is_route('labor.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        labor_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.labor_event_set = function() {

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['user_id', 'month'], true);
    });

    /*---- 通知表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-notice-modal]', function () {
        _modal.create(this, 'notice-modal', ['user_id', 'type', 'date'], true);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['holding_id', 'company_id'], true);
    });

    /*---- 改善基準設定保存時 ----*/

    // 労務設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal');
    });

    /*---- チャート表示時 ----*/

    // チャート表示時
    $(document).on('click', '[data-chart-modal]', function () {
        // モーダルのID取得とキャンバスIDを生成
        var modalId = $(this).data('chart-modal');
        var canvasId = $(this).data('canvas-id');
        var action = $(this).data('action');
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name' : 'user_id', 'value' : $(this).data('user_id')});
        // チャートの表示タイプを判定
        if ($(this).data('mode') == 'month') {
            // 月間チャート表示
            labor_monthly_chart_event_set(action, modalId, canvasId, datas);
        } else {
            // 月間チャート表示
            labor_yearly_chart_event_set(action, modalId, canvasId, datas);
        }
    });
}
/**
 * 年間チャート表示を行う
 * @param {*} action 
 * @param {*} modalId 
 * @param {*} canvasId 
 * @param {*} datas 
 */
window.labor_yearly_chart_event_set = function(action, modalId, canvasId, datas) {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    // 送信
    _ajax.post(action, datas, function (results) {
        
        $(create_id(modalId) + ' .modal-title').text(results.title);
        $(create_id(modalId) + ' .period').html(results.period);
        $(create_id(modalId) + ' .user-code').html(results.userCode);
        $(create_id(modalId) + ' .user-name').html(results.userName);

        var limitLine = results.limitLine;
        var yAxesUnit = results.yAxesUnit;

        chart(canvasId, 'bar', {
            labels: results.labels,
            datasets: [
            {
                data: results.datasets.rec_times.datas,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.5)',
                yAxisID: "monthly-y-axis",
                stack: 'stack-1',
            },
            {
                type: "line",
                fill: false,
                data: results.datasets.total_rec_times.datas,
                lineTension: 0,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: "total-y-axis",
            },
            ],
        }, {
            legend: {
                display: false, 
                position: "bottom", 
                onClick: function () { return false },
                labels: {
                    fontSize: ticksFontSize
                }
            }, 
            tooltips: {
                displayColors: false,
                mode: 'nearest',
                //intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return results.tooltips[tooltipItem[0].index].title;
                    },
                    label: function (tooltipItem, data){
                        return results.tooltips[tooltipItem.index].messages;
                    }
                }, 
            },
            responsive: true,
            scales: {
                yAxes: [{
                    id: "monthly-y-axis",
                    type: "linear",
                    position: "left",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.rec_times.ticks[results.datasets.rec_times.ticks.length - 1],
                        max: results.datasets.rec_times.ticks[0], 
                        fontSize: ticksFontSize, 
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.rec_times.ticks;
                        return;
                    }
                }, {
                    id: "total-y-axis",
                    type: "linear",
                    position: "right",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.total_rec_times.ticks[results.datasets.total_rec_times.ticks.length - 1],
                        max: results.datasets.total_rec_times.ticks[0], 
                        fontSize: ticksFontSize, 
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.total_rec_times.ticks;
                        return;
                    }, 
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize
                    },
                }],
            },
            //基準線を引く
            annotation: { 
                annotations: [{ 
                    type: 'line', 
                    mode: 'horizontal',
                    scaleID: 'total-y-axis', 
                    value: limitLine,
                    borderWidth: 2,
                    borderColor: 'red',
                    label: {
                        position: 'right',
                        yAdjust:10,
                        content: limitLine,
                        enabled: true
                    }
                }] 
            },
        });
        // モーダルを表示
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}
/**
 * 月間チャート表示を行う
 * @param {*} action 
 * @param {*} modalId 
 * @param {*} canvasId 
 * @param {*} datas 
 */
window.labor_monthly_chart_event_set = function(action, modalId, canvasId, datas) {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    // 送信
    _ajax.post(action, datas, function (results) {
        
        $(create_id(modalId) + ' .modal-title').text(results.title);
        $(create_id(modalId) + ' .period').html(results.period);
        $(create_id(modalId) + ' .user-code').html(results.userCode);
        $(create_id(modalId) + ' .user-name').html(results.userName);

        var limitLine = results.limitLine;
        var yAxesUnit = results.yAxesUnit;

        chart(canvasId, 'bar', {
            labels: results.labels,
            datasets: [
            {
                data: results.datasets.run_times.datas,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: "one-day-y-axis",
                stack: 'stack-1',
            },
            {
                data: results.datasets.rec_times.datas,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.5)',
                yAxisID: "one-day-y-axis",
                stack: 'stack-1',
            },
            {
                type: "line",
                fill: false,
                data: results.datasets.total_rec_times.datas,
                lineTension: 0,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: "total-y-axis",
            },
            ],
        }, {
            legend: {
                display: false, 
                position: "bottom", 
                onClick: function () { return false },
                labels: {
                    fontSize: ticksFontSize
                }
            }, 
            tooltips: {
                displayColors: false,
                mode: 'nearest',
                //intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return results.tooltips[tooltipItem[0].index].title;
                    },
                    label: function (tooltipItem, data){
                        return results.tooltips[tooltipItem.index].messages;
                    }
                }, 
            },
            responsive: true,
            scales: {
                yAxes: [{
                    id: "one-day-y-axis",
                    type: "linear",
                    position: "left",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.run_times.ticks[results.datasets.run_times.ticks.length - 1],
                        max: results.datasets.run_times.ticks[0], 
                        fontSize: ticksFontSize, 
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.run_times.ticks;
                        return;
                    }, 
                }, {
                    id: "total-y-axis",
                    type: "linear",
                    position: "right",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.total_rec_times.ticks[results.datasets.total_rec_times.ticks.length - 1],
                        max: results.datasets.total_rec_times.ticks[0], 
                        fontSize: ticksFontSize, 
                        callback: function(tick) {
                            return tick.toString() + yAxesUnit;
                        }
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.total_rec_times.ticks;
                        return;
                    }, 
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize
                    },
                }],
            },
            //基準線を引く
            annotation: { 
                annotations: [{ 
                    type: 'line', 
                    mode: 'horizontal',
                    scaleID: 'total-y-axis', 
                    value: limitLine,
                    borderWidth: 2,
                    borderColor: 'red',
                    label: {
                        position: 'right',
                        yAdjust:10,
                        content: limitLine,
                        enabled: true
                    }
                }] 
            },
        });
        // モーダルを表示
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}