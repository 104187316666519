/**
 * ニュースリリース編集ページ用のスクリプト
 */
if (is_route('news_release.edit')) {

    var modalId;
    /* ---- ニュースリリース設定モーダル用処理 ---- */
    var news_release = {
        fileSizeLimit: 1024 * 1024 * 2,
        fileErrorMsg: '',
        url: {},
        file: {},
        fileName: {},
        fileNameDisp: {},
        fileError: {},
        initialize: function() {

            /* ---- 初期表示設定 ---- */

            news_release.url = $(modalId + ' input[name="url"]');
            news_release.file = $(modalId + ' input[name="file_path_file"]');
            news_release.fileName = $(modalId + ' input[name="file_path"]');
            news_release.fileNameDisp = $(modalId + ' span.filename-file_path');
            news_release.fileError = $(modalId + '-file_path-errors');
            news_release.fileErrorMsg = $(modalId + ' #file-size-error').html();

            // ファイル選択時のサイズチェック
            $(modalId + ' .modal-content').on('change', 'input[name="file_path_file"]', function() {
                news_release.fileError.find('.invalid-feedback.size').remove();
                // ファイル指定なし
                if ($(this).prop('files').length === 0) {
                    return;
                }

                let file = $(this).prop('files')[0];
                if (file.size > news_release.fileSizeLimit) {
                    // ファイルサイズが制限以上
                    var clone = news_release.fileError.find('.invalid-feedback.copy').clone().html(news_release.fileErrorMsg).removeClass('copy').addClass('size');
                    news_release.fileError.append(clone);
                    $(this).val(''); // 中身をリセット
                }
            });
        },
    }

    $(function () {

        /*---- 設定の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-setting-modal]', function () {
            modalId = create_id($(this).data('setting-modal'));
            _modal.create(this, 'setting-modal', ['id'], null, function(){
                news_release.initialize();
            });
        });

        /*---- 設定の保存時 ----*/

        // 設定実行時
        $(document).on('click', '[data-save-modal]', function () {
            _modal.submit(this, 'save-modal', function (){
                // TODO: Phase2側の「submit後、listリロードしないパターン改修」に統合してから、画面リロードをイキにする
                // 管理者のみの画面動作の為優先度↓
                // location.reload();
            });
        });

        /*---- 削除確認の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-confirm-modal]', function () {
            _modal.create(this, 'confirm-modal', ['id']);
        });

        /*---- 削除時 ----*/

        // 削除実行時
        $(document).on('click', '[data-delete-modal]', function () {
            _modal.submit(this, 'delete-modal', function (){
                // TODO: Phase2側の「submit後listリロードしないパターン改修」に統合してから、画面リロードをイキにする
                // 管理者のみの画面動作の為優先度↓
                // location.reload();
            });
        });
    });
}
