/**
 * 診断ページ用のスクリプト
 */
if (is_route('allocation.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        allocation_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.allocation_event_set = function()
{
    /*---- チャート表示処理 ----*/
    var myChart = {};

    // チャート表示
    $('.allocation-chart').each(function() {
        // モーダルのID取得とキャンバスIDを生成
        let canvasId = $(this).data('canvas-id');
        let action = $(this).data('action');
        // 検索フォームを配列化
        let data = $('#search-form').serializeArray();
        // チャート表示
        _ajax.post(action, data, function (results) {
            myChart[canvasId] = chart(canvasId, 'horizontalBar', results, {
                responsive: true,
                legend: {
                    display: false,
                }
            });
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
