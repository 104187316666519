/**
 * 点検整備区分設定ページ用のスクリプト
 */
 if (is_route('inspection_type.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        inspection_type_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.inspection_type_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        //非表示部品の値をクリア
        let cycleType = $('#inspection_type-setting-modal-cycle_type option:selected').val();
        if(cycleType != '2') {
            $('.only-type-2 .active').removeClass("active");
        }
        if(cycleType != '1') {
            $('.only-type-1 input').val('');
            $('.only-type-1 [selected]').removeAttr("selected");
        }
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    /*---- サイクル設定変更時 ----*/
    // トグルボタン押下時
    $(document).on('change', '#inspection_type-setting-modal-cycle_type', function () {
        let type = $(this).val();
        let formId = create_id($(this).parents('form').attr('id'));
        // 間隔月設定の場合
        $(formId).find('.only-type').each(function() {
            $(this).addClass('display-none');
        });
        if (type == '1') {
            $(formId).find('.only-type-1').each(function() {
                $(this).removeClass('display-none');
            });
        } else if(type == '2') {
            $(formId).find('.only-type-2').each(function() {
                $(this).removeClass('display-none');
            });
        }
    });
}
