/**
 * 検索フォームの変更を検知し検索を行う
 */
$(function () {
    // フォーム外へのチェンジイベントのバブリングを禁止
    $(document).on('change', 'form', function(e) {
        e.stopPropagation();
    });
    // 検索フォームの変更時
    $(document).on('change', '#search-form, #toolbar-keyword-search', function() {
        // コンテンツを読込
        load_contents(null, false);
    });
    // キーワード検索の実行ボタンクリック時
    $(document).on('click', '#toolbar-keyword-search-submit, #toolbar-keyword-search-reset', function() {
        // コンテンツを読込
        load_contents(null, false);
    });
    // 検索フォーム内のチェックボックスの変更時
    $(document).on('change', '#search-form input[type=checkbox]', function() {
        // 配列系以外のチェックボックスの場合かつ選択解除された場合
        if (!$(this).prop('checked') &&
            !$(this).attr('name').includes('[') &&
            !$(this).attr('name').includes(']')) {
                _request.remove_parameters(location.search, [$(this).attr('name')]);
        }
    });
    // 表示設定の表示時
    $(document).on('click', '[data-display-setting-modal]', function(e) {
        // 設定モーダルを表示
        _modal.create(this, 'display-setting-modal', ['route', 'path'], true);
    });
    // 表示設定の適用時
    $(document).on('click', '[data-display-save-modal]', function(e) {
        _modal.submit(this, 'display-save-modal', null, null, '-form', false, function(){
            _request.set_parameters([{'name' : 'page', 'value' : '1'}]);
        });
    });
    // 表示設定の初期化時
    $(document).on('click', '[data-display-delete-modal]', function(e) {
        _modal.submit(this, 'display-delete-modal', null, null, '-form', false, function(){
            _request.set_parameters([{'name' : 'page', 'value' : '1'}]);
        });
    });
    // 表示設定の適用時
    $(document).on('click', '.sort-column', function(e) {
        let datas = [];
        datas.push({'name' : 'route', 'value' : $(this).data('route')});
        datas.push({'name' : 'order', 'value' : $(this).data('order')});
        datas.push({'name' : 'sort', 'value' : $(this).data('sort')});
        _ajax.post($(this).data('action'), datas, function(results) {
            load_contents();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
});
