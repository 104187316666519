/**
 * 診断ページ用のスクリプト
 */
if (is_route('diagnosis.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        diagnosis_event_set();
        diagnosis_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.diagnosis_event_set = function() {

    /*---- ページタブ切り替え時 ----*/

    // タブ切り替え時
    $(document).on('click', '[data-page-type]', function() {
        $('#search-form-type').val($(this).data('page-type'));
        $('#search-form-type').trigger('change');
    });

    /*---- カレンダーへのドロップ時 ----*/

    // ドロップ時
    $(document).on('drop', '.calendar-date', function() {
        var dropId = create_id(event.dataTransfer.getData('id'));
        $(dropId).data('date', $(this).data('date'));
        _modal.create($(dropId), 'setting-modal', ['id', 'type', 'date']);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'type'], true);
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#diagnosis-setting-modal');
        });
    });

}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.diagnosis_setting_event_set = function() {

    /*---- 受信区分の表示切り替え ----*/

    // 診断区分変更時
    $(document).on('change', 'select[name=diag_type]', function () {
        $('[data-diag-type]').toggleClass('display-none', true);
        $('[data-diag-type=' + $(this).val() + ']').toggleClass('display-none', false);
    });

}