
import Cookies from 'js-cookie';
import {Howl} from 'howler';

export class RemoteRollcallViewer {

    msec = 1000;
    conditionCallback = null;
    status = false;
    timer = null;
    sound = null;

    constructor(msec, conditionCallback = null, remoteRollcallStandbyId = 'remote-rollcall-standby', itRollcallStandbyId = 'it-rollcall-standby') {
        this.msec = msec;
        this.conditionCallback = conditionCallback;
        this.status = $(create_id(remoteRollcallStandbyId)).prop('checked') || $(create_id(itRollcallStandbyId)).prop('checked');
        this.reset();
        this.set();
    }

    reset() {
        Cookies.set('remote_rollcall_displayed', '');
    }

    set() {
        if (this.status) {
            this.timer = setInterval(function(obj) {
                obj.waitUpdate(obj);
            }, this.msec, this);
        } else {
            (this.timer != null) && clearInterval(this.timer);
        }
    }

    waitUpdate(obj = null) {
        obj = (obj == null) ? this : obj;
        if (obj.conditionCallback == null || !obj.conditionCallback()) {
            return;
        }
        let action = $('#remote-rollcall-navbar-menu').data('action');
        _ajax.background(action, {}, function(results, obj) {
            $('#remote-rollcall-notification-count').text(results.models.length);
            $('#remote-rollcall-notification-count').toggleClass('display-none', !(results.models.length > 0));
            // 閉じた点呼のID
            let closed = ((Cookies.get('remote_rollcall_closed') === undefined) ? '': Cookies.get('remote_rollcall_closed')).split(',');
            // 表示ずみの点呼ID
            let displayed = ((Cookies.get('remote_rollcall_displayed') === undefined) ? '': Cookies.get('remote_rollcall_displayed')).split(',');
            // 再読み込みフラグ
            let reload = false;
            // 待機項目が存在しない場合は通知を停止
            if (results.models.length == 0) {
                obj.stop();
            } else {
                // 待機項目分繰り返し
                $.each(results.models, function(index, value) {
                    // 表示を継続するか
                    let isExtened = false;
                    // 確認用ID
                    const confirm = String(value.id) + '-' + value.created_at;
                    // 表示済み項目の場合
                    if (displayed.indexOf(confirm) >= 0) {
                        isExtened = true;
                    }
                    // 継続しない場合
                    if (!isExtened) {
                        // 削除
                        $('#remote-rollcall-toastr-item_' + confirm).closest('.toast').remove();
                        // ステータスの更新のため一覧を更新する
                        reload = true;
                    }
                    // 表示した・閉じた項目に含まれる場合は何もしない
                    if (closed.indexOf(confirm) >= 0 || displayed.indexOf(confirm) >= 0) {
                        return;
                    }
                    // 表示されていない項目があるため更新
                    reload = true;
                    // トースト判定用の隠し値
                    let hiddenItem = '<span id="remote-rollcall-toastr-item_' + confirm + '" class="remote-rollcall-toastr-item display:none;" data-id="' + value.id + '">';
                    // 表示済み項目に追加
                    displayed.push(confirm);
                    Cookies.set('remote_rollcall_displayed', displayed.join(','));
                    // 一覧を更新する
                    reload = true;
                    toastr.info(value.notice + hiddenItem, '', {
                        'timeOut': 0,
                        'extendedTimeOut': 0,
                        'closeButton': true,
                        "positionClass": "toast-top-full-width",
                        'onclick': function() {
                            if (value.method_type == $('#remote-rollcall-stanby-actions').data('it_type')) {
                                RemoteRollcallViewer.authorizerSelect(value.meeting_id);
                            } else {
                                $('#remote_rollcall-recognition-modal').data('meeting_id', value.meeting_id);
                                $('#remote_rollcall-recognition-modal').modal('show');
                            }
                        },
                        'onCloseClick': function() {
                            closed.push(confirm);
                            Cookies.set('remote_rollcall_closed', closed.join(','));
                        }
                    });

                });
            }
            // 表示項目の状態に応じて呼び出し音を開始・停止
            if ($('.remote-rollcall-toastr-item').length > 0) {
                obj.play();
            } else {
                obj.stop();
            }
            // 更新が必要な場合は更新
            if ((is_route('remote_rollcall.index') || is_route('it_rollcall.index')) && reload) {
                obj.listUpdate();
            }
        }, null, 'json', obj);

    }

    listUpdate() {
        let action = '';
        let datas = [];
        if (!$('#search-form input[name=' + $('#toolbar-keyword-search').attr('name') + ']').length) {
            $('#search-form').append($('<input></input>', {
                type: 'hidden',
                name: $('#toolbar-keyword-search').attr('name'),
                value: $('#toolbar-keyword-search').val(),
            }));
        }
        $('#search-form input[name=' + $('#toolbar-keyword-search').attr('name') + ']').val($('#toolbar-keyword-search').val());
        // 検索フォームの値をURLにセットする
        _request.set_parameters($('#search-form').serializeArray());
        // 現在のURLのGetパラメーターを引き継ぐ
        action = $('#search-form').attr('action');
        action = extend_url_query(action);
        $('#search-form').attr('action', action);
        datas = $('#search-form').serializeArray();
        datas.push({
            'name': 'load_type',
            'value': 'json',
        });
        // 表示済みの項目のIDを取得
        var ids = [];
        $('#remote_rollcall-list-area tbody tr').each(function(i, elm) {
            ids.push($(elm).attr('id'));
        });
        _ajax.background(action, datas, function (results) {
            let hasItem = (results.models.length != 0);
            // 表示エリアの切り替え
            $('#remote_rollcall-list-area').toggleClass('display-none', !hasItem);
            $('#remote_rollcall-not-found').toggleClass('display-none', hasItem);
            // ページネーターの更新
            $('#remote_rollcall-list-area .paginator').html(results.paginator);
            $.each(results.models.reverse(), function(index, value) {
                // 既存の項目は置き換え/新規は最終に追加
                if ($(create_id(value['id'])).length) {
                    $(create_id(value['id'])).replaceWith(value['view']);
                } else {
                    $('#remote_rollcall-item-list').prepend(value['view']);
                }
                // 表示したIDを削除
                ids = ids.filter(function(id) {
                    return id != value['id'];
                });
            });
            // 余ったものは削除
            $.each(ids, function(index, id) {
                $(create_id(id)).remove();
            });
        }, function(xhr, status, errorThrown){
            _error.set('#search-form', xhr, status, errorThrown);
        });

    }

    static authorizerSelect(meetingId) {
        const modalId = create_id('it_rollcall-authorizer-modal');
        const datas = {'meeting_id': meetingId};
        const action = $('#remote-rollcall-stanby-actions').data('authorizer-action');
        // モーダルのコンテンツを空に
        $(modalId + ' .modal-content').empty();
        // 送信
        _ajax.post(action, datas, function (results) {
            $(modalId + ' .modal-content').html(results);
            initialize();
            _modal.show(modalId);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    }

    static initializeDetailArea() {
        let drawer = {

            /*---- properties ----*/
            _popoverEl: '',  // popover要素
    
            /*---- 初期化 ----*/
            init: function() {
                // OPEN
                $(document).on('click', '#remote_rollcall-join-modal #drawer-open', function () {
                    drawer.toggle($('#remote_rollcall-join-modal #drawer-content').is(':hidden'));
                });
                // CLOSE
                $(document).on('click', '#remote_rollcall-join-modal #drawer-close', function () {
                    drawer.toggle($('#remote_rollcall-join-modal #drawer-content').is(':hidden'));
                });
                drawer.popover();
            },
    
            /*---- 表示制御 ----*/
            toggle: function(open) {
                let content = $('#remote_rollcall-join-modal #drawer-content');
                // escape when not selector
                if (content.length === 0) {
                    return;
                }
                let parent = content.parent('#remote_rollcall-join-modal #drawer-toggle');
                let joinBlock = $('#join-block');
                if (open) {
                    // open
                    $('#remote_rollcall-join-modal #drawer-open').addClass('d-none');
                    content.removeClass('d-none');
                    // parent.removeClass('col-auto').addClass('col-12 col-lg-2 col-md-4 col-sm-5').css({width: "unset"});
                    // joinBlock.addClass('col-12 col-lg-10 col-md-8 col-sm-7').removeClass('col-auto').css({width:"unset"});
                    parent.removeClass('col-auto').addClass('col-5').css({width: "unset"});
                    joinBlock.addClass('col-7').removeClass('col-auto').css({width:"unset"});
                } else {
                    // close
                    $('#remote_rollcall-join-modal #drawer-open').removeClass('d-none');
                    content.addClass('d-none');
                    // parent.addClass('col-auto').removeClass('col-12 col-lg-2 col-md-4 col-sm-5').css({width: "5%"});
                    // joinBlock.addClass('col-auto').removeClass('col-12 col-lg-10 col-md-8 col-sm-7').css({width:"95%"});
                    parent.addClass('col-auto').removeClass('col-5').css({width: "5%"});
                    joinBlock.addClass('col-auto').removeClass('col-7').css({width:"95%"});
                }
                drawer.popover();
                // timeline._calendar.render();
            },
    
            /*---- popover制御 ----*/
            popover: function() {
                drawer._popoverEl = $('.external-popover');
                if (drawer._popoverEl.length > 0) {
                    drawer._popoverEl.popover('dispose');
                    drawer._popoverEl.popover({
                        trigger: 'hover',
                        placement: 'top',
                        container: 'body',
                        html: true
                    });
                }
            },
        }
        // load
        drawer.init();
    }

    play() {
        if (this.sound == null) {
            this.sound = new Howl({
                src: '/assets/audio/call.mp3',
                loop: true,
            });
            this.sound.play();
        }
    }

    stop() {
        // toastを非表示にする
        $('.remote-rollcall-toastr-item').each(function() {
            $(this).closest('.toast').remove();
        });
        // サウンドを停止
        if (this.sound != null) {
            this.sound.stop();
            this.sound = null;
        }
    }
}