/**
 * 会社ページ用のスクリプト
 */
if (is_route('shipping_information.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        shipping_information_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
 window.shipping_information_event_set = function() {

    $(document).ajaxStop(function() {
        var ship_date_flag = $('input:radio[name="ship_date_flag"]:checked').val();
        var return_date_flag = $('input:radio[name="return_date_flag"]:checked').val();

        if (ship_date_flag == '1') {
            $('div.search_ship_date').toggleClass('visibility-hidden');
        }else if (ship_date_flag == '0') {
            $('div.search_ship_date').addClass('visibility-hidden');
        }

        if (return_date_flag == '1') {
            $('div.search_return_date').toggleClass('visibility-hidden');
        }else if (return_date_flag == '0') {
            $('div.search_return_date').addClass('visibility-hidden');
        }
    });

    function setDisplayOfSearchDate(elm) {
        if ($(elm).val() == '1') {
            $("div." + $(elm).data('target')).toggleClass('visibility-hidden');
        }else if ($(elm).val() == '0'){
            $("div." + $(elm).data('target')).addClass('visibility-hidden');
        }
    }
    // switch
    $(document).on('change', '.ship_date_flag', function() {
        setDisplayOfSearchDate(this);
        return false;
    });

    $(document).on('change', '.return_date_flag', function() {
        setDisplayOfSearchDate(this);
        return false;
    });

    /*---- 設定の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定実行時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'holding')) {
                $('[data-replace-name-holding-id=' + results.holding.id + ']').text(results.holding.name);
            }
            if (keys.find(key => key == 'company')) {
                $('[data-replace-name-company-id=' + results.company.id + ']').text(results.company.name);
            }
        });
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        });
    });

    /*---- 検索ボタン押下時 ----*/

    // 検索時
    $(document).on('click', '#search-form-action_search', function () {
        // コンテンツを読込
        load_contents(null, false);
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });
}
