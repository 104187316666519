/**
 * オーバーレイのイベントに応じた自動表示や、クリック時などバブリングの設定を行う。
 */
$(function () {
   $(document).ajaxStart(function() {
      _overlay.show();
   });
   $(document).ajaxStop(function() {
      _overlay.hide();
   });
   $(document).on('click', '#overlay', function(e) {
      e.stopPropagation();
   });
});