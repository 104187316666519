/**
 * カスタムボタンの共通処理
 */
$(function () {
    /* ボタン式チェックボックス */
    $(document).on('click', '.check-button input', function() {
        let label = $(this).parent();
        $(label).toggleClass('active', $(this).prop('checked'));
    });
    // ボタン式チェックボックス：単一選択
    $(document).on('click', '.single-check input', function(e) {
        let checked = this;
        let name = $(this).attr('name');
        if ($(checked).prop('checked')){
            $('.single-check input[name="'+name+'"]').each(function(){
                if($(this).val() == $(checked).val()){
                    return true;
                }
                $(this).prop('checked', false);
                let label = $(this).parent();
                $(label).toggleClass('active', false);
            });
        }
    });
    /* ニュースリリースバナー閉じるボタン */
    $(document).on('click', '.news-release-message .close', function() {
        let id = $(this).data('id');
        let datas = [];
        datas.push({'name' : 'id', 'value' : id});
        _ajax.post($(this).data('action'), datas, function(response) {
            $('#news_release_message_'+id).addClass('display-none');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
});