/**
 * 違反歴ページ用のスクリプト
 */
if (is_route('violation.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        violation_event_set();
        violation_setting_event_set();
    });
}

/**
 * 道路種別・酒気帯び関連の表示制御
 * @uses 社員台帳・車両台帳からも呼び出されるためglobal定義
 */
window.violation = {
    isInfluence: false,
    // 設定画面表示初期化
    init: function() {
        violation.setKind($('#violation-setting-modal select[name="road_type"]').val());
        $('#violation-setting-modal').find('select[name^="violation_point_id"]').each(function(){
            violation.showDrinkingType($(this));
        })
    },
    // 道路による違反種別切替制御
    setKind: function(roadType) {
        if (roadType === '') return false;
        // 違う道路タイプの違反は表示しない
        $('#violation-setting-modal').find('select[name^="violation_point_id"]').each(function() {
            $(this).find('option').each(function(){
                if ($(this).parents().hasClass('wrap')) {
                    $(this).unwrap();
                }
                let optionType = $(this).data('road_type') || 0;
                // 道路にマッチしない違反種別は非表示
                if (roadType !== 0 && optionType !== 0 && Number(optionType) !== Number(roadType)) {
                    $(this).prop('selected', false)
                    if (!$(this).parents().hasClass('wrap')) {
                        $(this).wrap('<span class="wrap">');
                    }
                }
            });
        })
        initialize();
    },
    // 酒気帯び入力項目表示制御
    showDrinkingType: function(elm) {
        let card = elm.parents('.card');
        let drinkingType = card.find('select[name^="drinking_type"]');

        if (elm.val() === '2') {
            drinkingType.removeClass('d-none').parents('.form-group').find('.badge-required').show();
            drinkingType.prev('label').removeClass('d-none');
            drinkingType.prop('disabled', false);
        } else {
            violation.setDrinkingType($('#violation-setting-modal select[name^="drinking_type"]').not(drinkingType));
            if (violation.isInfluence === false) {
                drinkingType.val('');
            }
            drinkingType.addClass('d-none').parents('.form-group').find('.badge-required').hide();
            drinkingType.prev('label').addClass('d-none');
        }
    },
    // 違反種別に酒気帯びが存在しているか
    checkInfluence: function() {
        violation.isInfluence = false;
        $('#violation-setting-modal').find('select[name^="violation_point_id"]').each(function() {
            let checkDelete = $(this).parents('.card').find('input[name^="violation_kind_delete"]:checked');
            if ($(this).val() === '2' && checkDelete.length <= 0) {
                violation.isInfluence = $(this).parents('.card').find('select[name^="drinking_type"]').val();
                $(this).prop('disabled', false);
            }
        });
    },
    // 違反種別すべてに酒気帯び設定を反映 & 点数セット
    setDrinkingType: function(drinkingType) {
        violation.checkInfluence();
        // 選択解除
        $(drinkingType).each(function() {
            let hidden = $('input[name="' + $(this).attr('name') + '"');
            // 設定反映
            if (violation.isInfluence !== false) {
                $(this).val(violation.isInfluence);
                hidden.val(violation.isInfluence);
            } else {
                $(this).val('');
                hidden.val('');
            }
        });
        violation.setPoint();
    },
    // 違反点数セット
    setPoint: function(checkOn = false) {
        if (checkOn) {
            violation.checkInfluence();
        }
        let point = 0;
        let selected;
        $('#violation-setting-modal').find('select[name^="violation_point_id"]').each(function() {
            let pointElm = $(this).parents('.card').find('p.violation-point');
            pointElm.html('');
            if ($(this).val() !== '') {
                selected = $(this).find('option[value="' + $(this).val() + '"]');
                if (violation.isInfluence === '1' && selected.data('under_point') > 0) {
                    point = selected.data('under_point');
                } else if (violation.isInfluence === '2' && selected.data('more_point') > 0) {
                    point = selected.data('more_point');
                } else {
                    point = selected.data('point');
                }
                if (point !== '') {
                    point = point + pointElm.data('unit')
                }
                pointElm.html(point);
            }
        });
    }
}

/**
 * ページ読み込み時のイベント設定
 */
window.violation_event_set = function () {

    /*---- ページタブ切り替え時 ----*/

    // タブ切り替え時
    $(document).on('click', '[data-page-type]', function() {
        $('#search-form-type').val($(this).data('page-type')).trigger('change');
    });

    /*---- 違反歴詳細表示時 ----*/

    // 違反歴詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date'], true);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        _modal.create(this, 'setting-modal', ['id', 'date', 'user_id', 'vehicle_id'], true,function(){
            /*---- 初期化 ----*/
            violation.init();
        });
    });

    /*---- 設定時 ----*/

    // 違反歴設定時
    $(document).on('click', '[data-save-modal]', function () {
        var date = $('#violation-setting-modal-date').val();
        // 全要素をPOSTするためdisabled解除
        _modal.submit(this, 'save-modal', function(results) {
            // detailモーダルから変更した時
            if (date != null && date.length > 0) {
                if (results.violation.occurred_at) {
                    $('[data-detail-modal=violation-detail-modal][data-date=' + moment(results.violation.occurred_at).format("YYYY-MM-DD") + ']').trigger('click');
                } else {
                    $('[data-detail-modal=violation-detail-modal][data-date=' + date + ']').trigger('click');
                }
            }
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        var date = $('#violation-setting-modal-date').val();
        _modal.submit(this, 'delete-modal', function(results) {
            // detailモーダルから変更した時のみ
            if (date != null && date.length > 0) {
                $('[data-detail-modal=violation-detail-modal][data-date=' + date + ']').trigger('click');
            }
        });
    });
    
    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

}
/**
 * 違反歴設定モーダル表示時のイベント設定
 * @uses 社員台帳・車両台帳からも呼び出される
 */
window.violation_setting_event_set = function () {

    /*---- 道路選択時の違反種別選択肢出し分け ----*/

    $(document).on('change', '#violation-setting-modal select[name="road_type"]', function () {
        let type = $(this).val() || 0;
        violation.setKind(type);
    });

    /*---- 違反種別追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', 'button[name="add_violation_kind"]', function () {
        let count = $('#violation-setting-modal .card').length;
        let card = $('#violation-setting-modal #card0').clone();
        card.attr('id', 'card' + count);

        // 新規フォームは背景グレー
        card.attr('id', 'card' + count).addClass('badge-light');
        // フォーム削除ボタンON
        card.find('a.violation_kind-close').removeClass('d-none');
        // 削除・酒気帯び項目あれば消す
        card.find('input[name="violation_kind_delete[0]"]').remove();
        card.find('#label_violation_kind_delete').remove();
        card.find('select[name^="drinking_type"]').addClass('d-none').prev('label').addClass('d-none');

        // 追加要素の初期化
        card.find('span.select2').remove();
        card.find('p.violation-point').html('');
        card.find('input,select').each(function () {
            $(this).val('').prop('selected', false).removeAttr('selected');

            let id = $(this).attr('id') || '';
            let name = $(this).attr('name') || '';

            let newId = id.replace(/_0/g, '_' + count);
            let newName = name.replace(/\[0\]/g, '[' + count + ']');
            let label = card.find('label[for="' + id + '"]');
            let error = card.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            $(this).attr('name', newName);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最初に追加
        card.insertBefore('#violation-setting-modal .card:first');
        // 酒気帯び初期化
        violation.setDrinkingType(card.find('select[name^="drinking_type"]'));

        // 2回呼び出ししないとselect2が反応しない
        initialize();
        initialize();
    });

    /*---- 違反種別フォーム削除 ----*/

    $(document).on('click', '#violation-setting-modal a.violation_kind-close', function () {
        let card = $(this).parents('.card');
        card.remove();
    });

    /*---- 違反種別が酒気帯びの時は酒気帯び入力項目を表示する ----*/

    $(document).on('change', '#violation-setting-modal select[name^="violation_point_id"]', function () {
        violation.showDrinkingType($(this));
    });

    /*---- 酒気帯び変更時は全て違反種別の酒気帯び設定値を同じにする ----*/

    $(document).on('change', '#violation-setting-modal select[name^="drinking_type"]', function () {
        $('input[name="' + $(this).attr('name') + '"]').val($(this).val());
        violation.setDrinkingType($('#violation-setting-modal select[name^="drinking_type"]').not(this));
    });

    /*---- 酒気帯び運転を削除チェック時、違反点数再取得 ----*/

    $(document).on('change', '#violation-setting-modal input[name^="violation_kind_delete"]', function () {
        let card = $(this).parents('.card');
        violation.setDrinkingType($('#violation-setting-modal select[name^="drinking_type"]').not(card.find('select[name^="drinking_type"]')));
        violation.setPoint(true);
        if ($(this).prop('checked')) {
            card.find('p.violation-point').html('');
        }
    });
}
