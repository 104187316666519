/**
 * 車両台帳ページ用のスクリプト
 */
if (is_route('vehicle.index')) {

    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        vehicle_event_set();
        vehicle_setting_event_set();
        accident_setting_event_set();
        violation_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.vehicle_event_set = function () {

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.certificate_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id', 'vehicle_id', 'inspection_id']);
    });

    // リース情報詳細表示時
    $(document).on('click', '[data-lease-detail-modal]', function () {
        _modal.create(this, 'lease-detail-modal', ['id', 'serial', 'page']);
    });

    // レンタル情報詳細表示時
    $(document).on('click', '[data-rental-detail-modal]', function () {
        _modal.create(this, 'rental-detail-modal', ['id', 'serial', 'page']);
    });

    // 給油詳細表示時
    $(document).on('click', '[data-refuel-detail-modal]', function () {
        _modal.create(this, 'refuel-detail-modal', [
            'holding_id', 'company_id', 'section_id', 'vehicle_id', 'refueled_from', 'refueled_to', 'page'
        ]);
    })

    // 車両設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['company_id', 'section_id'], true);
    })

    /*---- 給油詳細検索 ----*/

    // update modal
    var refuel = {
        search:function (elm) {
            let form = elm.parents('form');
            let data = $(form).serializeArray();
            let action = $(form).attr('action');
            let modalId = $(form).data('modal_id');
            $(modalId + ' .modal-content').empty();
            _modal.update(modalId, action, data);
        }
    };

    // グループ会社、会社、営業所、車両検索
    $(document).on('select2:selecting', '#vehicle-refuel-detail-modal-holding_id:enabled, #vehicle-refuel-detail-modal-company_id:enabled, #vehicle-refuel-detail-modal-section_id, #vehicle-refuel-detail-modal-vehicle_id', function(e) {
        $(this).val(e.params.args.data.id);
        if ($(this).attr('id') === 'vehicle-refuel-detail-modal-holding_id') {
            $('#vehicle-refuel-detail-modal-company_id').val('');
            $('#vehicle-refuel-detail-modal-section_id').val('');
            $('#vehicle-refuel-detail-modal-vehicle_id').val('');
        }
        if ($(this).attr('id') === 'vehicle-refuel-detail-modal-company_id') {
            $('#vehicle-refuel-detail-modal-section_id').val('');
            $('#vehicle-refuel-detail-modal-vehicle_id').val('');
        }
        if ($(this).attr('id') === 'vehicle-refuel-detail-modal-section_id') {
            $('#vehicle-refuel-detail-modal-vehicle_id').val('');
        }
        refuel.search($(this));
    });

    // 給油日検索
    $(document).on('change', '#vehicle-refuel-detail-modal-refueled_from, #vehicle-refuel-detail-modal-refueled_to', function(e) {
        refuel.search($(this));
    });

    /*---- 印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        datas.push({'name': 'id', 'value': $(this).data('id')});
        let action = $(this).data('print');
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-total-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('total-print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 選択印刷表示時 ----*/

    // 選択印刷
    $(document).on('click', '[data-select-print-modal]', function (e) {
        // バブリング停止
        e.stopPropagation();
        let modalId = create_id($(this).data('select-print-modal'));
        let form = $(modalId).find('form');
        let formId = '#' + form.attr('id');
        // 送信値を配列化
        let datas = form.serializeArray();
        console.log(datas);
        let action = extend_url_query(form.attr('action'));

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            _error.omitted(xhr, status, errorThrown);
        });
    });


    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        let modalId = $(this).data('setting-modal');
        let keys = ['id', 'type'];
        let isExtendQuery = false; // URLに現在のGETクエリを追加するか
        // 印刷設定画面
        if (modalId === 'vehicle-print-select-modal') {
            keys = ['holding_id', 'company_id', 'section_id'];
            isExtendQuery = true; //
        }
        _modal.create(this, 'setting-modal', keys, isExtendQuery, function() {
            if (modalId.indexOf('violation') !== -1) {
                /*---- 表示初期化 ----*/
                violation.init();
            }
        });
    });

    // // リース情報設定表示時
    // $(document).on('click', '[data-lease-setting-modal]', function () {
    //     _modal.create(this, 'lease-setting-modal', ['id', 'serial', 'lease_id']);
    // });

    // // レンタル情報設定表示時
    // $(document).on('click', '[data-rental-setting-modal]', function () {
    //     _modal.create(this, 'rental-setting-modal', ['id', 'serial', 'rental_id']);
    // });

    // 給油情報設定表示
    $(document).on('click', '[data-refuel-list-modal]', function () {
        _modal.create(this, 'refuel-list-modal', ['vehicle_id']);
    });

    // 給油情報設定表示
    $(document).on('click', '[data-refuel-setting-modal]', function (e) {
        if ($(e.target).hasClass('receipt-image')) return;
        if ($(e.target).hasClass('receipt-img')) return;
        _modal.create(this, 'refuel-setting-modal', ['refuel_id', 'not_current']);
    });

    // レシート画像表示
    $(document).on('click', '[data-refuel-image-modal]', function () {
        _modal.create(this, 'refuel-image-modal', ['refuel_id']);
    });

    // 移籍日変更表示（移籍日変更ボタン押下）
    $(document).on('click', '[data-use-date-modal]', function () {
        _modal.create(this, 'use-date-modal', ['id', 'original-vehicle-id']);
    });

    /*---- 履歴表示時 ----*/

    // 履歴表示時
    $(document).on('click', '[data-history-modal]', function () {
        _modal.create(this, 'history-modal', ['id', 'page', 'equipment_id', 'holding_id']);
        return false;
    });

    // リース情報履歴表示時
    $(document).on('click', '[data-lease-history-modal]', function () {
        _modal.create(this, 'lease-history-modal', ['id', 'serial', 'page']);
        return false;
    });

    // レンタル情報履歴表示時
    $(document).on('click', '[data-rental-history-modal]', function () {
        _modal.create(this, 'rental-history-modal', ['id', 'serial', 'page']);
        return false;
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        //資産番号ハイフン除去
        let asset_number = $('#vehicle-setting-modal-asset_number');
        if(asset_number.val() != null){
            asset_number.val(asset_number.val().replace('-',''));
        }

        let modalId = $(this).data('save-modal');
        _modal.submit(this, 'save-modal', function(results) {
            if (modalId.indexOf('accident') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.accident.vehicle_id + ']').trigger('click');
            } else if (modalId.indexOf('violation') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.violation.vehicle_id + ']').trigger('click');
            } else if (modalId.indexOf('daily_check') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.daily_check.vehicle_id + ']').trigger('click');
            } else {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.vehicle.id + ']').trigger('click');
            }
        }, function() {
            if (modalId.indexOf('accident') < 0 && modalId.indexOf('violation') < 0) {
                vehicle_set_steper_error();
            }
        });
    });

    // const updateSettingModalTable = (action, datas, updateElementId) => {
    //     // 送信
    //     _ajax.post(action, datas, function (results) {
    //         $('#' + updateElementId).html(results);
    //     }, function(xhr, status, errorThrown){
    //         _error.omitted(xhr, status, errorThrown);
    //     }, 'html');
    // }

    // // リース情報設定保存時
    // $(document).on('click', '[data-lease-save-modal]', function () {
    //     const updateAction = $(this).data('update-action');
    //     const updateData = {
    //         'id' : $(this).data('update-id'),
    //         'serial' : $(this).data('update-serial'),
    //     };
    //     _modal.submit(this, 'lease-save-modal', function(results) {
    //         updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-lease-table');
    //     }, function() {
    //         vehicle_set_steper_error();
    //     }, '-form', true);
    // });

    // // レンタル情報設定保存時
    // $(document).on('click', '[data-rental-save-modal]', function () {
    //     const updateAction = $(this).data('update-action');
    //     const updateData = {
    //         'id' : $(this).data('update-id'),
    //         'serial' : $(this).data('update-serial'),
    //     };
    //     _modal.submit(this, 'rental-save-modal', function(results) {
    //         updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-rental-table');
    //     }, function() {
    //         vehicle_set_steper_error();
    //     }, '-form', true);
    // });

    // 給油情報設定保存時
    $(document).on('click', '[data-refuel-save-modal]', function () {
        let refuel_callback = function(results) {
            $('[data-refuel-detail-modal=vehicle-refuel-detail-modal]').trigger('click');
        };
        if($('.vehicle-detail-refuel-button').is(":visible")) {
            refuel_callback = function(results) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.refuel.vehicle_id + ']').trigger('click');
            };
        }

        _modal.submit(this, 'refuel-save-modal', refuel_callback, function() {
            vehicle_set_steper_error();
        });
    });

    // 車両設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal', function(results) {
            $('[data-config-modal=vehicle-config-modal]').trigger('click');
        }, function() {
            vehicle_set_steper_error();
        });
    });

    /*---- 一括削除チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#vehicle-all-delete', function () {
        $(".vehicle-delete").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.vehicle-delete', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#vehicle-all-delete, .vehicle-delete', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#vehicle-multi-delete-button').prop('disabled', ($('.vehicle-delete:checked').length == 0));
        $('#vehicle-multi-delete-button').toggleClass('display-none', ($('.vehicle-delete:checked').length == 0));
    });

    /*---- 削除確認時 ----*/

    // 一括削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        var modalId = $(this).data('confirm-modal');
        if (modalId.indexOf('accident') !== -1 || modalId.indexOf('violation') !== -1 || modalId.indexOf('daily_check') !== -1 ) {
            _modal.create(this, 'confirm-modal', ['id']);
        } else {
            var ids = ($(this).data('id') == null ? [] : [$(this).data('id')]);
            if ($(this).data('id') == null) {
                $('.vehicle-delete[name="ids[]"]:checked').map(function () {
                    ids.push($(this).val());
                });
            }
            if (ids.length > 0) {
                _modal.create_with_data(this, 'confirm-modal', {'ids': ids});
            }
        }
    });

    // // リース情報削除確認時
    // $(document).on('click', '[data-lease-confirm-modal]', function () {
    //     _modal.create(this, 'lease-confirm-modal', ['lease_id']);
    // });

    // // レンタル情報削除確認時
    // $(document).on('click', '[data-rental-confirm-modal]', function () {
    //     _modal.create(this, 'rental-confirm-modal', ['rental_id']);
    // });

    // 給油情報削除確認時
    $(document).on('click', '[data-refuel-confirm-modal]', function () {
        _modal.create(this, 'refuel-confirm-modal', ['id']);
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        var modalId = $(this).data('delete-modal');
        _modal.submit(this, 'delete-modal', function(results) {
            if (modalId.indexOf('accident') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.accident.vehicle_id + ']').trigger('click');
            } else if (modalId.indexOf('violation') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.violation.vehicle_id + ']').trigger('click');
            } else if (modalId.indexOf('daily_check') !== -1) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.vehicle_id + ']').trigger('click');
            } else {
                _modal.hide('#vehicle-detail-modal');
            }
        });
    });

    // // リース情報削除実行時
    // $(document).on('click', '[data-lease-delete-modal]', function () {
    //     const updateAction = $(this).data('update-action');
    //     const updateData = {
    //         'id' : $(this).data('update-id'),
    //         'serial' : $(this).data('update-serial'),
    //     };
    //     _modal.submit(this, 'lease-delete-modal', function(results) {
    //         updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-lease-table');
    //     }, null, '-form', true);
    // });

    // // レンタル情報削除実行時
    // $(document).on('click', '[data-rental-delete-modal]', function () {
    //     const updateAction = $(this).data('update-action');
    //     const updateData = {
    //         'id' : $(this).data('update-id'),
    //         'serial' : $(this).data('update-serial'),
    //     };
    //     _modal.submit(this, 'rental-delete-modal', function(results) {
    //         updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-rental-table');
    //     }, null, '-form', true);
    // });

    // 給油情報削除実行時
    $(document).on('click', '[data-refuel-delete-modal]', function () {
        let callback = function(results) {
                $('[data-refuel-detail-modal=vehicle-refuel-detail-modal]').trigger('click');
            };
        if($('.vehicle-detail-refuel-button').is(":visible")) { //車両詳細モーダルの給油情報タブの詳細ボタンからの操作の場合
            callback = function(results) {
                $('[data-detail-modal=vehicle-detail-modal][data-id=' + results.vehicle_id + ']').trigger('click');
            };
        }
        _modal.submit(this, 'refuel-delete-modal', callback);
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        let modalId = '#vehicle-csv-setting-modal';
        $(modalId + '-csv-submit-message').empty().addClass('display-none');
        $(modalId + '-csv-error').empty().addClass('display-none');
        $(modalId + '-csv_file_name').val('');
        $(modalId + '-csv').val('');
    });

    // CSVアップロード画面の選択肢変更時
    $(document).on('change', '#vehicle-csv-setting-modal-holding_id, #vehicle-csv-setting-modal-company_id, #vehicle-csv-setting-modal-section_id', function () {
        // 既にCSV選択後であれば初期化処理
        if (!$("[data-csv-save-modal]").prop("disabled")) {
            let modalId = '#vehicle-csv-setting-modal';
            $(modalId + '-csv-submit-message').empty().addClass('display-none');
            $(modalId + '-csv-error').empty().addClass('display-none');
            $(modalId + '-csv_file_name').val('');
            $(modalId + '-csv').val('');
            $(modalId + '-data_path').val('');
            $("[data-csv-save-modal]").prop("disabled", true);
        }
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#vehicle-csv-setting-modal-csv', function () {
        let modalId = '#vehicle-csv-setting-modal';
        let formId = '#vehicle-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
            $(modalId + '-data_path').val(results.data_path);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
                $(modalId + '-csv_file_name').val('');
                $(modalId + '-csv').val('');
                $(modalId + '-data_path').val('');
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#vehicle-csv-setting-modal';
        let formId = '#vehicle-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}

// tabindexの初期化
const tab_clear = function() {
    let tabIndex = $('[tabindex]');
    while(tabIndex.length > 0) {
        tabIndex.removeAttr('tabindex');
        tabIndex = $('[tabindex]');
    }
}

/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.vehicle_setting_event_set = function () {

    $(document).on('click', '#certificate-part .select2-container', function (e) {
        tab_clear();
    });
    $(document).on('keydown', '#certificate-part', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode == 9) { //Tabの場合
            tab_clear();
        }
    });

    /*---- 装備品ツリー処理 ----*/

    var tree = {
        element: '',
        selectedNode: [],
        parentNode: [],
        setTree: function(elm) {
            tree.element = elm.find('#equipment-tree');
            tree.getTree(tree.element.data('action'), tree.element.data('selected'));
        },
        getTree: function (action, selected) {
            action = extend_url_query(action);
            _ajax.post(action, {'vehicle_equipment': true, 'selected': selected}, function(results) {
                /*---- ツリー情報表示 ----*/
                tree.element.treeview({
                    data: results.data,
                    expandIcon: 'fas fa-plus',
                    collapseIcon: 'fas fa-minus',
                    onNodeSelected: function(event, node) {
                        if ((node.custom || '') !== '') {
                            tree.selectedNode = node.custom;
                            $('input[name="equipment_id"]').val(node.custom.equipment_id);
                            $('button[name="equipment_selected"]').prop('disabled', false);
                        } else {
                            tree.selectedNode = [];
                            $('input[name="equipment_id"]').val('');
                            $('button[name="equipment_selected"]').prop('disabled', true);
                        }
                    },
                    onRendered: function(event, nodes) {
                        // expand selected
                        tree.selectedNode = tree.element.treeview('getSelected');
                        tree.element.treeview('expandNode', [ tree.selectedNode, { silent: true } ]);
                        tree.element.treeview('revealNode', [ tree.selectedNode, { silent: true } ]);
                    }
                });
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        },
    }

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);

        // 別ステップ移動時に、bs_step_trigger関数で、元々開いていたnav-tabsコンテンツのshowクラスが消えるので追加
        let firstNav = $('#baseNavTab [data-toggle="tab"]:first');
        $('#baseNavTab [data-toggle="tab"]').removeClass('active');
        firstNav.addClass('active');
        let contentId = firstNav.attr('href');
        $(contentId).addClass('show').addClass('active');
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#vehicle-setting-modal';
        let formId = '#vehicle-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#vehicle-setting-modal-confirm_step').val($(this).data('confirm-step'));
        // 送信前にエラーをリセット
        _error.reset()
        vehicle_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            bs_step_trigger(nextPartTrigger);
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            vehicle_set_steper_error();
        });
    });

    /*---- 基本情報ステップボタンクリック時 ----*/

    $(document).on('click', '.bs-stepper .step:first', function () {
        // 別ステップ移動時に、bs_step_trigger関数で、元々開いていたnav-tabsコンテンツのshowクラスが消えるので追加
        let activeNav = $('#baseNavTab .active[data-toggle="tab"]');
        let contentId = activeNav.attr('href');
        $(contentId).addClass('show');
    });

    /*---- 装備品追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', '#equipment-part button[name="add_equipment"]', function () {
        let count = $('#equipment-part .card').length;
        let card = $('#equipment-part #card0').clone();

        /*---- 追加要素の初期化 ----*/
        // 新規フォームは背景グレー
        card.attr('id', 'card' + count).addClass('badge-light');
        // フォーム削除ボタンON
        card.find('a.equipment-close').removeClass('d-none');
        // 削除チェック無効
        card.find('#label_equipment_delete').remove();
        card.find('input[name="equipment_delete[0]"]').remove();
        card.find('input[name="vehicle_equipment_id[0]"]').remove();
        // 点検予定日、実施日削除
        card.find('div.inspection-manage').remove();
        // 装備選択ボタン有効＆初期化
        card.find('button.select_equipment').prop('disabled', false)
            .data('card_no', count)
            .data('vehicle_equipment_id', '')
            .data('select_type', 1);
        // 装備品内容クリア
        card.find('span.serial_number').html('');
        card.find('span.equipment_name').html('');
        card.find('span.version').html('');
        card.find('span.unit').html('');
        card.find('#vehicle-setting-modal-quantity_0').prop('disabled', true);
        card.find('#vehicle-setting-modal-price_0').prop('disabled', true);
        card.find('#vehicle-setting-modal-note_0').prop('disabled', true);
        // 点検設定ボタン初期化＆エリア非表示
        card.find('button[name="inspection_setting"]').addClass('btn-outline-secondary').removeClass('btn-secondary').prop('disabled', true);
        card.find('.starting_date_flg-block').addClass('d-none');
        card.find('.inspection_type-block').addClass('d-none');
        card.find('.inspection_setting-block').hide();

        // 入力フォーム群のクリア＆id、name属性の書き換え
        card.find('input,select').each(function () {
            // radio、checkboxはvalueクリアしない
            if ($(this).attr('type') === 'radio' || $(this).attr('type') === 'checkbox') {
                $(this).prop('checked', false);
                $(this).parent('label').removeClass('active');
            } else if ($(this).attr('type') !== 'button') {
                $(this).val('').prop('selected', false).prop('checked', false);
            }
            let id = $(this).attr('id') || '';
            let name = $(this).attr('name') || '';

            let newId = id.replace(/_0/g, '_' + count);
            let newName = name.replace(/\[0\]/g, '[' + count + ']');
            let label = card.find('label[for="' + id + '"]');
            let error = card.find('#' + id + '-errors');

            // ID書き換え
            if (newId.length > 0) {
                $(this).attr('id', newId);
                label.attr('for', newId);
                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
            if (newName.length > 0) {
                $(this).attr('name', newName);
            }
        });

        // 最初に追加
        card.insertBefore('#equipment-part .card:first');
        initialize();
    });

    /*---- 装備品フォーム削除 ----*/

    $(document).on('click', 'a.equipment-close', function () {
        let card = $(this).parents('.card');
        card.remove();
    });

    /*---- 点検設定OPEN/CLOSE ----*/

    $(document).on('click', '#equipment-part button[name="inspection_setting"]', function () {
        let settingBlock = $(this).parents('div').siblings('.inspection_setting-block');
        settingBlock.slideToggle(100);
        settingBlock.find('select').prop('disabled', false);
        settingBlock.find('input').prop('disabled', false);
    });

    /*---- 起算日切替 ----*/

    $(document).on('change', '#equipment-part select[name^="starting_date_flg"]', function () {
        $(this).parents('.inspection_setting-block').find('.starting_date_flg-block').addClass('d-none');
        if ($(this).val() === '2') {
            $(this).parents('.inspection_setting-block').find('.starting_date_flg-block').removeClass('d-none');
        }
    });

    /*---- 点検設定切替 ----*/

    $(document).on('change', '#equipment-part select[name^="inspection_type"]', function () {
        $(this).parents('.inspection_setting-block ').find('.inspection_type-block').addClass('d-none');
        $(this).parents('.inspection_setting-block ').find('#inspection_type_' + $(this).val()).removeClass('d-none');
        $(this).parents('.inspection_setting-block ').find('#inspection_type_' + $(this).val()).find('select').prop('selected', false).val('');
        $(this).parents('.inspection_setting-block ').find('#inspection_type_' + $(this).val()).find('input').not(':radio, :checkbox, :button').val('');
        $(this).parents('.inspection_setting-block ').find('#inspection_type_' + $(this).val()).find(':checkbox').prop('checked', false);
        $(this).parents('.inspection_setting-block ').find('#inspection_type_' + $(this).val()).find(':radio').prop('checked', false);
    });

    /*---- 装備選択画面表示 ----*/

    $(document).on('click', '[data-select-modal]', function () {
        _modal.create(this,'select-modal',
            ['id', 'vehicle_equipment_id', 'card_no', 'selected_exchange_vehicle_equipment_id', 'selected_equipment_id'],
            true,
            function() {
            let modal = $('#equipment_select-modal');
            let type = $('input[name="select_type"]:checked').val();
            let submit = modal.find('button[name="selected"]');

            // 装備品ツリー情報生成
            tree.setTree(modal);

            // 選択タイプ入力切替＆初期化
            modal.find('input[name="select_type"]').off().change(function() {
                type = $('input[name="select_type"]:checked').val();
                modal.find('.select_type-block').addClass('d-none');
                modal.find('#type' + type).removeClass('d-none');

                if (type === '1') {
                    if (tree.selectedNode.length === 0) {
                        submit.prop('disabled', true);
                    } else {
                        submit.prop('disabled', false);
                    }
                }
                if (type === '2'){
                    if (modal.find('select[name="vehicle_equipment_id"]').val() === '') {
                        submit.prop('disabled', true);
                    } else {
                        submit.prop('disabled', false);
                    }
                }
            });

            /*---- 選択ボタンON/OFF ----*/
            // 車両装備品選択
            modal.find('select[name="vehicle_equipment_id"]').change(function(){
                if ($(this).val() === '') {
                    modal.find('button[name="selected"]').prop('disabled', true);
                } else {
                    modal.find('button[name="selected"]').prop('disabled', false);
                }
            });
        });
        initialize();
    });

    /*---- 装備品選択 ----*/

    $(document).on('click', '[data-selected-modal]', function () {
        let cardNo = $(this).data('card-no');
        let card = $('#equipment-part #card' + cardNo);
        _modal.submit(this, 'selected-modal', function(result) {
            // 選択結果をセット＆入力項目をenabled
            if (result.exchange_vehicle_equipment_id !== '') {
                card.find('button.select_equipment')
                .data('selected_equipment_id', '')
                .data('selected_exchange_vehicle_equipment_id', result.exchange_vehicle_equipment_id);
            } else {
                card.find('button.select_equipment')
                .data('selected_equipment_id', result.equipment_id)
                .data('selected_exchange_vehicle_equipment_id', '');
            }
            card.find('span.serial_number').html(result.serial_number);
            card.find('span.equipment_name').html(result.equipment_name);
            card.find('span.version').html(result.version);
            card.find('span.unit').html(result.unit);
            $('input[name="exchange_vehicle_equipment_id[' + cardNo + ']"]').val(result.exchange_vehicle_equipment_id);
            $('input[name="equipment_id[' + cardNo + ']"]').val(result.equipment_id);
            card.find('button[name="inspection_setting"]').prop('disabled', false);

            // 交換元設定値あれば継承
            $('input[name="quantity[' + cardNo + ']"]').val(result.quantity).prev('.number-input').prop('disabled', false).val(result.quantity);
            $('input[name="price[' + cardNo + ']"]').val(result.price).prev('.number-input').prop('disabled', false).val(result.price);
            $('input[name="note[' + cardNo + ']"]').prop('disabled', false).val(result.note);
            $('input:hidden[name="note[' + cardNo + ']"]').remove();

            // 交換元点検設定あれば継承
            if (Object.keys(result.inspection).indexOf('id') !== -1) {
                card.find('.inspection_setting-block').children('select').prop('disabled', false);
                card.find('button[name="inspection_setting"]').removeClass('btn-outline-secondary').addClass('btn-secondary');
                // 起算日
                $('select[name="starting_date_flg[' + cardNo + ']"]').val(result.inspection.starting_date_flg);
                if (result.inspection.starting_date_flg === 2) {
                    card.find('.starting_date_flg-block').removeClass('d-none');
                    $('input[name="starting_date[' + cardNo + ']"]').val(result.inspection.starting_date);
                }
                // 点検設定
                card.find('#inspection_type_' + result.inspection.inspection_type).removeClass('d-none');
                $('select[name="inspection_type[' + cardNo + ']"]').val(result.inspection.inspection_type);
                if (result.inspection.inspection_type === 1) {
                    $('input[name="inspection_date[' + cardNo + ']"]').val(result.inspection.inspection_date);
                }
                if (result.inspection.inspection_type === 2) {
                    $('select[name="inspection_interval_date[' + cardNo + ']"]').val(result.inspection.inspection_interval);
                }
                if (result.inspection.inspection_type === 3) {
                    $('select[name="inspection_interval_month[' + cardNo + ']"]').val(result.inspection.inspection_interval);
                }
                if (result.inspection.inspection_type === 4) {
                    $('input[name="inspection_months[' + cardNo + '][]"]').each(function() {
                        if ($.inArray($(this).val(), result.inspection.inspection_months) !== -1) {
                            $(this).prop('checked', true);
                            $(this).parent('label').addClass('active');
                        }
                    });
                }
            }
        }, null, '-form', true);
    });

    /*---- 運輸局選択時の支局セット ----*/

    $(document).on('change', 'select[name="transportation_office_parent"]', function () {
        // 支局セット
        let select = $(this).parents('form').find('select[name="transportation_office_child"]');
        if (select.length > 0) {
            if ($(this).val() === '') {
                $(select).empty();
                $(select).select2({
                    width: "100%",
                    data: [{'id': '', 'text': '-----'}],
                    val: ''
                });
                return;
            }
            let action = $(select).data('action');
            let value = $(select).val();
            datas = {'parent_id': $(this).val()};
            _ajax.post(action, datas, function (results) {
                $(select).empty();
                let option = {
                    width: "100%",
                    data: results.options,
                    val: value
                };
                // modal内の時は親modal要素をdropdownParent指定する
                let parent = $(select).parents('.modal-content');
                if (parent.length > 0) {
                    option.dropdownParent = parent;
                }
                $(select).select2(option);
                tab_clear();
                $('select[name="transportation_office_parent"]').focus();
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    });

    $(document).on('change', 'select[name="transportation_office_child"]', function () {
        tab_clear();
        $('select[name="transportation_office_child"]').focus();
    });

    // 運輸局select部品のフォーカス対応
    $(document).on('focus', 'select[name="transportation_office_parent"]', function () {
        tab_clear();
        $('select[name="transportation_office_parent"]').parent().find('span[role="combobox"]').addClass('parent-select-focused');
    });
    $(document).on('blur', 'select[name="transportation_office_parent"]', function () {
        $('select[name="transportation_office_parent"]').parent().find('span[role="combobox"]').removeClass('parent-select-focused');
    });
    $(document).on('focus', 'select[name="transportation_office_child"]', function () {
        tab_clear();
        $('select[name="transportation_office_child"]').parent().find('span[role="combobox"]').addClass('parent-select-focused');
    });
    $(document).on('blur', 'select[name="transportation_office_child"]', function () {
        $('select[name="transportation_office_child"]').parent().find('span[role="combobox"]').removeClass('parent-select-focused');
    });

    /* ---- ステータス廃車時の売却先・売却金額表示 ---- */
    $(document).on('change', '#vehicle-setting-modal-status', function () {
        if(parseInt($(this).val()) === 3) { //廃車選択時
            $('#for-scrap-data').removeClass('no-disp-unless-scrap');
        } else {
            //廃車以外選択時、値をクリア
            $('#vehicle-setting-modal-buyer').val('');
            $('input[name="buyer"]').val('');
            $('#vehicle-setting-modal-sales_price').val('');
            $('input[name="sales_price"]').val('');
            $('#for-scrap-data').addClass('no-disp-unless-scrap');
        }
    });

    /*---- 資産形態変更時のフォーム表示 ----*/

    // 設定モーダル表示時に処理
    $(document).on('show.bs.modal', '#vehicle-setting-modal', function (e) {
        vehicle_setting_set_by_asset_type($('.vehicle-setting-asset-type:checked'));
    });
    //資産形態クリック時
    $(document).on('click', 'input[name="asset_type"]', function (e) {
        vehicle_setting_set_by_asset_type($('.vehicle-setting-asset-type:checked'));
    });

    /*---- リース情報・レンタル情報 ----*/

    const updateSettingModalTable = (action, datas, updateElementId) => {
        // 送信
        _ajax.post(action, datas, function (results) {
            $('#' + updateElementId).html(results);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    }

    // リース情報設定表示時
    $(document).on('click', '[data-lease-setting-modal]', function () {
        _modal.create(this, 'lease-setting-modal', ['id', 'serial', 'lease_id']);
    });

    // リース情報設定保存時
    $(document).on('click', '[data-lease-save-modal]', function () {
        const updateAction = $(this).data('update-action');
        const updateData = {
            'id' : $(this).data('update-id'),
            'serial' : $(this).data('update-serial'),
        };
        _modal.submit(this, 'lease-save-modal', function(results) {
            updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-lease-table');
        }, function() {
            vehicle_set_steper_error();
        }, '-form', true);
    });

    // リース情報削除確認時
    $(document).on('click', '[data-lease-confirm-modal]', function () {
        _modal.create(this, 'lease-confirm-modal', ['lease_id']);
    });

    // リース情報削除実行時
    $(document).on('click', '[data-lease-delete-modal]', function () {
        const updateAction = $(this).data('update-action');
        const updateData = {
            'id' : $(this).data('update-id'),
            'serial' : $(this).data('update-serial'),
        };
        _modal.submit(this, 'lease-delete-modal', function(results) {
            updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-lease-table');
        }, null, '-form', true);
    });

    // レンタル情報設定表示時
    $(document).on('click', '[data-rental-setting-modal]', function () {
        _modal.create(this, 'rental-setting-modal', ['id', 'serial', 'rental_id']);
    });

    // レンタル情報設定保存時
    $(document).on('click', '[data-rental-save-modal]', function () {
        const updateAction = $(this).data('update-action');
        const updateData = {
            'id' : $(this).data('update-id'),
            'serial' : $(this).data('update-serial'),
        };
        _modal.submit(this, 'rental-save-modal', function(results) {
            updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-rental-table');
        }, function() {
            vehicle_set_steper_error();
        }, '-form', true);
    });

    // レンタル情報削除確認時
    $(document).on('click', '[data-rental-confirm-modal]', function () {
        _modal.create(this, 'rental-confirm-modal', ['rental_id']);
    });

    // レンタル情報削除実行時
    $(document).on('click', '[data-rental-delete-modal]', function () {
        const updateAction = $(this).data('update-action');
        const updateData = {
            'id' : $(this).data('update-id'),
            'serial' : $(this).data('update-serial'),
        };
        _modal.submit(this, 'rental-delete-modal', function(results) {
            updateSettingModalTable(updateAction, updateData, 'vehicle-setting-modal-rental-table');
        }, null, '-form', true);
    });

    // 新規作成の際、車検情報「自動車の種別」・「自家用・事業用」変更時にナンバープレート区分自動変更（※編集時は対象外）
    $(document).on('change', '#certificate-vehicle-type-container, #certificate-home-business-container', function () {
        let hiddenId = $('#vehicle-setting-modal-id');
        if(hiddenId.val() != undefined && hiddenId.val().length > 0) { //新規作成でなければ何もしない
            return;
        }
        let vehicleTypeVal = $('#certificate-vehicle-type-container').find('label.active').find('input').val();
        let homeBusinessVal = $('#certificate-home-business-container').find('label.active').find('input').val();

        if(vehicleTypeVal == undefined || homeBusinessVal == undefined) {
            $('select[name=number_plate_type').val("");
            return;
        }
        //自家用
        if(parseInt(homeBusinessVal) == 1) {
            switch(parseInt(vehicleTypeVal)) {
                case 1:     // 小型
                    $('select[name=number_plate_type').val("1");
                    break;
                case 2:     // 普通
                    $('select[name=number_plate_type').val("1");
                    break;
                case 3:     // 大特
                    $('select[name=number_plate_type').val("1");
                    break;
                case 4:     // 小特
                    $('select[name=number_plate_type').val("1");
                    break;
                case 5:     // 軽
                    $('select[name=number_plate_type').val("3");
                    break;
                default:
                    $('select[name=number_plate_type').val("");
                    break;
            }
        } else if(parseInt(homeBusinessVal) == 2) {
            //事業用
            switch(parseInt(vehicleTypeVal)) {
                case 1:     // 小型
                    $('select[name=number_plate_type').val("2");
                    break;
                case 2:     // 普通
                    $('select[name=number_plate_type').val("2");
                    break;
                case 3:     // 大特
                    $('select[name=number_plate_type').val("2");
                    break;
                case 4:     // 小特
                    $('select[name=number_plate_type').val("2");
                    break;
                case 5:     // 軽
                    $('select[name=number_plate_type').val("4");
                    break;
                default:
                    $('select[name=number_plate_type').val("");
                    break;
            }
        } else {
            $('select[name=number_plate_type').val("");
        }
    });
}

window.vehicle_setting_set_by_asset_type = function (selector) {
    const typeArr = [1,2,3,4];
    const classPrefix = '.asset-type-';
    let activeType = $(selector).val();
    let formId = create_id($('#asset_type_container').data('form-id'));

    for(const type of typeArr) { //一度、全タイプ非表示
        $(formId).find(classPrefix + type).each(function() {
            $(this).addClass('display-none');
        });
    }
    $(formId).find(classPrefix + activeType).each(function() { //対象のタイプを表示
        $(this).removeClass('display-none');
    });
    if(activeType == undefined) { //デフォルト表示の場合
        $(formId).find(classPrefix + 'default').each(function() { //対象のタイプを表示
            $(this).removeClass('display-none');
        });
    }
}

// ステッパーへのエラー表示処理
window.vehicle_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#certificate-part-trigger').toggleClass('error', ($('#certificate-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#equipment-part-trigger').toggleClass('error', ($('#equipment-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#insurance-part-trigger').toggleClass('error', ($('#insurance-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#other-part-trigger').toggleClass('error', ($('#other-part').find('.invalid-feedback:not(.copy)').length > 0));
        // 基本情報タブ
        $('#base-part').find('.tab-pane').each(function (index, element) {
            let key = $(this).attr('id');
            $('#base-part').find("a[href='#"+key+"']").toggleClass('error', $(this).find('.invalid-feedback:not(.copy)').length > 0);
        });
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#certificate-part-trigger').removeClass('error');
        $('#equipment-part-trigger').removeClass('error');
        $('#insurance-part-trigger').removeClass('error');
        $('#other-part-trigger').removeClass('error');
        // 基本情報タブ
        $('#base-part').find('.tab-pane').each(function (index, element) {
            let key = $(this).attr('id');
            $('#base-part').find("a[href='#"+key+"']").removeClass('error');
        });
    }
}
