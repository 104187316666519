/**
 * 分または秒から時間を取得する
 * @param {*} value 
 * @param {*} isMinutes 
 */
 exports.toHours = function (value, isMinutes = true) {
    if (isMinutes) {
        return Math.floor(value / 60) ;
    } else {
        return Math.floor(Math.floor(value / 60) / 60);
    }
};
/**
 * 時間または秒から分を取得する
 * @param {*} value 
 * @param {*} isHours 
 */
 exports.toMinutes = function (value, isHours = true) {
    if (isHours) {
        return value * 60;
    } else {
        return Math.floor(value / 60);
    }
};
/**
 * 時間または分から秒を取得する
 * @param {*} value 
 * @param {*} isHours 
 */
 exports.toSeconds = function (value, isMinutes = true) {
    if (isMinutes) {
        return value * 60;
    } else {
        return value * 60 * 60;
    }
};