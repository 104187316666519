/**
 * 文字列を郵便番号に変換する
 * @returns string
 */
$.fn.zipcode = function () {
    // 半角数字のみ書き出し郵便番号の形式にフォーマット
    text = this.text().replace(/[^0-9]/g, '');
    zipcode = '〒' + text.substr(0,3) + '-' + text.substr(3);
    // 値を更新
    this.text(zipcode);
    return this;
};