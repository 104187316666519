const { includes, indexOf } = require("lodash");

/**
 * 操作履歴ページ用のスクリプト
 */
if (is_route('operation_history.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        operation_history_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.operation_history_event_set = function() {

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

}
