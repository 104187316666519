/**
 * 会社ページ用のスクリプト
 */
if (is_route('company.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        company_event_set();
        company_local_app_key_event();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
 window.company_event_set = function() {

    /*---- 設定の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id']);
    });

     // 設定画面 パスワード表示アイコンクリック時
     $(document).on('click', '.show-password-btn', function () {
         let input = $(this).parents('.input-group').find('input');
         if (input.attr('type') === 'password') {
             // パスワード表示
             input.attr('type', 'text');
             $(this).find('.fas').removeClass('fa-eye').addClass('fa-eye');
         } else {
             // パスワード非表示
             input.attr('type', 'password');
             $(this).find('.fas').removeClass('fa-eye').addClass('fa-eye-slash');
         }
     });

     /*---- 設定の保存時 ----*/

    // 設定実行時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'holding')) {
                $('[data-replace-name-holding-id=' + results.holding.id + ']').text(results.holding.name);   
            }
            if (keys.find(key => key == 'company')) {
                $('[data-replace-name-company-id=' + results.company.id + ']').text(results.company.name);   
            }
            company_certification_modal(results);
        });
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        });
    });

    /*---- CSV雛形ダウンロード時 ----*/

    // CSV雛形ダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#company-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#company-csv-setting-modal-csv-submit-message').empty();
        $('#company-csv-setting-modal-csv-error').addClass('display-none');
        $('#company-csv-setting-modal-csv-error').empty();
        $('#company-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#company-csv-setting-modal-csv', function () {
        let modalId = '#company-csv-setting-modal';
        let formId = '#company-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#company-csv-setting-modal';
        let formId = '#company-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });

    // 車楽連携ON/OFF切り替え時
    $(document).on('change', 'input[name=is_syaraku]', function(){
        modalId = "#company-setting-modal";
        syarakuUrl = $(modalId + '-syaraku_url');
        syarakuClassCode = $(modalId + '-syaraku_class_code');
        if ($(this).val() === '1') {
            syarakuUrl.prop('disabled', false).trigger('change');
            syarakuClassCode.prop('disabled', false).trigger('change');
        } else {
            syarakuUrl.val(null).prop('disabled', true).trigger('change');
            syarakuClassCode.val(null).prop('disabled', true).trigger('change');
        }
    });
}
/**
 * ローカルアプリキーに関連するイベントをセットする
 */
window.company_local_app_key_event = function() {

    /*---- ローカルアプリキーの再発行確認 ----*/

    // 再発行確認時
    $(document).on('click', '[data-local_app_key-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'local_app_key-modal', ['id']);
    });

    /*---- ローカルアプリキーの再発行 ----*/

    // 再発行時
    $(document).on('click', '[data-refresh-modal]', function () {
        _modal.submit(this, 'refresh-modal', function(results) {
            company_certification_modal(results);
        });
    });
}
/**
 * ローカルアプリキーモーダルを表示する
 * @param {*} results 
 */
window.company_certification_modal = function(results) {
    if (results.local_app_key != null) {
        $('#company-certification-modal-company_name').html(results.company.name);
        $('#company-certification-modal-local_app_key').html(results.local_app_key);
        _modal.show('#company-certification-modal');
    }
}