/**
 * アカウント設定ページ用のスクリプト
 */
if (is_route('account.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        account_event_set();
        account_csv_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
 window.account_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], true);
    });

     // 設定画面 パスワード表示アイコンクリック時
     $(document).on('click', '.show-password-btn', function () {
         let input = $(this).parents('.input-group').find('input');
         if (input.attr('type') === 'password') {
             // パスワード表示
             input.attr('type', 'text');
             $(this).find('.fas').removeClass('fa-eye-slash').addClass('fa-eye');
         } else {
             // パスワード非表示
             input.attr('type', 'password');
             $(this).find('.fas').removeClass('fa-eye').addClass('fa-eye-slash');
         }
     });

     /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        let defaultUser = $('#account-setting-modal input[name=default_user_id]');
        let count = $('#account-setting-modal input[name=added_group_count]').val();
        // ユーザーが新規付与される場合以外
        if ($(defaultUser).val().length > 0 && count > 0) {
            let userId = $('#account-setting-modal [name=user_id]').val();
            if ($(defaultUser).val() != userId) {
                // ユーザーの権限グループ初期化確認
                _modal.create_with_data(defaultUser, 'confirm-modal', {
                    'default_user_id': $(defaultUser).val(),
                    'user_id': userId,
                });
                return false;
            }
        }
        _modal.submit(this, 'save-modal');
    });

    /*---- 設定の保存時（ユーザーグループ初期化確認） ----*/

    // 設定保存時
    $(document).on('click', '[data-change-confirm-modal]', function () {
        let confirmModalId = create_id($(this).data('change-confirm-modal'));
        $('#account-setting-modal input[name=initialize_user_group]').val($(this).data('initialize'));
        _modal.hide(confirmModalId);
        _modal.submit($('[data-save-modal]'), 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    // /*---- CSV雛形ダウンロード時 ----*/

    // CSV雛形ダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#account-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#account-csv-setting-modal-csv-submit-message').empty();
        $('#account-csv-setting-modal-csv-error').addClass('display-none');
        $('#account-csv-setting-modal-csv-error').empty();
        $('#account-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#account-csv-setting-modal-csv', function () {
        let modalId = '#account-csv-setting-modal';
        let formId = '#account-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            $('#account-csv-setting-modal-csv').val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#account-csv-setting-modal';
        let formId = '#account-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 一覧取得用のurlにアクセス
            window.location.href = results.url;
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });
}

window.account_csv_event_set = function() {

    /*---- ヘルプボタン押下時 ----*/

    $(document).on('click', '.modal-account-description', function () {
        _modal.show('#account-csv-description-modal');
    });

}