/**
 * リンク集ページ用のスクリプト
 */
if (is_route('link.edit')) {

    var modalId;
    /* ---- リンク集設定モーダル用処理 ---- */
    var link = {
        fileSizeLimit: 1024 * 1024 * 2,
        fileErrorMsg: '',
        typeUrl: '1',
        typeFile: '2',
        url: {},
        file: {},
        fileName: {},
        fileNameDisp: {},
        fileError: {},
        initialize: function() {

            /* ---- 初期表示設定 ---- */

            link.url = $(modalId + ' input[name="url"]');
            link.file = $(modalId + ' input[name="file_path_file"]');
            link.fileName = $(modalId + ' input[name="file_path"]');
            link.fileNameDisp = $(modalId + ' span.filename-file_path');
            link.fileError = $(modalId + '-file_path-errors');
            link.fileErrorMsg = $(modalId + ' #file-size-error').html();

            // typeが1以外の時、urlをdisabledにする
            if ($(modalId + ' select[name="type"]').val() !== link.typeUrl) {
                link.url.prop('disabled', true);
            }
            // typeが1以外の時、fileをdisabledにする
            if ($(modalId + ' select[name="type"]').val() !== link.typeFile) {
                link.file.parent('span.btn').addClass('disabled');
                link.file.prop('disabled', true);
            }

            /* ---- イベント登録 ---- */

            // タイプ選択時の入力項目制御
            $(modalId + ' .modal-content').on('change', 'select[name="type"]', function() {
                if ($(this).val() === '1') {    // URL
                    link.url.prop('disabled', false);
                    link.file.prop('disabled', true).parent('span.btn').addClass('disabled');
                    link.fileName.val('');
                    link.fileNameDisp.html('');
                } else if ($(this).val() === '2') { // FILE
                    link.url.prop('disabled', true).val('');
                    link.file.prop('disabled', false).parent('span.btn').removeClass('disabled');
                } else {
                    link.url.prop('disabled', true).val('');
                    link.file.prop('disabled', true).parent('span.btn').addClass('disabled');
                    link.fileName.val('');
                    link.fileNameDisp.html('');
                }
            });

            // ファイル選択時のサイズチェック
            $(modalId + ' .modal-content').on('change', 'input[name="file_path_file"]', function() {
                link.fileError.find('.invalid-feedback.size').remove();
                // ファイル指定なし
                if ($(this).prop('files').length === 0) {
                    return;
                }

                let file = $(this).prop('files')[0];
                if (file.size > link.fileSizeLimit) {
                    // ファイルサイズが制限以上
                    var clone = link.fileError.find('.invalid-feedback' + '.copy').clone().html(link.fileErrorMsg).removeClass('copy').addClass('size');
                    link.fileError.append(clone);
                    $(this).val(''); // 中身をリセット
                }
            });
        },
    }

    $(function () {

        /*---- 設定の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-setting-modal]', function () {
            modalId = create_id($(this).data('setting-modal'));
            _modal.create(this, 'setting-modal', ['id'], null, function(){
                link.initialize();
            });
        });

        /*---- 設定の保存時 ----*/

        // 設定実行時
        $(document).on('click', '[data-save-modal]', function () {
            _modal.submit(this, 'save-modal');
        });

        /*---- 削除確認の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-confirm-modal]', function () {
            _modal.create(this, 'confirm-modal', ['id']);
        });

        /*---- 削除時 ----*/

        // 削除実行時
        $(document).on('click', '[data-delete-modal]', function () {
            _modal.submit(this, 'delete-modal');
        });
    });
}
