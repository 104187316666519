/**
 * サイドバーの表示・非表示の処理を行う。
 */
$(function () {
    // 所属選択ボタンクリック時
    $(document).on('click', '.affiliation-select-button', function() {
        const div = $(this).parents('.affiliation-menu').find('.affiliation-select');
        const select = $(this).parents('.affiliation-menu').find('.affiliation-select select');
        $(div).toggleClass('display-none', false);
        $(select).select2('open');
    })
    // 所属選択時
    $(document).on('change', '[data-dropdown-class=affiliation-select-dropdown]', function() {
        const key = $(this).data('key');
        // データの取得
        const id = $(this).val();
        const action = $(this).data('action');
        const is_extend = $(this).data('is-extend');
        const excution = $(this).data('excution');
        let excutionData = (excution != null ? excution.split(',') : []);
        excutionData.push('page');
        if (key == 'holding_id') {
            excutionData.push('company_id', 'section_id');
        } else if (key == 'company_id') {
            excutionData.push('section_id');
        }
        // URLを作成
        const url = _request.create_affiliation_url(action, is_extend, [{'name': key, 'value': id}], excutionData);
        // リダイレクト
        location.href = url;
    });
});