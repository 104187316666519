/**
 * 点検整備修理ページ用のスクリプト
 */
if (is_route('inspection.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        inspection_event_set();
        inspection_setting_event_set();

    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.inspection_event_set = function() {

    /*---- KEYWORD検索フォーム初期化 ----*/
    const initSearchBlock = () => {
        if ($("#inspection-table-card").length !== 0) {
            $('.navbar-search-block').removeClass('d-none');
        } else {
            $('#toolbar-keyword-search').val('');
            $('.navbar-search-block').addClass('d-none');
        }
    }
    initSearchBlock();
    
    // イベント再登録
    $(document).on('change', '#search-form, #toolbar-keyword-search', function () {
        // コンテンツを読込
        load_contents(function () {
            setTimeout(function () {
                initSearchBlock();
            }, 10);
        }, false);
    });

    /*---- ページタブ切り替え時 ----*/

    // タブ切り替え時
    $(document).on('click', '[data-type]', function() {
        $('#search-form-type').val($(this).data('type'));
        $('#search-form-type').trigger('change');
    });

    /*---- カレンダー予定日未登録車両切替 ----*/
    $(document).on('change', 'select[name="select_inspection_type"]', function() {
        if ($(this).find('option:selected').val().length == 0) {
            $('.select-panel').show();
        } else {
            $('.select-panel').hide();
            $('#select-panel-' + $(this).find('option:selected').val()).show();
        }
    });

    /*---- 車両検索時車両メンテナンス区分パラメタクリアする ----*/
    $(document).on('change', '#search-form-vehicle_id', function(){
        var url = new URL(window.location.href);
        // 営業所指定が無ければクリア
        if (url.searchParams.get('section_id') === null && url.searchParams.get('inspection_type')) {
            url.searchParams.delete('inspection_type');
            url.searchParams.set('vehicle_id', $(this).val());
            window.location.href = url.href;
        }
    });

    /*---- カレンダーへのドロップ時 ----*/

    // ドロップ時
    $(document).on('drop', '.calendar-date,.schedule-list', function() {
        const dropId  = create_id(event.dataTransfer.getData('id'));
        if(!dropId.startsWith('#plan_')) { //ドロップ場所対象外
            event.preventDefault();
            return;
        }
        const dragged = $(dropId);
        const dropTo = $(this);
        if(dropTo.data('date') == dragged.data('original-date')) { //元の場所にドロップされた場合
            event.preventDefault();
            return;
        }
        dragged.data('original-date', dropTo.data('date'));
        const data = {
            id : dragged.data('id'),//ドラッグしている予定のID
            inspection_type_id : dragged.data('inspection_type_id'),//ドラッグしている予定のメンテ区分ID
            date : dropTo.data('date'),//ドロップ先日付
        };
        const action = dragged.data('drop-action');
        if(data.id == undefined) {
            event.preventDefault();
            return;
        }
        _ajax.post(action, data, function (results) {
            toastr.success(results.message);
            load_contents();
            return;
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'json');
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'edit_type', 'inspection_id'], true, 
            function () {
                // 所属変更前の車両メンテの場合は入力欄を全てdisabledにする
                $('.modal-inspection #transferred-vehicle :input').prop('disabled', true);
            }
        );
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        //自社・他社で「他社」指定以外の場合、依頼先をクリアする
        if(false == $('input#inspection-setting-modal-own_or_other_2').prop('checked')) {
            $('input#inspection-setting-modal-request_destination').val('');
        }
        //終日チェックあり／無しに従ってクリアする
        if($('input#inspection-setting-modal-expected_allday').prop('checked')) {
            $('input#inspection-setting-modal-expected_date_start').val('');
            $('input#inspection-setting-modal-expected_date_end').val('');
        } else {
            $('input#inspection-setting-modal-expected_date_start_2').val('');
            $('input#inspection-setting-modal-expected_date_end_2').val('');
        }
        if($('input#inspection-setting-modal-completed_allday').prop('checked')) {
            $('input#inspection-setting-modal-completed_date_start').val('');
            $('input#inspection-setting-modal-completed_date_end').val('');
        } else {
            $('input#inspection-setting-modal-completed_date_start_2').val('');
            $('input#inspection-setting-modal-completed_date_end_2').val('');
        }
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function() {
            _modal.hide('#inspection-setting-modal');
        });
    });


    /*---- 修理履歴表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['inspection_id'], true);
    });

    /*---- 修理履歴設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-repair-save-modal]', function () {
        //予定日・実施日の非表示部品の値をクリア
        $('.expected-type .display-none input').val('');
        $('.completed-type .display-none input').val('');
        
        _modal.submit(this, 'repair-save-modal', function(result) {
            if (result.count > 0) {
                $('#inspection-setting-modal #repair-count').html('(' + result.count + ')');
            } else {
                $('#inspection-setting-modal #repair-count').html('');
            }
        }, null, '-form', true);
    });

    /*---- 終日チェック変更時 ----*/
    $(document).on('change', '.allday-checkbox', function() {
        const onAllday = '.' + $(this).data('allday-type') + '-date';
        const onNotAllday = '.' + $(this).data('allday-type') + '-datetime';
        //終日チェックON
        if($(this).prop('checked')) {
            $(onAllday).removeClass('display-none');
            $(onNotAllday).addClass('display-none');
        } else {
            $(onNotAllday).removeClass('display-none');
            $(onAllday).addClass('display-none');
        }
    });

    /*---- チャート表示処理 ----*/

    // チャート表示
    $(document).on('click', '[data-chart-modal]', function () {
        // モーダルのID取得とキャンバスIDを生成
        var modalId = $(this).data('chart-modal');
        var canvasId = $(this).data('canvas-id');
        var action = $(this).data('action');
        // 親検索情報継承
        let data = $('#search-form').serializeArray();
        data.push({'name' : 'holding_id', 'value' : $(this).data('holding_id')});
        data.push({'name' : 'company_id', 'value' : $(this).data('company_id')});
        data.push({'name' : 'section_id', 'value' : $(this).data('section_id')});
        data.push({'name' : 'vehicle_id', 'value' : $(this).data('vehicle_id')});
        // 検索条件初期化＆不要削除
        data = data.filter((item) => {
            return (item.name !== 'repair_id'
                || item.name !== 'completed_from'
                || item.name !== 'completed_to'
                || item.name !== 'expected_date'
                || item.name !== 'status'
                || item.name !== 'type'
            );
        });

        // チャートモーダル内の検索情報初期化
        var completedFrom = $(modalId + ' input[name="completed_from"]');
        var completedTo = $(modalId + ' input[name="completed_to"]');
        var repairId = $(modalId + ' select[name="repair_id"]');
        completedFrom.val('').off();
        completedTo.val('').off();
        repairId.val('').off();
        // 検索情報更新時
        repairId.change(function(){
            data = data.filter((item) => {
                return (item.name !== 'repair_id');
            });
            data.push({'name' : 'repair_id', 'value' : $(this).val()});
            inspection_chart_event_set(action, modalId, canvasId, data);
        })
        completedFrom.change(function(){
            data = data.filter((item) => {
                return (item.name !== 'completed_from');
            });
            data.push({'name' : 'completed_from', 'value' : $(this).val()});
            inspection_chart_event_set(action, modalId, canvasId, data);
        })
        completedTo.change(function(){
            data = data.filter((item) => {
                return (item.name !== 'completed_to');
            });
            data.push({'name' : 'completed_to', 'value' : $(this).val()});
            inspection_chart_event_set(action, modalId, canvasId, data);
        })
        // チャート表示
        inspection_chart_event_set(action, modalId, canvasId, data, function(results) {
            // モーダルopen
            _modal.show(modalId);
            initialize();
        });
    });

    /*---- CSVダウンロード ----*/

    $(document).on('click', '#search-form-csv_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let params = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, params);
    });
    
    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-csv_upload', function () {
        // 初期化処理
        $('#inspection-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#inspection-csv-setting-modal-csv-submit-message').empty();
        $('#inspection-csv-setting-modal-csv-error').addClass('display-none');
        $('#inspection-csv-setting-modal-csv-error').empty();
        $('#inspection-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#inspection-csv-setting-modal-csv', function () {
        let modalId = '#inspection-csv-setting-modal';
        let formId = '#inspection-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#inspection-csv-setting-modal';
        let formId = '#inspection-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 印刷時 ----*/
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}

/**
 * 車両修理履歴モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.inspection_setting_event_set = function () {

    /*---- 自社・他社の選択による依頼先表示・非表示設定 ----*/
    
    $(document).on('change', '.inspection-setting-own_or_other-type', function () {
        let type = $(this).val();
        if (type == '1') {
            $('#request_destination-container').addClass('display-none');
        } else {
            $('#request_destination-container').removeClass('display-none');
        }
    });

    /*---- 修理履歴の追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', '#inspection_repair-setting button[name="add_inspection_repair"]', function () {
        let count = $('#inspection_repair-setting .cards').length;
        let template = document.querySelector('#copy-repair-card');
        let card = $(template.innerHTML);
        card.attr('id', 'card' + count);

        // 削除項目あれば消す
        card.find('input[name="inspection_repair_delete[0]"]').remove();
        card.find('#label_inspection_repair_delete').remove();

        // 追加要素の初期化
        card.find('input,select').each(function () {
            $(this).val('');

            let id = $(this).attr('id') || '';
            let name = $(this).attr('name') || '';

            // Form::numberはform構成が特殊なため、例外処理
            if ($(this).hasClass('number-input-value')) {
                id = '';
            } else if ($(this).hasClass('number-input')) {
                name = '';
            }

            let newId = id.replace(/_0/g, '_' + count);
            let newName = name.replace(/\[0\]/g, '[' + count + ']');
            let label = card.find('label[for="' + id + '"]');
            let error = card.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            $(this).attr('name', newName);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });
        
        const cardId = card.attr('id');
        // 最初に追加
        card.insertBefore('#inspection_repair-setting .cards:first');
        //option初期化
        if($('#option-holder').text().length > 0) {
            const newOption = JSON.parse($('#option-holder').text());
            newOption.dropdownParent = $('#' + cardId)[0];
            const select = $('#' + cardId).find('select.repair-select');
            select.empty();
            select.select2(newOption);
        }
        
        // 50件になったら追加ボタン殺す
        if (count + 1 >= 50) {
            $(this).prop('disabled', true);
        }
    });
}

/**
 * チャート表示を行う
 * @param {*} action
 * @param {*} modalId
 * @param {*} canvasId
 * @param {*} data
 * @param {*} callback
 */
window.inspection_chart_event_set = function(action, modalId, canvasId, data, callback = null) {
    // 送信
    _ajax.post(action, data, function (results) {
        inspection_draw_chart(canvasId, results, $('input[name="repair_id"]:selected').val());
        // callback
        if (callback != null) {
            callback(results);
        }
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}

/**
 * チャート描画
 * @param {*} canvasId
 * @param {*} results
 */
window.inspection_draw_chart = function(canvasId, results) {

    let dataSets = [
        {
            base: 0,
            label: results.label,
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            data: results.data,
            borderColor: "rgba(28,175,35,0.5)",
            backgroundColor: "rgba(18,114,23,0.5)",
        },
    ];
    let options = {
        legend: {
            onClick: function () { return false },
            position: "bottom",
        }
    }
    // データなしの時の目盛りデフォルトset
    if (results.ticks) {
        options.scales = {
            yAxes: [{
                ticks: {
                    min: results.ticks.min,
                    max: results.ticks.max
                }
            }]
        }
    }

    // チャートクリア
    if (typeof myChart !== 'undefined' && myChart) {
        myChart.destroy();
    }

    window.myChart = chart(canvasId, 'bar', {
        labels: results.labels,
        datasets: dataSets,
    }, options);
}
