/**
 * 測定端末ページ用のスクリプト
 */
var device_treemultiselect = null;

 if (is_route('device.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        device_event_set();
        device_setting_event_set();
        device_batch_transfer_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
 window.device_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        // バブリングを禁止
        e.stopPropagation();
        // 設定モーダル表示
        _modal.create(this, 'setting-modal', ['id', 'type'], true);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 一括削除チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#device-all-delete', function () {
        $(".device-delete").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.device-delete', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#device-all-delete, .device-delete', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#device-multi-delete-button').prop('disabled', ($('.device-delete:checked').length == 0));
    });

    /*---- 所属一括変更表示時 ----*/

    // 所属一括変更表示時
    $(document).on('click', '#search-form-action_batch_transfer', function () {
        _modal.create(this, 'batch-transfer-modal', ['id', 'type'], false, function() {
            user_treemultiselect = initialize_treemultiselect();
        });
    });

    // /*---- 削除確認時 ----*/

    // // 削除確認時
    // $(document).on('click', '[data-confirm-modal]', function () {
    //     _modal.create(this, 'confirm-modal', ['id']);
    // });

    // 一括削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        var ids = ($(this).data('id') == null ? [] : [$(this).data('id')]);
        if ($(this).data('id') == null) {
            $('.device-delete[name="ids[]"]:checked').map(function() {
                ids.push($(this).val());
            });
        }
        if (ids.length > 0) {
            _modal.create_with_data(this, 'confirm-modal', {'ids': ids});
        }
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    // /*---- CSVダウンロード時(測定端末全て) ----*/

    // // CSVダウンロード時
    // $(document).on('click', '#search-form-action_download_all', function () {
    //     // アクションの取得
    //     let action = extend_url_query($(this).data('action'));
    //     // 検索フォームを配列化
    //     let datas = $('#search-form').serializeArray();
    //     // ポスト送信を実行
    //     post(action, datas);
    // });

    //アコーディオンをクリックした時の動作
    $(document).on('change', '.destination-address', function () {//要素をクリックしたら
        var findElm = $(".box1");//直後のアコーディオンを行うエリアを取得し
        $(findElm).slideToggle(0.2);//アコーディオンの上下動作
    });

    /*---- 返却確認時 ----*/

    // 返却確認時
    $(document).on('click', '[data-return-confirm-modal]', function () {
        _modal.create(this, 'return-confirm-modal', ['id']);
        e.stopPropagation();
    });

    /*---- 返却時 ----*/

    // 返却実行時
    $(document).on('click', '[data-return-modal]', function () {
        _modal.submit(this, 'return-modal');
    });
}
/**
 * 設定モーダル表示時のイベント設定
 */
 window.device_setting_event_set = function() {

    //アコーディオンをクリックした時の動作
    $(document).on('change', '#device-setting-modal-dest_section_id', function () {//要素をクリックしたら
        let action = $('#dest_section_forms').data('action');

        // 送信
        _ajax.post(action, [
            {'name': 'id', 'value': $('#device-setting-modal-id').val()},
            {'name': 'dest_company_id', 'value': $('#device-setting-modal-dest_company_id').val()},
            {'name': 'dest_section_id', 'value': $('#device-setting-modal-dest_section_id').val()},
        ], function (results) {
            $('#dest_section_forms').html(results);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'html')
    });
}

/**
 * 設定モーダル表示時のイベント設定
 */
window.device_batch_transfer_event_set = function () {

    setTreeMultiSelect('#device-batch-transfer-modal-section_id', 'select.devices-select', {'type': 'transfer'});

    // 送信イベント変更時
    $(document).on('change', '#device-batch-transfer-modal-change_events', function () {
        let value = $(this).val();
        $('.setting-part').each(function() {
            $(this).addClass('display-none');
        });
        $('.setting-'+value).removeClass('display-none');
    });

    // セレクトの値をセットする
    function setTreeMultiSelect(elm, find, datas = {}, callback = null) {
        let select = $(elm).parents('form').find(find);
        if (select.length > 0) {
            let action = $(select).data('action');
            _ajax.post(action, datas, function (results) {
                $(select).empty();
                user_treemultiselect[0][0].remove();
                user_treemultiselect[0][0].reload();
                $.each(results.options, function(index, option) {
                    if(option.id == ''){ return true; }
                    $(select).append($('<option></option>', {
                        'value': option.id,
                        'data-section': option.company_name + '/' + option.section_name
                    }).text(option.text));
                })
                user_treemultiselect[0][0].reload();
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-batch-transfer-save-modal]', function () {
        _modal.submit(this, 'batch-transfer-save-modal', function(results) {
            _modal.hide('#device-batch-transfer-modal');
        });
    });

}
