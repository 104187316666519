// コンテンツ読み込み後
$(document).on('bss:onload', function() {
    // 印刷履歴ボタン
    $(document).on('click', '[data-print_history-modal]', function () {
        _modal.create(this, 'print_history-modal', ['route_model', 'route_action'], true);
    });
    // 再印刷ボタン
    $(document).on('click', '.print_history-reprint', function () {
        let datas = {'id': $(this).data('id'), 'section_id':$(this).data('section_id')};
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob($(this).data('action'), datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });


});

