/**
 * 乗務判断結果ページ用のスクリプト
 */
if (is_route('drive_judgement.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        drive_judgement_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.drive_judgement_event_set = function () {

    // 自動リロード用
    let setTimeoutId;

    /*---- 一覧検索フォーム ----*/

    // search.jsのイベントが先に実行されるため、一旦offにして再設定
    $(document).off('change', '#search-form, #toolbar-keyword-search');
    // 検索フォームの変更時
    $(document).on('change', '#search-form', function() {
        // リロードOFF（検索フォームでエラーがあった場合、リロードがかかって乗務判断管理画面が表示されないのを防ぐ）
        clearTimeout(setTimeoutId);
        // エラー表示を初期化
        _error.reset();
        // コンテンツを読込（成功した場合はリロード再開）
        load_contents(switchAutoLoad, false);
    });

    /*---- アクションボタン押下（承認・拒否） ----*/

    // 詳細 表示時
    $(document).on('click', '[data-drive_judgement-setting-modal]', function () {
        // リロードOFF
        clearTimeout(setTimeoutId);
        _modal.create(this, 'drive_judgement-setting-modal', ['id', 'status'], false);
    });

    // モーダル 閉じる
    $('#drive_judgement-setting-modal, #drive_judgement-code-modal').on('hide.bs.modal', function () {
        // 値によってリロード再開
        switchAutoLoad();
    });

    // 詳細 保存・承認・却下ボタン押下
    $(document).on('click', '[data-drive_judgement-save-modal]', function () {
        // ステータスが空の場合、付け加える（ダッシュボードからの呼び出し）
        let formId = $(this).data('drive_judgement-save-modal') + '-form';
        let status = $(formId).find('input:hidden[name="status"]');
        let clickStatus = $(this).data('status');
        if (clickStatus) {
            status.val(clickStatus);
        }
        let btn = $(this);
        _modal.submit(this, 'drive_judgement-save-modal', function (result) {
            $(create_id($(this).data('drive_judgement-save-modal'))).modal('hide');
            // 承認の場合のみ、認証コードを表示
            if (result.drive_judgement.status === '1' && (is_route('dashboard.index') || is_route('dashboard_data.index'))) {
                let data = {
                    id: result.drive_judgement.id
                };
                _modal.create_with_data(btn, 'drive_judgement_code_modal', data, false, null, 'code_action');
            }
        });
    });

    /*---- 認証コードボタン押下 ----*/

    // 認証コード 表示時
    $(document).on('click', '[data-code-modal]', function () {
        // リロードOFF
        clearTimeout(setTimeoutId);
        _modal.create(this, 'code-modal', ['id']);
    });

    /*---- 自動リロード切替 ----*/

    // リロード実行設定
    switchAutoLoad();

    // 自動リロードON/OFF 押下時
    $(document).on('click', '[name=auto_reload]', function (e) {
        // クリックでONする場合は、すぐにリロード実行
        let timer = 0;
        switchAutoLoad(timer);
    });

    // 自動リロード切替
    function switchAutoLoad(timer = 300000) {
        // デフォルト：5分間隔（300000ミリ秒）でリロード
        if ($('[name=auto_reload]:checked').val() === '1') {
            // ON
            setTimeoutId = setTimeout(function () {
                location.reload();
            }, timer);
        } else {
            // OFF
            clearTimeout(setTimeoutId);
        }
    }
};
