
import firebase from "firebase/app";
import 'firebase/messaging';

/**
 * セットアップページ用のスクリプト
 * @uses 管理画面と設計が異なるため共通部分は呼び出すもののbss独自イベントなどは使用しない。
 */
if (is_route('setup.index')) {

    var firebaseConfig = {
        apiKey: "AIzaSyC_5hd6DIO_wubtSUHjfqvDPs8FEEjiJAE",
        authDomain: "bss-anest.firebaseapp.com",
        projectId: "bss-anest",
        storageBucket: "bss-anest.appspot.com",
        messagingSenderId: "335952016242",
        appId: "1:335952016242:web:4c1582aa0ca331ea8863eb",
        measurementId: "G-CSLGZE569P"
    };

    firebase.initializeApp(firebaseConfig);

    var messaging = null;
    if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
    }

    $(function () {

        initialize();

        /*---- グループ会社選択時の処理 ----*/

        function setCompanySelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-company_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'holding_id', 'value' : id});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('#setup-form-company_id').empty();
                $('#setup-form-company_id').select2({
                    width: "100%",
                    data: results.options
                });
                initialize();
                $('.setup-company-select').removeClass('display-none');
                $('.setup-section-select, .setup-user-select').addClass('display-none');
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-holding_id', function(e) {
            setCompanySelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-holding_id').val() != null) {
            setCompanySelect($('input[type=hidden]#setup-form-holding_id'), $('input[type=hidden]#setup-form-holding_id').val());
        }

        /*---- 会社選択時の処理 ----*/

        function setSectionSelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-section_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'company_id', 'value' : id});
            datas.push({'name' : 'nullable', 'value' : true});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('#setup-form-section_id').empty();
                $('#setup-form-section_id').select2({
                    width: "100%",
                    data: results.options
                });
                initialize();
                $('.setup-section-select').removeClass('display-none');
                $('.setup-user-select').addClass('display-none');
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-company_id', function(e) {
            setSectionSelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-company_id').val() != null) {
            setSectionSelect($('input[type=hidden]#setup-form-company_id'), $('input[type=hidden]#setup-form-company_id').val());
        }

        /*---- 営業所選択時の処理 ----*/

        function setUserSelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-user_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'section_id', 'value' : id});
            datas.push({'name' : 'nullable', 'value' : true});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('.setup-user-select').removeClass('display-none');
                if (results.options.length == 1) {
                    $('#user-select-none').removeClass('display-none');
                    $('#user-select').addClass('display-none');
                } else {
                    let value = $('#setup-form-user_id').val();
                    $('#setup-form-user_id').empty();
                    $('#setup-form-user_id').select2({
                        width: "100%",
                        data: results.options,
                        val: value
                    });
                    $('#user-select-none').addClass('display-none');
                    $('#user-select').removeClass('display-none');
                    $('#setup-form-user_id').val(value).trigger('change');
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-section_id', function(e) {
            setUserSelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-section_id').val() != null) {
            setUserSelect($('input[type=hidden]#setup-form-section_id'), $('input[type=hidden]#setup-form-section_id').val());
        }

        // メニューバーの受付スライドのプロパティを判定しイベントをセットする
        $(document).on('click', '#setup-form-submit', function(e) {
            e.preventDefault();
            e.stopPropagation()
            if (messaging) {
                messaging.getToken({vapidKey: 'BIGjyEz9c4u4zBptKGxAlMzrBfKQmgZdcqCvE0XdlhWaO09k5KRv3Gh-xh7wiRpMVWqNt3IXtfN8ebNx14l2plk'})
                    .then(function (currentToken) {

                        if (currentToken) {
                            $('#setup-form-push_token').val(currentToken);
                            $('#setup-form-user_agent').val(navigator.userAgent);
                        }

                    }).finally(function () {
                    $('#setup-form').trigger('submit');
                });
            } else {
                $('#setup-form').trigger('submit');
            }
            // 20秒反応がない場合も送信
            setTimeout(function() {
                $('#setup-form').trigger('submit');
            }, 20000);
            return false;
        });

    });
}
