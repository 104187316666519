/**
 * ニュースリリース編集ページ用のスクリプト
 */
if (is_route('client_download.index')) {

    $(function () {

        // 全対象ON/OFF
        $(document).on('change', 'input[name=is_all]', function(){
            let holding = $('select[name=holding_id]');
            let company = $('select[name=company_id]');
            if ($(this).val() === '1') {
                holding.val(0).prop('disabled', true).trigger('change');
                holding.prev('.badge-required').addClass('d-none');
                company.val(0).prop('disabled', true).trigger('change');
                company.prev('.badge-required').addClass('d-none');
            } else {
                holding.val(0).prop('disabled', false).trigger('change');
                holding.prev('.badge-required').removeClass('d-none');
                company.val(0).prop('disabled', false).trigger('change');
                company.prev('.badge-required').removeClass('d-none');
            }
        });

        // URL入力した時はFILEはクリア
        $(document).on('change', 'input[name=url]', function(){
            if ($(this).val().length > 0) {
                $('.upload_file').parents('.input-group-btn').find('span.btn').addClass('disabled');
                $('input[name=_file_path]').val('');
            } else {
                $('.upload_file').parents('.input-group-btn').find('span.btn').removeClass('disabled');
            }
        });

        // FILE入力した時はURLはクリア
        $(document).on('change', '.upload_file', function(){
            if ($(this).val().length > 0) {
                $('input[name=url]').prop('disabled', true).val('');
            } else {
                $('input[name=url]').prop('disabled', false);
            }
        });

        /*---- 設定の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-setting-modal]', function () {
            modalId = create_id($(this).data('setting-modal'));
            _modal.create(this, 'setting-modal', ['id'], null, function(){
                // 初期表示設定
                if ($('input[name=is_all]:checked').val() === '1') {
                    let holding = $('select[name=holding_id]');
                    let company = $('select[name=company_id]');
                    holding.val('').prop('disabled', true);
                    holding.prev('.badge-required').addClass('d-none');
                    company.val('').prop('disabled', true);
                    company.prev('.badge-required').addClass('d-none');
                }
            });
        });

        /*---- 設定の保存時 ----*/

        // 設定実行時
        $(document).on('click', '[data-save-modal]', function () {
            _modal.submit(this, 'save-modal');
        });

        /*---- 削除確認の表示時 ----*/

        // 設定表示時
        $(document).on('click', '[data-confirm-modal]', function () {
            _modal.create(this, 'confirm-modal', ['id']);
        });

        /*---- 削除時 ----*/

        // 削除実行時
        $(document).on('click', '[data-delete-modal]', function () {
            _modal.submit(this, 'delete-modal');
        });
    });
}
