const zdcMap = {
    /*---- properties ----*/
    _mapApiUrl: '',      // webAPI用パラメタ取得用URL
    _zMap: null,         // 生成MAPオブジェクト
    _zMakers: [],        // MAP上に描画されるmarkerオブジェクト群
    _makerMax: 1,        // MAP上に描画できるmarkerの最大数
    _mapId: null,        // MAPを生成するblock要素のid(widthとhight属性が必須となる)
    _geom: null,         // MAP上のオブジェクトのWKTテキストinput要素
    _onSetting: false,   // MAP編集中FLG
    _defaultSetting: {
        zoom: 8,
        lat: '35.6781444',  // 東京駅
        lon: '139.7693167',
    },

    /*---- Map main ----*/

    /* Map初期描画
    * @param map        MAPを生成するblock要素
    * @param options    MAP option
    * @param isEdit     オブジェクト描画可否
    * @param markerType 描画タイプ(point/linestring/polygon/oval)    TODO: pointのみで事が済むのでは？他の仕様確定依存
    */
    Map: {
        init: function (map, options = {}, isEdit= false, markerType = 'point')
        {
            zdcMap._mapApiUrl = map.data('map_api_url');
            zdcMap._geom = $('input[name="' + map.data('input_name') + '"]');
            zdcMap._mapId = map.attr('id');
            // ZDC Undefined
            if (!ZDC) {
                map.append('<div>地図が利用できません。管理者にお問い合わせください。</div>');
                return;
            }

            // 初期設定
            let lat = options.lat === void 0 ? zdcMap._defaultSetting.lat : options.lat;
            let lon = options.lat === void 0 ? zdcMap._defaultSetting.lon : options.lon;
            let geom = {type: zdcMap.Utility.pascalize(markerType)};

            // 地図描画
            zdcMap._zMap = new ZDC.Map(
                document.getElementById(zdcMap._mapId),
                {
                    latlon: new ZDC.LatLon(lat, lon),
                    zoom: !options.zoom ? zdcMap._defaultSetting.zoom : options.zoom,
                    mapType: ZDC.MAPTYPE_DEFAULT,
                }
            );
            zdcMap._zMap.addWidget(new ZDC.ScaleBar());
            zdcMap._zMap.addWidget(new ZDC.Control({type: ZDC.CTRL_TYPE_ZM}));

            // 緯度経度設定ありの時
            if (zdcMap._geom.val().length > 0) {
                geom = zdcMap.Utility.WKT2geom(zdcMap._geom.val());
                // マーカー描画
                if (geom.type === 'Point' || geom.type === 'MultiPoint') {
                    lat = geom.coordinates[0];
                    lon = geom.coordinates[1];
                    /* マーカを作成 */
                    zdcMap.drawGadget.makeMarker(lat, lon);
                } else if (_geom.type === 'LineString' || _geom.type === 'MultiLineString') {
                } else {
                }
                // 中央移動
                zdcMap.Map.moveLatlon(lat, lon);
            }

            // 地図描画イベントセット
            // TODO:userGadgetの使用可否に変更する
            // 編集中はイベント監視し、確定後はイベント破棄といった動作にする
            if (isEdit) {
                zdcMap.Map.setEvent(geom.type);
            }
            return zdcMap._zMap;
        },

        /* 地図再描画 */

        setEvent: function (type)
        {
            if (type === 'Point') {
                ZDC.addListener(zdcMap._zMap, ZDC.MAP_MOUSEUP, function() {
                    let latlon = zdcMap._zMap.getPointerPosition();
                    zdcMap._geom.val('POINT(' +  latlon.lat + ' ' + latlon.lon + ')');
                    zdcMap.drawGadget.makeMarker(latlon.lat, latlon.lon);
                });
            }
        },

        /* 地図再描画 */

        refresh: function ()
        {
            zdcMap._zMap.refresh();
        },

        /* 中心の緯度経度移動 */

        moveLatlon: function (lat, lon)
        {
            zdcMap._zMap.moveLatLon(new ZDC.LatLon(lat, lon));
        },

        /* 郵便番号から中心緯度経度セット */

        setFromZipcode: function (zip)
        {
            // 半角数字以外を削除
            let val = zip.replace(/[^\d]+/g, '');
            // 郵便番号7桁以外は何もしない
            if (!val.match(/^\d{7}$/)) {
                return;
            }
            // OAuthパラメタ取得
            let apiName = 'address/zipcode';
            let data = {
                api: apiName,
                zipcode: val,
                limit: 1,
            };
            _ajax.post(zdcMap._mapApiUrl, data, function (result) {
                ZDC.Search.getByZmaps(apiName, result.params, function (status, res) {
                    if (status.code === 200 && res.info.hit > 0) {
                        zdcMap._zMap.moveLatLon(new ZDC.LatLon(res.item[0].point.lat, res.item[0].point.lon));
                    }
                });
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        },

        /* 住所(フリーワード)から中心緯度経度セット */

        setFromAddress: function (prefecture, municipality, address)
        {
            let val = $.trim(prefecture + municipality + address);
            // 入力なしは何もしない
            if (val.length === 0) {
                return;
            }
            // OAuthパラメタ取得
            let apiName = 'address/word';
            let data = {
                api: apiName,
                word: val,
                limit: 1
            };
            _ajax.post(zdcMap._mapApiUrl, data, function (result) {
                ZDC.Search.getByZmaps(apiName, result.params, function (status, res) {
                    if (status.code === 200 && res.info.hit > 0) {
                        zdcMap._zMap.moveLatLon(new ZDC.LatLon(res.item[0].point.lat, res.item[0].point.lon));
                    }
                });
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        },
    },

    /*---- drawGadget ----*/
    drawGadget: {
        makeMarker: function(lat, lon)
        {
            // 既存マーカ削除
            zdcMap._zMakers.forEach(function(mrk){
                zdcMap._zMap.removeWidget(mrk);
            })
            /* クリックした地点にマーカを作成 */
            let mkr = new ZDC.Marker(new ZDC.LatLon(lat, lon), {
                color: ZDC.MARKER_COLOR_ID_BLUE_L,
                number: ZDC.MARKER_NUMBER_ID_STAR_L,
            });
            /* マーカを追加 */
            zdcMap._zMap.addWidget(mkr);
            zdcMap._zMakers.push(mkr);
        }
    },

    /*---- Utility ----*/
    Utility: {
        /* 'point' -> 'Point', 'multi_point' -> 'MultiPoint' */
        pascalize: function (string)
        {
            return `${string}`
            .toLowerCase()
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w*)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3}`
            )
            .replace(new RegExp(/\w/), s => s.toUpperCase());
        },

        /* wkt -> geoJson */
        WKT2geom: function(wkt)
        {
            // 前処理
            // trim & [(][)][,]前後のスペースを削除
            var _wkt = wkt.replace(/\s\s/g,' ').replace(/\s\)/g,')').replace(/\(\s/g,'(').replace(/\s\(/g,'(').replace(/\)\s/g,')').replace(/^\s/,'').replace(/\s$/,'').replace(/\,\s/g,',').replace(/\s\,/g,',');
            var _type;
            // MULTIPOINT =linestring
            if (wkt.indexOf("MULTIPOINT") >= 0) {
                _type = "MultiPoint";
                // MULTILINESTRING =polygon
            } else if (wkt.indexOf("MULTILINESTRING") >= 0) {
                _type = "MultiLineString";
                // MULTIPOLYGON
            } else if (wkt.indexOf("MULTIPOLYGON") >= 0) {
                _type = "MultiPolygon";
                // POINT
            } else if (wkt.indexOf("POINT") >= 0) {
                _type = "Point";
                // LINESTRING
            } else if (wkt.indexOf("LINESTRING") >= 0) {
                _type = "LineString";
                // POLYGON
            } else if (wkt.indexOf("POLYGON") >= 0) {
                _type = "Polygon";
            }

            // parse処理
            _wkt = _wkt.replace(_type.toUpperCase(), '');
            var _rtnVal = {"type": _type, "coordinates":[]}
            var _arWkt = _wkt.split(')),');
            var _arRtn = [];

            // deep4
            if (_arWkt.length > 1) {
                _arRtn = zdcMap.Utility.WKT2geom4_4(_arWkt);
            } else {
                // deep3
                _arWkt = _wkt.split('),');
                if (_arWkt.length > 1) {
                    _arRtn = zdcMap.Utility.WKT2geom4_3(_arWkt);
                } else {
                    // deep2
                    _arWkt = _wkt.split(',');
                    if (_arWkt.length > 1) {
                        _arRtn = zdcMap.Utility.WKT2geom4_2(_arWkt);
                    } else {
                        // deep1
                        _arWkt = _wkt.split(' ');
                        if(_arWkt.length > 1){
                            _arRtn = zdcMap.Utility.WKT2geom4_1(_arWkt);
                        }
                    }
                }
            }

            if (_type === "Polygon") {
                if (_arRtn.length > 2) {
                    _rtnVal.coordinates.push(_arRtn);
                } else {
                    _rtnVal.coordinates = _arRtn;
                }

            } else {
                _rtnVal.coordinates = _arRtn;
            }

            return _rtnVal;
        },

        // wkt -> geoJson deep1
        WKT2geom4_1: function(wkt)
        {
            var _arRtn = [];
            wkt.forEach(function (p, i){
                if (p !== "") {
                    p = p.replace(/^\(/,'').replace(/\)$/,'');
                    _arRtn[i] = p;
                }
            });
            return _arRtn;
        },

        // wkt -> geoJson deep2
        WKT2geom4_2: function(wkt)
        {
            var _arRtn = [];
            wkt.forEach(function (p,i){
                if (p !== "") {
                    p = p.replace(/^\(/,'').replace(/\)$/,'');
                    _arRtn[i] = zdcMap.Utility.WKT2geom4_1(p.split(' '));
                }
            });
            return _arRtn;
        },

        // wkt -> geoJson deep3
        WKT2geom4_3: function(wkt)
        {
            var _arRtn = [];
            wkt.forEach(function (p,i){
                if (p !== "") {
                    p = p.replace(/^\(/,'').replace(/\)$/,'');
                    _arRtn[i] = zdcMap.Utility.WKT2geom4_2(p.split(','));
                }
            });
            return _arRtn;
        },

        // wkt -> geoJson deep4
        WKT2geom4_4: function(wkt)
        {
            var _arRtn = [];
            wkt.forEach(function (p,i){
                if (p !== "") {
                    p = p.replace(/^\(/,'').replace(/\)$/,'');
                    _arRtn[i] = zdcMap.Utility.WKT2geom4_3(p.split('),'));
                }
            });
            return _arRtn;
        },
    },
}
export default zdcMap;

