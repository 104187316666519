/**
 * 点検整備区分設定ページ用のスクリプト
 */
 if (is_route('terminal.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        terminal_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.terminal_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
}
