import Cookies from 'js-cookie';

class FaceAuth {

    videoId = null;
    canvasId = null;
    startedCallback = null;
    cameraDeviceId = null;
    devices = [];

    constructor(videoId, startedCallback = null, canvasId = null) {
        this.videoId = videoId;
        this.startedCallback = startedCallback;
        this.canvasId = canvasId;
        if (this.canvasId == null) {
            let items = $(create_id(videoId)).siblings('canvas');
            if (items.length > 0) {
                this.canvasId = $(items[0]).attr('id');
            }
        }
        this.cameraDeviceId = this.getCameraId();
    }

    selectableCamera() {
        (async () => {   
            this.devices = await navigator.mediaDevices.enumerateDevices();
            _camera.create_options(create_id(this.videoId), this.devices, this.cameraDeviceId);
        })();
        return this;
    }

    start(startedCallback = null) {
        if (startedCallback != null) {
            this.startedCallback = startedCallback;
        }
        _camera.start(this.videoId, this.startedCallback, this.cameraDeviceId);
        return this;
    }

    getCameraId() {
        let cameraDeviceId = Cookies.get('selected_camera_device_id');
        if (cameraDeviceId === undefined) {
            cameraDeviceId = null;
        }
        return cameraDeviceId;
    }

    setCameraId(cameraDeviceId) {
        this.cameraDeviceId = cameraDeviceId;
        Cookies.set('selected_camera_device_id', cameraDeviceId);
    }

    changeCamera(cameraDeviceId) {
        this.setCameraId(cameraDeviceId);
        _camera.change(this.videoId, this.cameraDeviceId, this.startedCallback);
        return this;
    }

    capture(obj = null) {
        obj = (obj == null) ? this : obj;
        const capture = _camera.capture(obj.videoId, obj.canvasId);
        return capture;
    }

    complete() {
        return this;
    }

    error() {
        return this;
    }

    cancel() {
        _camera.end(this.videoId);
        return this.complete();
    }
}

export class FaceAuthentication extends FaceAuth {

    timer = null;
    isAuthenticating = false;
    interval = 3000;

    constructor(videoId, interval = 3000, startedCallback = null, canvasId = null) {
        super(videoId, startedCallback, canvasId);
        this.timer = null;
        this.interval = interval;
    }

    start(startedCallback = null) {
        if (startedCallback != null) {
            this.startedCallback = startedCallback;
        }
        _camera.start(this.videoId, function(obj) {
            obj.timer = setInterval(function(obj) {
                obj.capture(obj);
            }, obj.interval, obj);
        }, this.cameraDeviceId, this);
        return this;
    }

    changeCamera(cameraDeviceId) {
        this.setCameraId(cameraDeviceId);
        if (this.timer != null) {
            _camera.change(this.videoId, this.cameraDeviceId, function(obj) {
                (obj.timer != null) && clearTimeout(obj.timer);
                obj.timer = setInterval(function(obj) {
                    obj.capture(obj);
                }, obj.interval, obj);
            }, this);
        }
        return this;
    }

    capture(obj) {
        if (obj.isAuthenticating) {
            return;
        }
        obj.isAuthenticating = true;
        const capture = super.capture(obj);
        obj.startedCallback(capture);
    }

    complete() {
        (this.timer != null) && clearInterval(this.timer);
        this.isAuthenticating = false;
        return super.complete();
    }

    error() {
        this.isAuthenticating = false;
        return super.error();
    }

}

export class FaceAuthRegister extends FaceAuth {

    isRegisting = false;

    constructor(videoId, startedCallback = null, canvasId = null) {
        super(videoId, startedCallback, canvasId);
    }
    
    start(startedCallback = null) {
        this.isRegisting = true;
        return super.start(startedCallback);
    }

    complete() {
        this.isRegisting = false;
        return super.complete();
    }

    error() {
        this.isRegisting = false;
        return super.error();
    }
}