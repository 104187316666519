/**
 * 全ページ共通のスクリプト
 */
 $(document).on('bss:onload', function() {

    /*---- 受付設定時 ----*/

    $(document).on('click', '[data-remote_rollcall_config-setting-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'remote_rollcall_config-setting-modal', [], false, function() {
            displayed_default_list();
        });
    });

    /*---- 設定保存時 ----*/

    $(document).on('click', '[data-remote_rollcall_config-save-modal]', function() {
        _modal.submit(this, 'remote_rollcall_config-save-modal');
    });
    
    /*---- グループ会社のチェックボックスクリック時 ----*/

    $(document).on('change', '.list-holding-input', function() {
        let itemId = create_id($(this).data('parent-item-id'));
        // チェック項目が存在していなければindeterminateで表示
        $(this).prop('indeterminate', !($(itemId).find('.section-tree input:checked').length > 0));
        if ($(this).prop('checked')) {
            $(itemId + ' .company-tree ').slideDown(100);
        } else {
            $(itemId + ' .company-tree ').slideUp(100);
            $(itemId + ' .section-tree ').slideUp(100);
            $(itemId + ' .list-section-input').prop('checked', false);
            $(itemId + ' .list-company-input').prop('checked', false);
            $(itemId + ' .list-company-input').prop('indeterminate', false);
            $(this).prop('indeterminate', false);
        }
    })

    /*---- 会社のチェックボックスクリック時 ----*/

    $(document).on('change', '.list-company-input', function() {
        let itemId = create_id($(this).data('parent-item-id'));
        let holding = create_id($(this).data('parent-input-id'));
        $(this).prop('indeterminate', (!($(itemId).find('.section-tree input:checked').length > 0)));
        if ($(this).prop('checked')) {
            $(itemId + ' .section-tree ').slideDown(100);
            $(holding).prop('checked', true);
        } else {
            $(itemId + ' .section-tree').slideUp(100);
            $(this).prop('indeterminate', false);
            $(itemId + ' .list-section-input').prop('checked', false);
        }
        $(holding).trigger('change');
    });

    /*---- 営業所のチェックボックスクリック時 ----*/

    $(document).on('change', '.list-section-input', function () {
        // 同階層がチェックされていない場合
        let company = create_id($(this).data('parent-input-id'));
        if ($(this).prop('checked')) { // チェックされる時
            // 親のチェック状態を指定
            $(company).prop('checked', true);
        }
        $(company).trigger('change');
    });

    const displayed_default_list = function() {
        $('.list-holding-input:checked').each(function() {
            const holdingItemId = create_id($(this).data('parent-item-id'));
            const companyTree = $(holdingItemId).find('.company-tree');
            $($(companyTree)).show(0, function() {
                $(companyTree).find('.list-company-input:checked').each(function() {
                    const companyItemId = create_id($(this).data('parent-item-id'));
                    const sectionTree = $(companyItemId).find('.section-tree');
                    $(sectionTree).show(0);
                });
                

            });
        });
    }

 });