
import { AWSChime } from './aws_chime';
import {Howl} from 'howler';

export class RemoteRollcallMeeting {

    startCallback = null;
    loadedCallback = null;

    plainModalId = 'remote_rollcall-join-modal';
    modalId = '#remote_rollcall-join-modal';
    displayWidth = 640;
    displayHeight = 360;
    interval = 5000;
    plainConfirmModalId = 'remote_rollcall-join-confirm-modal';
    confirmModalId = '#remote_rollcall-join-confirm-modal';

    isResponding = false;
    isUpdating = false;

    joinAction = null;
    meetingId = null;
    authorizerId = null;
    params = null;

    chime = null;

    timer = null;

    updated = null;
    updatedAt = null;
    updateCounter = 0;
    detailConfirmResults = null;

    constructor(startCallback, loadedCallback = null, modalId = 'remote_rollcall-join-modal', displayWidth = 640, displayHeight = 360, interval = 5000, confirmModalId = 'remote_rollcall-join-confirm-modal') {
        this.startCallback = startCallback;
        this.loadedCallback = loadedCallback;
        this.plainModalId = modalId;
        this.modalId = create_id(modalId);
        this.displayWidth = displayWidth;
        this.displayHeight = displayHeight;
        this.interval = interval;
        this.plainConfirmModalId = confirmModalId;
        this.confirmModalId = create_id(confirmModalId);
        this.updated = new Howl({
            src: '/assets/audio/updated.mp3',
        });
    }

    setJoinAction(action = null) {
        this.joinAction = (action == null) ? $('#remote-rollcall-stanby-actions').data('remote-join-action') : action;
    }

    setParams(meetingId, authorizerId = null, confirm = false) {
        this.meetingId = meetingId;
        this.authorizerId = authorizerId;
        this.params = [];
        this.params.push({'name': 'meeting_id', 'value': meetingId});
        this.params.push({'name': 'authorizer_id', 'value': authorizerId});
        if (confirm) {
            this.params.push({'name': 'confirm', 'value': confirm});
        }
    }

    setModalEvent(obj = null) {

        obj = (obj == null) ? this : obj;

        /*---- モーダル表示時 ----*/

        $(document).off('show.bs.modal', this.modalId);
        $(document).on('show.bs.modal', this.modalId, function() {
            obj.isResponding = true;
            obj.init();
        });

        /*---- モーダル非表示時 ----*/

        /*---- モーダル非表示時はタイマーを初期化する ----*/

        $(document).off('hidden.bs.modal', this.modalId);
        $(document).on('hidden.bs.modal', this.modalId, function() {
            obj.cancel();
        });

        /*---- 完了ボタン押下時 ----*/

        $(document).off('click', create_id(this.modalId, 'complete'));
        $(document).on('click', create_id(this.modalId, 'complete'), function() {
            obj.complete(obj);
        });

        const formSelector = create_id(this.modalId, 'form input,' + create_id(this.modalId, 'form input,' + create_id(this.modalId, 'form textarea')))

        // フォーム変更時の一時保存処理
        $(document).off('change', formSelector);
        $(document).on('change', formSelector, function() {
            if ($(this).attr('name') == 'video-inputs' || $(this).attr('name') == 'audio-inputs' || $(this).attr('name') == 'audio-outputs') {
                return;
            }
            obj.isUpdating = true;
            let update = $('[data-remote-rollcall-update-action]');
            let updateAction = $(update).data('remote-rollcall-update-action');
            _ajax.background(updateAction, $('#remote_rollcall-join-modal-form').serialize(), function(results, obj) {
                obj.isUpdating = false;
            }, function(xhr, status, errorThrown, obj){
                obj.isUpdating = false;
            }, 'json', obj);
        });
    }

    getElement(suffix = '') {
        return $(create_id(this.modalId, suffix));
    }

    getValue(suffix = '') {
        return this.getElement(suffix).val();
    }

    init() {
        this.isResponding = true;
        this.isUpdating = false;
    }

    confirm(messages, obj = null) {

        obj = (obj == null) ? this : obj;

        // 確認用モーダルにメッセージを追加し表示する
        $.each(messages, function(index, message) {
            $(create_id(obj.confirmModalId, 'message')).text(message);
        })
        _modal.show(obj.confirmModalId);
        // 重複バインドを防止
        $(document).off('click', create_id(obj.confirmModalId, 'submit'));
        $(document).on('click', create_id(obj.confirmModalId, 'submit'), function() {
            // 確認し点呼へ参加する場合は確認ずみフラグをONにしていし点呼に参加
            _modal.hide(obj.confirmModalId);
            obj.join(obj.meetingId, obj.authorizerId, obj.joinAction, true);
        });
    }

    /**
     * 遠隔点呼への参加（クラウド側・全ページ共通）
     *
     * @param {*} meetingId
     * @param {*} confirm
     */
    join(meetingId, authorizerId = null, action = null, confirm = false) {

        (this.startCallback != null) && this.startCallback();
        this.getElement(' .modal-content').empty();
        this.init();
        this.setJoinAction(action);
        this.setParams(meetingId, authorizerId, confirm);
        this.setModalEvent();
        
        // 送信
        _ajax.post(this.joinAction, this.params, function (results, obj) {
            obj.getElement(' .modal-content').html(results);
            toastr.clear();
            initialize();
            if (obj.loadedCallback != null) {
                obj.loadedCallback(results);
            }
            obj.start();
        }, function(xhr, status, errorThrown, obj) {
            try {
                let response = JSON.parse(xhr.responseText);
                // エラーが確認の場合
                if (response.errors.confirm !== undefined) {
                    obj.confirm(response.errors.confirm, obj);
                } else {
                    _error.omitted(xhr, status, errorThrown);
                }
            } catch {
                // レスポンスされたエラーを表示する
                _error.responseError(xhr);
                // システムエラーを表示
                _error.systemError(xhr.status);
            }
        }, 'html', this);
    }

    start(overlapId = null, isModal = true) {

        if (overlapId != null) {
            this.plainModalId = overlapId;
            this.modalId = create_id(overlapId);
        }

        if (this.getValue('is_timeover')) {
            // モーダル表示
            (isModal) && _modal.show(this.modalId);
            return;
        }

        this.chime = new AWSChime(this.plainModalId, {
            'audio_id': this.plainModalId + '-audio-preview',
            'video_id_prefix': this.plainModalId + '-video-preview-',
            'video_view_id': this.plainModalId + '-video-view',
            'video_slide_id': this.plainModalId + '-video-slide',
            'tiles': Number(this.getValue('tile_limit')),
            'meeting': JSON.parse(this.getValue('meeting')),
            "attendee_id": this.getValue('attendee_id'),
            "attendee": JSON.parse(this.getValue('attendee')),
            'size': {
                'width': this.displayWidth,
                'height': this.displayHeight,
            },
            'errors': {
                'bind': this.getValue('bind'),
                'device': this.getValue('device'),
                'tile': this.getValue('tile'),
            }
        }, this.sendLog);

        // 詳細表示更新のタイマー処理
        this.timer = setInterval(this.detailUpdate, this.interval, this);
        this.detailUpdate();

        // モーダル表示
        (isModal) && _modal.show(this.modalId);
    }

    detailUpdate(obj = null) {
        obj = (obj == null) ? this : obj;

        if (obj.isUpdating) {
            return;
        }
        // データの更新確認と適用
        let get = $('[data-remote-rollcall-get-action]');
        if ($(get).length > 0) {
            let getAction = $(get).data('remote-rollcall-get-action');
            _ajax.background(getAction, {'meeting_id': obj.getValue('meeting_id')}, function(results) {
                // ローカルからのキャンセル時
                if (results.model.status == obj.getValue('cancel_status')) {
                    if (!obj.chime.isEnded) {
                        obj.chime.end();
                    }
                    obj.cancel();
                    obj.getElement('complete').remove();
                }
                // 最終更新を元にvaluesのデータを更新する
                if (obj.updatedAt != results.model.updated_at) {
                    obj.updatedAt = results.model.updated_at;
                    if (obj.updateCounter > 0) {
                        obj.updated.play();
                    }
                    obj.updateCounter ++;
                    if (results.model.values != null) {
                        if(results.vehicle != null){
                            obj.getElement('vehicle_id').val(results.vehicle.id);
                            obj.getElement('vehicle-text').val(results.vehicle.vehicle_name);
                        }
                        if (results.model.values.alc_concentration != null) {
                            obj.getElement('alc_concentration').val(results.model.values.alc_concentration);
                            obj.getElement('alc_concentration-text').val(results.model.values.alc_concentration + obj.getElement('alc_concentration-text').data('unit'));
                        }
                        if (results.model.values.rollcall_type != null) {
                            obj.getElement('rollcall_type_' + results.model.values.rollcall_type).trigger('click');
                        }
                        if (results.model.values.blood_pressure_flag == 1) {
                            if (results.model.values.max_blood_pressure != null) {
                                obj.getElement('max_blood_pressure').val(results.model.values.max_blood_pressure);
                                obj.getElement('max_blood_pressure-text').html(results.model.values.max_blood_pressure);
                            }
                            if (results.model.values.min_blood_pressure != null) {
                                obj.getElement('min_blood_pressure').val(results.model.values.min_blood_pressure);
                                obj.getElement('min_blood_pressure-text').html(results.model.values.min_blood_pressure);
                            }
                            if (results.model.values.pulse != null) {
                                obj.getElement('pulse').val(results.model.values.pulse);
                                obj.getElement('pulse-text').html(results.model.values.pulse);
                            }
                            if (results.model.values.temperature_flag == 1){
                                if (results.model.values.temperature != null) {
                                    obj.getElement('temperature').val(results.model.values.temperature);
                                    obj.getElement('temperature-text').val(results.model.values.temperature + obj.getElement('temperature-text').data('unit'));
                                    obj.getElement('temperature-form').toggleClass('display-none', false);
                                }else{
                                    obj.getElement('temperature-form').toggleClass('display-none', true);
                                }
                              }else{
                                obj.getElement('temperature-form').toggleClass('display-none', true);
                              }
                        } else {
                            if (results.model.values.temperature != null) {
                                obj.getElement('temperature').val(results.model.values.temperature);
                                obj.getElement('temperature-text').val(results.model.values.temperature + obj.getElement('temperature-text').data('unit'));
                                obj.getElement('temperature-form').toggleClass('display-none', false);
                            }
                        }
                        obj.getElement('blood_pressure-form').toggleClass('display-none', !(results.model.values.blood_pressure_flag == 1));
                    }
                    if (results.model.alc_image_url != null) {
                        obj.getElement('alc-img img').attr('src', results.model.alc_image_url);
                        obj.getElement('alc-img').removeClass('display-none');
                    }
                    // 詳細表示に変更があれば更新する
                    let detail = $('[data-remote-rollcall-detail-action]');
                    _ajax.background($(detail).data('remote-rollcall-detail-action'), obj.params, function(results, obj) {
                        if (obj.detailConfirmResults != results) {
                            $(detail).html(results);
                            obj.detailConfirmResults = results;
                        }
                        window.initialize_tooltip();
                    }, function(xhr, status, errorThrown){
                        _error.omitted(xhr, status, errorThrown);
                    }, 'html', obj);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            }, 'json', obj);
        }
    }

    sendLog(action, id, awsChime) {
        const datas = {
            'id': id,
            'log': awsChime.logs.join('\n'),
            'error': awsChime.errors.join('\n'),
        };
        _ajax.post(action, datas);
    }

    complete(obj = null) {
        
        obj = (obj == null) ? this : obj;

        obj.cancel()
        _modal.submit(obj.getElement('complete'), 'complete-modal', null, function() {
            obj.isResponding = true;
            // エラーの場合は再開
            obj.timer = setInterval(obj.detailUpdate, obj.interval, obj); 
        });
    }

    cancel() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
        this.isResponding = false;
    }

}