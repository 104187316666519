/**
 * URLからパラメーターを取得する
 * @param {*} url
 */
exports.get_parameters = function(url) {
    let params = [];
    if (url.indexOf('?') < 0) {
        return params;
    }
    search = url.split('?')[1];
    let searchs = search.split('&');
    searchs.forEach(function (value) {
        let values = value.split('=');
        param = {};
        param.name = values[0];
        param.value = values[1];
        params.push(param);
    });
    return params;
}
/**
 * 検索パラメーターを生成し現在のURLにセットする
 * @param {*} requests
 */
exports.set_parameters = function(requests) {
    let preParams = module.exports.create_parameters(location.search);
    let params = [];
    // クエリストリングに含まれる配列項目は一旦クリアしparamにセット
    preParams.forEach(function (param) {
        if (!param.name.match(/.*\[\]$/)) {
            params.push(param);
        }
    });

    // paramsにrequest内容を反映
    requests.forEach(function (request) {
        let index;
        if (request.name.match(/.*\[\]$/)) {
            // 配列項目の時は同じnameと値があるかを検索する
            index = params.findIndex((v) => v.name === request.name && v.value === request.value);
        } else {
            // それ以外は同じnameがあるかを検索
            index = params.findIndex((v) => v.name === request.name);
        }
        if (index >= 0) {
            if (request.value.length > 0) {
                // 同じものがある場合、フォームの値を最優先
                params.splice(index, 1, request);
            } else {
                // 同じnameの値で値がない場合、削除する
                params.splice(index, 1);
            }
        } else {
            // paramに存在しない場合は値が指定されているものだけセットする
            if (request.value.length > 0) {
                params.push(request);
            }
        }
    });
    let url = module.exports.create_url('', params);
    // urlの変更
    history.replaceState('', '', url);
    history.pushState('', '', url);
};
/**
 * 検索パラメータを削除する
 * @param {*} removes
 */
 exports.remove_parameters = function(url, removes) {
    let params = module.exports.create_parameters(url);
    let urls = url.split('?');
    url = urls[0];
    removes.forEach(function (key) {
        // 同じnameの値があるかを検索
        let index = params.findIndex((v) => v.name === key);
        if (index >= 0) {
            params.splice(index, 1);
        }
    });
    let current = module.exports.create_url('', params);
    // urlの変更
    history.replaceState('', '', current);
    history.pushState('', '', current);
    return module.exports.create_url(url, params);
}
/**
 * 値から検索パラメーターの配列を返す
 * @param {*} url
 * @returns
 */
exports.create_parameters = function(url) {
    let urls = url.split('?');
    let params = [];
    if (urls.length >= 2) {
        let current = urls[1];
        let currentParams = [];
        if (current.length > 0) {
            currentParams = current.split('&');
        }
        currentParams.forEach(function (value) {
            let values = value.split('=');
            if (values[1].length > 0) {
                param = {};
                param.name = values[0];
                param.value = values[1];
                params.push(param);
            }
        });
    }
    return params;
}
/**
 * URLにパラメータをセットしたURLを返す
 * @param {*} url
 * @param {*} params
 * @returns
 */
exports.create_url = function(url, params) {
    let tmp = [];
    $.each(params, function(index, search) {
        if (search.name != '_token') {
            tmp.push(search.name + '=' + search.value);
        }
    });
    return url + '?' + tmp.join('&');
}
/**
 * サイドバーのURLを生成する
 * @param {*} url
 * @param {*} is_extend
 * @param {*} replace
 * @param {*} excludes
 * @returns
 */
exports.create_affiliation_url = function(url, is_extend, replace, excludes = []) {
    let currentParams = is_extend ? module.exports.create_parameters(location.search) : [];
    let params = [];
    // 条件指定されているもので、exclude対象でないものをparamセットする
    currentParams.forEach(function (param) {
        if (param.name.length > 0 && param.value.length > 0) {
            if (excludes.findIndex((exclude) => exclude === param.name || exclude + '[]' === param.name) < 0) {
                params.push(param);
            }
        }
    });    
    $.each(replace, function(index, json) {
        if (json['value'] == 'null') {
            params = params.filter(param => param.name !== json['name'])
        } else {
            params = json_put(params, 'name', json);
        }
    })
    // url生成
    url = module.exports.create_url(url, params);
    let current = module.exports.create_url('', params);
    history.replaceState('', '', current);
    history.pushState('', '', current);
    return url;
}
